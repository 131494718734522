import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'event/event/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/event/${detail[pkField]}/`
}


const EventApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEventList: getList(builder, baseApiUrl, 'EventList'),
    getEventSimpleList: getList(builder, baseApiUrl, 'EventSimpleList', { simple: true }),

    getEventDetail: getDetail(builder, baseApiUrl, 'EventDetail'),
    getEventFormDetail: getDetail(builder, baseApiUrl, 'EventFormDetail', { forEdit: true }),

    updateEvent: update(builder, baseApiUrl, pkField),

    deleteEvent: remove(builder, baseApiUrl),
    batchDeleteEvent: batchRemove(builder, baseApiUrl),
  }),
});


export default EventApi;
