import { useState } from 'react'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'

import { RPSTariffList } from 'models/RPSTariff/components'


const filterFields = [
  SmartFilter.getFieldConfig('forParkingCard', 'Для учёта парковочных карт', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('allowNotify', 'Разрешены уведомления', SmartFilter.FilterBool),
];


const RPSTariffListPage = () => {
  const [params, setParams] = useState(null);
 
  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Тарифы парковки"
      headerMiddle={<SmartFilter queryKey="rpsTariff" onChange={onFilterChange} fields={filterFields} />}
    >
      <SmartFilterValues queryKey="rpsTariff" onChange={onFilterChange} fields={filterFields} />

      <RPSTariffList params={params} />
    </Page>
  )
}

export default RPSTariffListPage
