import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'building/building/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/building/${detail[pkField]}/`
}


const BuildingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuildingList: getList(builder, baseApiUrl, 'BuildingList'),
    getBuildingSimpleList: getList(builder, baseApiUrl, 'BuildingSimpleList', { simple: true }),

    getBuildingDetail: getDetail(builder, baseApiUrl, 'BuildingDetail'),
    getBuildingFormDetail: getDetail(builder, baseApiUrl, 'BuildingFormDetail', { forEdit: true }),

    updateBuilding: update(builder, baseApiUrl, pkField),

    deleteBuilding: remove(builder, baseApiUrl),
    batchDeleteBuilding: batchRemove(builder, baseApiUrl),
  }),
});


export default BuildingApi;
