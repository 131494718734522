import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { ModalForm } from 'components/common'
import HallRequestApi, { pkField, getDetailUrl } from 'models/HallRequest/api'
import { HallRequestActions } from 'models/HallRequest/slice'
import MainFieldSet from './MainFieldSet'


const HallRequestForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.hallRequest);

  const [apiGetFormDetail, apiGetFormDetailProps] = HallRequestApi.endpoints.getHallRequestFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = HallRequestApi.endpoints.updateHallRequest.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    const data = {
      [pkField]: values[pkField],
      status: values.status,
      statusComment: values.statusComment,
    }
    apiUpdate(data);
  };

  return (
    <ModalForm
      name="HallRequest"
      withTabs={false}
      title={formDetailPK ? `Заявка №${formDetailPK}`: 'Новая заявка'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={HallRequestApi}

      onFinish={onFinish}
      onClose={() => dispatch(HallRequestActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
          eventStart: dayjs(detail.eventStart)
        }
      }}
    >
      <MainFieldSet formDetail={formDetail} initials={initials} />
    </ModalForm>
  );
}


export default HallRequestForm
