import { useDispatch } from 'react-redux'

import { TableList, TableColumns, PropertyList } from 'components/common'
import CardTypeBenefitApi, { getDetailUrl, pkField } from 'models/CardTypeBenefit/api'
import { CardTypeBenefitActions } from 'models/CardTypeBenefit/slice'
import CardTypeBenefitValue from '../CardTypeBenefitValue'


const defaultColumnList = ['tenant', 'value', 'text', 'sort', 'isActive', 'actions'];


const CardTypeBenefitList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = CardTypeBenefitApi.endpoints.getCardTypeBenefitList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CardTypeBenefitApi.endpoints.updateCardTypeBenefit.useMutation();
  const [apiDelete, apiDeleteProps] = CardTypeBenefitApi.endpoints.deleteCardTypeBenefit.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = CardTypeBenefitApi.endpoints.batchDeleteCardTypeBenefit.useMutation();

  const dispatch = useDispatch();

  const fields = {
    tenant: {
      title: 'Арендатор',
      sorter: false,
      render: (val, item) => (<CardTypeBenefitValue detail={item} asLink={false} isBack={true} />),
    },
    value: {
      title: 'Скидка',
      width: 150,
      render: (val, item) => (
        <PropertyList
          style={{ padding: '0', border: '0' }}
          items={[
            { label1: 'Скидка', value: `${item.value} ${item.valueLabel}` },
            { label1: 'Скидка2',  value: `${item.value2} ${item.value2Label}` },
          ]}
        />
      ),
    },
    text: {
      title: 'Описание',
      width: 200,
      render: (val, item) => (
        <div style={{fontSize: '12px', lineHeight: '1.125'}}>{val}</div>
      ),
    },
    sort: { title: 'Сортировка', sorter: true, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getDetailUrl,
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(CardTypeBenefitActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        { name: 'delete', action: () => apiDelete(item[pkField]) },
      ]
    }),
  };

  return (
    <TableList
      queryKey="cardTypeBenefit"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default CardTypeBenefitList;
