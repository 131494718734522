import { TableList, DateTimeValue } from 'components/common'
import { ClientValue } from 'models/Client/components'

const defaultColumnList = ['sender', 'text', 'dateCreated', 'actions'];


const CardRequestList = ({ columnList, messages, onEdit }) => {
  const fields = {
    sender: {
      title: 'Отправитель',
      width: 240,
      render: (val, item) => (
        <>
          <ClientValue detail={val} showPhone={false} asLink={true} isBack={true} />
          {item.isManager && <div style={{fontSize: '12px', color: '#9f9f9f', margin: '0 0 0 40px'}}>Менеджер</div>}
        </>
      ),
    },
    text: { title: 'Сообщение', width: 180 },
    number: {
      title: 'Номер карты',
      sorter: true,
      width: 150,
    },
    dateCreated: {
      title: 'Дата',
      render: (val, item) => <DateTimeValue date={val} />,
    },
  };

  return (
    <TableList
      queryKey="cardRequestMessage"
      rowKey="id"
      fields={fields}
      columnList={columnList || defaultColumnList}
      dataList={messages || []}

      rowSelection={false}
      hidePagination={true}
      hideStat={true}
    />
  );
}

export default CardRequestList;
