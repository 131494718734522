import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'

import { TenantServiceActions } from 'models/TenantService/slice'
import { TenantServiceList, TenantServiceForm } from 'models/TenantService/components'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const TenantServiceListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(TenantServiceActions.openForm({ name: 'editForm' }))
  };
 
  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Услуги арендаторов"
      headerMiddle={<SmartFilter queryKey="tenantService" onChange={onFilterChange} fields={filterFields} />}

      mainActions={[
        { name: 'add', action: (detail) => onEdit() },
      ]}

      basePath="/tenant/"
      tabs={[
        { label: 'Арендаторы', key: '' },
        { label: 'Категории', key: 'category/' },
        { label: 'Услуги', key: 'service/' },
      ]}
    >
      <SmartFilterValues queryKey="tenantService" onChange={onFilterChange} fields={filterFields} />

      <TenantServiceList params={params} />

      <TenantServiceForm mode="list" />
    </Page>
  )
}

export default TenantServiceListPage
