import { useSelector, useDispatch } from 'react-redux'

import { getFullNameOrEmail } from 'utils/helpers'
import { ModalForm } from 'components/common'
import ClientApi, { pkField, getDetailUrl } from 'models/Client/api'
import { ClientActions } from 'models/Client/slice'

import MainFieldset from './MainFieldset'


const ClientForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.client);

  const [apiGetFormDetail, apiGetFormDetailProps] = ClientApi.endpoints.getClientFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ClientApi.endpoints.updateClient.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  return (
    <ModalForm
      name="Client"
      withTabs={false}
      title={formDetailPK ? `Клиент «${getFullNameOrEmail(formDetail)}»`: 'Новый клиент'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={ClientApi}

      onFinish={onFinish}
      onClose={() => dispatch(ClientActions.closeForm())}
      initialValues={{
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
        }
      }}
    >
      <MainFieldset />
    </ModalForm>
  );
}


export default ClientForm
