import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { HallSelectField } from 'models/Hall/components'
import { ClientSelectField } from 'models/Client/components'
import HallAccessStatusField from '../../HallAccessStatusField'

const { TextField, DateField, NumberField, TextareaField } = FormFields;


const MainFieldSet = ({ detailForEdit, initials }) => {
  const initialHall = (initials && initials?.hall) || detailForEdit?.hall?.id;
  const initialClient = (initials && initials?.client) || detailForEdit?.client?.id;

  return (
    <Row gutter={32}>
      <Col span={16}>
        <HallSelectField label="Зал" name="hall" required={true} disabled={!!initialHall} />

        <ClientSelectField label="Клиент" name="client" required={true} disabled={!!initialClient} />

        <TextField label="Название события" name="eventName" required={true} disabled={true} />

        <Row gutter={32} style={{ marginBottom: '12px' }}>
          <Col span={12}>
            <DateField label="Дата проведения" name="eventStart" required={true} disabled={false} />
          </Col>
          <Col span={12}>
            <DateField label="Дата проведения" name="eventEnd" required={true} disabled={false} />
          </Col>
        </Row>

        <NumberField label="Длительность" name="eventDuration" required={true} disabled={true} />
      </Col>

      <Col span={8}>
        <HallAccessStatusField label="Статус" name="status" />
      </Col>
    </Row>
  );
};

export default MainFieldSet;
