import { useState, useEffect, useMemo } from 'react'

import SvgIcon from 'components/SvgIcon'

import './styles.css'


const SchemePlaceEntrance = ({ outerPosition, place, scale, levelWidth, pointEditMode, updatePlace }) => {
  const [position, setPosition] = useState(place.schemeEntrancePosition || [0, 0]);

  const placeId = place.id;

  const [moveMode, setMoveMode] = useState(false);
  const [moveStartPosition, setMoveStartPosition] = useState(false);

  const size = 50;

  const realPosition = useMemo(() => {
    return [outerPosition[0] + position[0], outerPosition[1] + position[1]]
  }, [outerPosition, position]);

  useEffect(() => {
    if (!moveMode) return;

    function getXY(e) {
      const dx = e.pageX - moveStartPosition[0];
      const dy = e.pageY - moveStartPosition[1];
      const x = Math.round(position[0] + dx / scale);
      const y = Math.round(position[1] + dy / scale);
      return [x, y]
    }

    const move = (e) => {
      const pos = getXY(e);
      setPosition(pos);
    }

    const endMove = (e) => {
      setMoveMode(false);
      const pos = getXY(e);
      setPosition(pos);
      updatePlace && updatePlace({ id: placeId, schemeEntrancePosition: pos })
    }

    window.addEventListener("mousemove", move);
    window.addEventListener("mouseup", endMove);
    return () => {
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", endMove);
    }
    // eslint-disable-next-line
  }, [moveMode]);

  const onStartMoveInfo = (e) => {
    setMoveMode(true);
    setMoveStartPosition([e.pageX, e.pageY])
  }

  if (!pointEditMode) return null;

  return (
    <div className="schemePlaceEntrance" style={{ width: `${size}px`, height: `${size}px`, fontSize: `${size}px`, transform: `translate(${realPosition[0]}px, ${realPosition[1]}px)` }} onMouseDown={onStartMoveInfo}>
      <SvgIcon className="schemePlaceEntrance__icon" icon={`arrow-${place.schemeEntranceDirection}`} />
    </div>
  )
}

export default SchemePlaceEntrance
