import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { HallSelectField } from 'models/Hall/components/'

const { TextField, SwitchField, HtmlField, DateField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  const initialHall = initials && initials.hall;
  const initialStart = initials && initials.start;
  const initialEnd = initials && initials.end;

  return (
    <Row gutter={32}>
      <Col span={16}>
        <HallSelectField label="Зал" name="hall" required={false} disabled={!!initialHall} />

        <TextField label="Название" name="name" required={true} />

        <Row gutter={32} style={{ marginBottom: '24px' }}>
          <Col span={12}>
            <DateField label="Дата начала" name="start" required={true} disabled={!!initialStart} />
          </Col>
          <Col span={12}>
            <DateField label="Дата завершения" name="end" required={true} disabled={!!initialEnd} />
          </Col>
        </Row>

        <HtmlField label="Текст" name="text" tags={['event']} required={false} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />
      </Col>
    </Row>
  );
};

export default MainFieldset;
