import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Switch } from 'antd'

import { api } from 'store/api/api'


const OnOff = ({ name, detail, fieldName, pkField = 'id', apiUpdate, apiUpdateProps }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onChange = data => {
    if (!loading) {
      setLoading(true);
      apiUpdate(data);
    }
  }

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      dispatch(api.util.invalidateTags([{ type: `${name}Detail` }]))
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [apiUpdateProps.isSuccess]);

  return (
    <Switch
      checkedChildren="вкл"
      unCheckedChildren="выкл"
      checked={detail && detail[fieldName]}
      loading={loading}
      onClick={() => {
        onChange({ [pkField]: detail[pkField], [fieldName]: !detail[fieldName] });
      }}
    />
  );
};

export default OnOff;
