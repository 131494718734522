import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { BuildingLevelSelectField } from 'models/BuildingLevel/components/'
import { BuildingPlaceStatus } from 'models/BuildingPlace/components/'

const { TextField, TextareaField, NumberField, SwitchField, ImageUploadField, SelectField } = FormFields;


const MainFieldset = ({ initials }) => {
  const initialLevel = initials && initials.level;

  return (
    <Row gutter={32}>
      <Col span={16}>
        <BuildingLevelSelectField label="Этаж" name="level" required={true} disabled={!!initialLevel} />

        <SelectField
          label="Статус"
          name="status"
          required={true}
          options={
            BuildingPlaceStatus.STATUS_LIST.map(item => ({ value: item[0], label: item[1] }))
          }
        />

        <Row gutter={32} style={{ marginBottom: '16px' }}>
          <Col flex="150px">
            <ImageUploadField
              label="Иконка" name="icon" data={{ tags: ['place'] }} required={false}
              title="Загрузить иконку" single={true}
            />
          </Col>
          <Col flex="auto">
            <TextField label="Название" name="name" required={true} />

            <TextField label="Номер" name="number" required={false} />
          </Col>
        </Row>

        <TextareaField label="Описание" name="text" required={false} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Площадь" name="area" required={false} />

        <TextField label="Назначение" name="purpose" required={false} />

        <TextField label="Отделка" name="furnish" required={false} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
