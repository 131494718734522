import { FormFields } from 'components/common'
import HallRequestStatus from '../HallRequestStatus'

const { SelectField } = FormFields;


const HallRequestStatusField = ({ name, label, required, disabled }) => {
  return (
    <SelectField
      label={label}
      name={name}
      required={true}
      disabled={disabled}
      rules={[{ required: required }]}
      options={
        HallRequestStatus.STATUS_LIST.map(item => ({ value: item[0], label: item[1] }))
      }
    />
  );
}

HallRequestStatusField.getValue = (values) => {
  return values;
}

HallRequestStatusField.getDisplayValue = (values, config) => {
  return values
};

export default HallRequestStatusField
