import { Form, DatePicker } from 'antd'

import "./styles.css"


const DateField = ({ name, label, required, disabled, format='DD.MM.YYYY HH:mm', showTime=true }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <DatePicker
        format={format}
        showTime={showTime ? { format: 'HH:mm' }: false}
        minuteStep={30}
        size="large"
        disabled={disabled}
        locale={{
          "lang": {
            "locale": "ru_RU",
            "placeholder": "Выберите дату",
            "rangePlaceholder": ["Дата от", "Дата до"],
            "today": "Сегодня",
            "now": "Сейчас",
            "backToToday": "Назад к сегодня",
            "ok": "OK",
            "clear": "Очистить",
            "month": "Месяц",
            "year": "Год",
            "timeSelect": "Выбрать время",
            "dateSelect": "Выбрать дату",
            "monthSelect": "Выбрать месяц",
            "yearSelect": "Выбрать год",
            "decadeSelect": "Выбрать декаду",
            "yearFormat": "YYYY",
            "dateFormat": "DD.MM.YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Previous month (PageUp)",
            "nextMonth": "Next month (PageDown)",
            "previousYear": "Last year (Control + left)",
            "nextYear": "Next year (Control + right)",
            "previousDecade": "Last decade",
            "nextDecade": "Next decade",
            "previousCentury": "Last century",
            "nextCentury": "Next century"
          },
          "timePickerLocale": {
            "placeholder": "Выберите время"
          },
          "dateFormat": "YYYY-MM-DD",
          "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
          "weekFormat": "YYYY-wo",
          "monthFormat": "YYYY-MM"
        }}
      />
    </Form.Item>
  );
};

export default DateField;
