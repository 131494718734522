import { useMemo } from 'react'

import './styles.css'


const STATUS_LIST = [
  ['freeToBuy', 'Доступно для покупки'],
  //['freeToRent', 'Доступно для аренды'],
  ['free', 'Доступно для аренды/покупки'],
  ['booked', 'Продано/сдано'],
  ['common', 'Зона общего пользования'],
  ['closed', 'Недоступно'],
]

const STATUS = STATUS_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {})


const BuildingPlaceStatus = ({ detail }) => {
  const status = useMemo(() => {
    if (detail.hall || detail.tenant) {
      return 'booked'
    }
    return detail.status;
  }, [detail.status, detail.hall, detail.tenant]);

  return (
    <div className={`placeStatus ${status}`}>{STATUS[status] || status}</div>
  );
};

BuildingPlaceStatus.STATUS_LIST = STATUS_LIST;
BuildingPlaceStatus.STATUS = STATUS;


export default BuildingPlaceStatus
