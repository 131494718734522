import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, getSimpleListExtraReducers, openForm, closeForm } from 'store/common'
import api, { pkField } from './api'


const initialState = {
  ...baseModelState,
}


export const tenantService = createSlice({
  name: 'tenantService',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateTenantService);
    getSimpleListExtraReducers(builder, api.endpoints.getTenantServiceSimpleList, pkField);
  },
});

export default tenantService.reducer;

export const TenantServiceActions = tenantService.actions;
