import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { TextField, NumberField, SwitchField } = FormFields;


const MainFieldSet = ({ detailForEdit }) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <TextField label="Название" name="name" required={true} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldSet;
