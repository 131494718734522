import { Form } from 'antd'

import HallSelect from '../HallSelect'


const HallSelectField = ({ name, label, required, disabled, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <HallSelect allowClear={!required} required={required} disabled={disabled} filter={filter} onChange={onChange} />
    </Form.Item>
  );
}

HallSelectField.getValue = (values) => {
  return values;
}

HallSelectField.getDisplayValue = (values, config) => {
  return values
};

export default HallSelectField
