import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom"
import { Menu } from 'antd'
import {
  DashboardOutlined, TeamOutlined, ApartmentOutlined, FieldTimeOutlined, CarOutlined, CreditCardOutlined,
  ToolOutlined, ScheduleOutlined, SolutionOutlined, SettingOutlined, HomeOutlined
} from '@ant-design/icons'

import './styles.css';


function getItem(label, key, icon, type, children) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const getMenuItems = (user) => {
  let items = [
    getItem('Рабочий стол', '/', <DashboardOutlined />),
    { type: 'divider' },
  ];

  const isAdmin = user?.is_superuser;
  const roles = user?.roles || [];

  if (isAdmin || roles.includes('tenant')) {
    items = items.concat([
      getItem('Арендаторы', 'tenants-group', null, 'group'),

      getItem('Арендаторы', '/tenant/', <ApartmentOutlined />),
      getItem('Схемы этажей', '/building/', <HomeOutlined />),
      getItem('Залы в аренду', '/hall/', <ApartmentOutlined />),
      getItem('Заявки на бронь залов', '/hall-request/', <SolutionOutlined />),
      getItem('Доступы к залам', '/hall-access/', <SolutionOutlined />),

      { type: 'divider' },
    ]);
  }

  if (isAdmin || roles.includes('card')) {
    items = items.concat([
      getItem('Клиенты', 'client-group', null, 'group'),

      getItem('Клиенты', '/client/', <TeamOutlined />),
      getItem('Клубные карты', '/card/', <CreditCardOutlined />),
      getItem('Заявки на карты', '/card-request/', <SolutionOutlined />),
      getItem('Заявки на обслуживание', '/support/', <ToolOutlined />),

      { type: 'divider' },
    ]);
  }

  if (isAdmin || roles.includes('event')) {
    items = items.concat([
      getItem('События', 'event-group', null, 'group'),

      getItem('События', '/event/', <FieldTimeOutlined />),
      getItem('Новости', '/news/', <ScheduleOutlined />),

      { type: 'divider' },
    ]);
  }

  if (isAdmin || roles.includes('parking')) {
    items = items.concat([
      getItem('Парковка', 'parking-group', null, 'group'),

      getItem('Парковочные карты', '/parking-card/', <CreditCardOutlined />),
      getItem('Тарифы парковки', '/rps-tariff/', <CarOutlined />),
      //getItem('QR-пропуски', '/parking-pass/', <CarOutlined />),

      { type: 'divider' },
    ]);
  }

  if (isAdmin) {
    items = items.concat([
      getItem('Настройки', '/settings/', <SettingOutlined />),
    ]);
  }

  return items;
}


const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector(state => state.auth.user);

  const allKeys = useMemo(() => {
    return location.pathname.slice(1, -1).split('/').reduce((result, slug) => {
      const before = result.length > 0 ? result[result.length - 1] : '/';
      if (slug === '') {
        result.push(`/`);
      } else {
        result.push(`${before}${slug}/`);
      }
      return result
    }, []);
  }, [location.pathname]);

  const onNavigate = ({ key }) => {
    navigate(key);
  }

  const items = getMenuItems(user);

  return (
    <Menu
      className="navigation"
      mode="inline"
      theme="dark"
      items={items}
      defaultOpenKeys={allKeys}
      //defaultSelectedKeys={[currentPath, '/tenant/tenant/']}
      selectedKeys={allKeys}
      onClick={onNavigate}
    />
  );
}

export default Navigation;
