import { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Spin } from 'antd'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ruLocale from '@fullcalendar/core/locales/ru'
import dayjs from 'dayjs'

import { getObjectSearchParams, getDataQueryString } from 'utils/urls'
import EventApi from 'models/Event/api'
import { HallSelect } from 'models/Hall/components'

import './styles.css'


const queryKey = "event";


const EventCalendar = ({ onEdit }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [apiGetList, apiGetListProps] = EventApi.endpoints.getEventList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = EventApi.endpoints.updateEvent.useMutation();

  const objectList = apiGetListProps.data?.results || [];

  const [hall, setHall] = useState(null);

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const objectSearchParams = useMemo(() => {
    return getObjectSearchParams(location.search, queryKey);
  }, [location.search]);


  useEffect(() => {
    if (hall === null && objectSearchParams.get('hall')) {
      const hallId = parseInt(objectSearchParams.get('hall'));
      onHallSelect(hallId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      loadData();
    }
    // eslint-disable-next-line
  }, [apiUpdateProps.isSuccess]);

  const onLoadData = ({ dataQuery }) => {
    apiGetList(dataQuery);
  }

  function loadData() {
    const [dataQuery] = getDataQueryString(searchParams, objectSearchParams, queryKey);
    onLoadData({ dataQuery });
  }

  const onHallSelect = (val) => {
    setHall(val);
    objectSearchParams.set('hall', val);

    const [dataQuery, searchString] = getDataQueryString(searchParams, objectSearchParams, queryKey);
    navigate({ search: searchString, replace: true });
    onLoadData({ dataQuery });
  }

  const onHallLoaded = (items) => {
    if (hall === null && items.length > 0) {
      onHallSelect(items[0].id);
    }
  }

  const updateEvent = (info) => {
    if (window.confirm("Вы уверены, что хотите перенести событие на эту дату?")) {
      apiUpdate({ id: info.event.id, start: info.event.start, end: info.event.end })
    } else {
      info.revert();
    }
  }

  return (
    <Spin spinning={apiGetListProps.isLoading || apiUpdateProps.isLoading}>
      <div className="calendar">

        <div className="calendarHeader">
          <HallSelect width="180px" required={true} value={hall} onChange={onHallSelect} onLoaded={onHallLoaded} />
        </div>

        <FullCalendar
          height={1540}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          locale={ruLocale}
          headerToolbar={{
            start: '',
            center: 'prev,title,next',
            end: 'today',
          }}
          customButtons={{
            myCustomButton: {
              text: 'custom!',
              click: function () {
                alert('clicked the custom button!');
              }
            }
          }}

          allDaySlot={false}
          slotDuration="00:30:00"
          slotMinTime="09:00:00"
          slotMaxTime="24:00:00"
          selectable={true}
          editable={true}
          stickyHeaderDates={true}
          nowIndicator={true}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short'
          }}
          events={objectList}
          dateClick={(info) => {
            onEdit(null, { hall: hall, start: dayjs(info.date), end: dayjs(info.date).add(1, 'hour') })
          }}

          eventClick={(info) => {
            //onEdit(info.event.id);
            navigate(`/event/${info.event.id}/`)
          }}
          eventDrop={(info) => {
            updateEvent(info)
          }}
          eventResize={(info) => {
            updateEvent(info)
          }}
        />
      </div>
    </Spin>
  );
}

export default EventCalendar;
