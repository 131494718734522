import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { authApi } from 'store/api/authApi'


const initialState = {
  dataIsLoaded: false,
}

export const appSlice = createSlice({
  initialState,
  name: 'appSlice',
  reducers: {
    initCompleted: (state) => {
      state.dataIsLoaded = true;
    },
  },
});

export default appSlice.reducer;


const appInit = createAsyncThunk(
  'app/init',
  async (arg, thunkAPI) => {
    const tokenOld = thunkAPI.getState().auth.token;
    if (tokenOld) {
      await thunkAPI.dispatch(authApi.endpoints.tokenRefresh.initiate(tokenOld));
      console.log('appInit: load initials data');

      await thunkAPI.dispatch(appSlice.initCompleted());
      return true;
    }
    return false;
  }
)

export { appInit }
