import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { ModalForm } from 'components/common'
import HallRequestApi, { pkField, getDetailUrl } from 'models/HallRequest/api'
import { HallRequestActions } from 'models/HallRequest/slice'
import MainFieldSet from './MainFieldSet'


const HallRequestStatusForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.hallRequest);

  const [apiGetFormDetail, apiGetFormDetailProps] = HallRequestApi.endpoints.getHallRequestFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = HallRequestApi.endpoints.updateHallRequest.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    const data = {
      [pkField]: values[pkField],
      status: values.status,
      message: values.message,
      eventName: values.eventName,
      eventStart: values.eventStart,
      eventDuration: values.eventDuration,
    }
    console.log(data);
    //apiUpdate(data); 
  };

  return (
    <ModalForm
      name="HallRequest"
      withTabs={false}
      title={formDetailPK ? `Заявка №${formDetailPK}`: 'Новая заявка'}
      width={700}
      mode={mode}
      visible={activeForm === 'statusForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={HallRequestApi}

      onFinish={onFinish}
      onClose={() => dispatch(HallRequestActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return { 
          id: detail.id,
          status: initials?.status || detail.status,
          eventName: detail.eventName,
          eventStart: dayjs(detail.eventStart),
          eventDuration: detail.eventDuration,
         }
      }}
    >
      <MainFieldSet initials={initials} status={initials?.status || formDetail?.status} />
    </ModalForm>
  );
}


export default HallRequestStatusForm
