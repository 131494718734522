import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { Fieldset, Descriptions, DateTimeValue, OnOff } from 'components/common'
import CardApi, { pkField } from 'models/Card/api'
import { CardTypeValue } from 'models/CardType/components'
import { ClientValue } from 'models/Client/components'


const CardDetail = ({ detail }) => {
  const [apiUpdate, apiUpdateProps] = CardApi.endpoints.updateCard.useMutation();
  const [apiGetDetail] = CardApi.endpoints.getCardDetail.useLazyQuery();

  const pk = detail[pkField];

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line
  }, [pk, apiUpdateProps.isSuccess]);

  return (
    <>
      <Fieldset>
        <Descriptions>
          <Descriptions.Item span={12} label="Активно">
            <OnOff name="Card" detail={detail} pkField={pkField} fieldName="isActive" apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />
          </Descriptions.Item>

          <Descriptions.Item span={6} label="Дата создания">
            <DateTimeValue date={detail?.dateCreated} />
          </Descriptions.Item>
          <Descriptions.Item span={6} label="Дата изменения">
            <DateTimeValue date={detail?.dateChanged} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Row gutter={16}>
        <Col span={16}>
          <Fieldset title="Основное">
            <Descriptions>
              <Descriptions.Item span={12} label="Тип карты">
                <CardTypeValue detail={detail?.cardType} asLink={true} isBack={true} />
              </Descriptions.Item>

              <Descriptions.Item span={12} label="Номер">{detail?.number}</Descriptions.Item>

              <Descriptions.Item label="Клиент">
                {detail?.client && <ClientValue detail={detail?.client} asLink={true} isBack={true} />}
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>

          <Fieldset title="Данные">
            <Descriptions>
              <Descriptions.Item span={12} label="Дата начала действия">
                <DateTimeValue date={detail?.startDate} />
              </Descriptions.Item>
              <Descriptions.Item span={12} label="Дата окончания действия">
                <DateTimeValue date={detail?.stopDate} />
              </Descriptions.Item>

              <Descriptions.Item span={8} label="Скидка">{detail?.discount}</Descriptions.Item>
              <Descriptions.Item span={8} label="Бонус">{detail?.bonus}</Descriptions.Item>
              <Descriptions.Item span={8} label="Остаток">{detail?.restSum}</Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>

        <Col span={8}>
          <Fieldset title="Контакты">
            <Descriptions>
              <Descriptions.Item label="ФИО">{detail?.first_name} {detail?.last_name}</Descriptions.Item>

              <Descriptions.Item label="Телефон">{detail?.phone}</Descriptions.Item>

              <Descriptions.Item label="Email">{detail?.email}</Descriptions.Item>

              <Descriptions.Item label="День рождения">{detail?.birthday}</Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>
      </Row>
    </>
  );
}

export default CardDetail
