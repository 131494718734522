import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from 'models/BuildingLevel/api'


const initialState = {
  ...baseModelState,
}


export const buildingLevel = createSlice({
  name: 'buildingLevel',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateBuildingLevel);
  },
});

export default buildingLevel.reducer;

export const BuildingLevelActions = buildingLevel.actions;
