import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'hall/access/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/hall-access/${detail[pkField]}/`
}


const HallAccessApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHallAccessList: getList(builder, baseApiUrl, 'HallAccessList'),
    getHallAccessSimpleList: getList(builder, baseApiUrl, 'HallAccessSimpleList', { simple: true }),

    getHallAccessDetail: getDetail(builder, baseApiUrl, 'HallAccessDetail'),
    getHallAccessFormDetail: getDetail(builder, baseApiUrl, 'HallAccessFormDetail', { forEdit: true }),

    updateHallAccess: update(builder, baseApiUrl, pkField),

    deleteHallAccess: remove(builder, baseApiUrl),
    batchDeleteHallAccess: batchRemove(builder, baseApiUrl),
  }),
});


export default HallAccessApi;
