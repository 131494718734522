import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { TextField, DateField, PhoneField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <TextField label="ФИО" name="name" required={false} />

        <PhoneField label="Телефон" name="phone" required={false} />
      </Col>

      <Col span={8}>
        <DateField label="Дата последней оплаты" name="dateLastPaid" required={true} format="DD.MM.YYYY" showTime={false} />

        <DateField label="Дата следующей оплаты" name="dateNextPaid" required={true} format="DD.MM.YYYY" showTime={false} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
