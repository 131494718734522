import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { TextField, TextareaField, NumberField, SwitchField } = FormFields;


const MainFieldset = () => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <TextField label="Название" name="name" required={true} />

        <TextareaField label="Описание" name="text" required={false} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
