import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { Fieldset, Avatar, Descriptions, DateTimeValue, BoolValue, OnOff } from 'components/common'
import ClientApi, { pkField } from 'models/Client/api'


const ClientDetail = ({ detail }) => {
  const [apiUpdate, apiUpdateProps] = ClientApi.endpoints.updateClient.useMutation();
  const [apiGetDetail] = ClientApi.endpoints.getClientDetail.useLazyQuery();

  const pk = detail[pkField];

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line
  }, [pk, apiUpdateProps.isSuccess]);

  return (
    <>
      <Fieldset>
        <Descriptions>
          <Descriptions.Item span={6} label="Активен">
          <OnOff name="Client" detail={detail} pkField={pkField} fieldName="isActive" apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />
          </Descriptions.Item>

          <Descriptions.Item span={6} label="Дата регистрации">
            <DateTimeValue date={detail?.date_joined} />
          </Descriptions.Item>
          <Descriptions.Item span={6} label="Дата последнего входа">
            <DateTimeValue date={detail?.last_login} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Row gutter={16}>
        <Col flex="auto">
          <Fieldset title="Основное">
            <Descriptions>
              <Descriptions.Item label="Фамилия" span={12}>{detail?.last_name}</Descriptions.Item>

              <Descriptions.Item label="Имя" span={12}>{detail?.first_name}</Descriptions.Item>

              <Descriptions.Item label="Email" span={10}>{detail?.email}</Descriptions.Item>

              <Descriptions.Item label="Телефон" span={9}>{detail?.phone}</Descriptions.Item>

              <Descriptions.Item label="Тел. подтв." span={5}>
                <BoolValue value={detail?.isPhoneConfirmed} />
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>

        <Col flex="232px">
          <Fieldset title="Фоторафия">
            <Avatar image={detail?.photo} size={166} />
          </Fieldset>
        </Col>
      </Row>
    </>
  );
}

export default ClientDetail
