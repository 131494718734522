import { useDispatch } from 'react-redux'

import { TableList, DateTimeValue, PropertyList, TableColumns } from 'components/common'

import HallRequestApi, { getDetailUrl, pkField } from 'models/HallRequest/api'
import { HallRequestActions } from 'models/HallRequest/slice'

import { ClientValue } from 'models/Client/components'
import { HallValue } from 'models/Hall/components'
import HallRequestValue from '../HallRequestValue'
import HallRequestStatus from '../HallRequestStatus'

const defaultColumnList = ['title', 'hall', 'event', 'client', 'actions'];


const HallRequestList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = HallRequestApi.endpoints.getHallRequestList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = HallRequestApi.endpoints.updateHallRequest.useMutation();
  const [apiDelete, apiDeleteProps] = HallRequestApi.endpoints.deleteHallRequest.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = HallRequestApi.endpoints.batchDeleteHallRequest.useMutation();

  const dispatch = useDispatch();

  console.log('filterParams', filterParams);

  const fields = {
    title: {
      title: 'Номер',
      sorter: true,
      width: 150,
      render: (val, item) => (
        <>
          <HallRequestValue detail={item} asLink={true} isBack={true} />
          <HallRequestStatus detail={item} style={{ marginTop: '10px' }} />
        </>
      ),
    },
    hall: {
      title: 'Зал',
      sorter: true,
      width: 160,
      render: (val, item) => <HallValue detail={val} asLink={true} isBack={true} />,
    },
    status: {
      title: 'Статус',
      width: 156,
      render: (val, item) => <HallRequestStatus detail={item} />,
    },
    event: {
      title: 'Событие',
      render: (val, item) => (
        <PropertyList
          style={{ padding: '0', border: '0' }}
          items={[
            { label: 'Название:', value: `${item.eventName}` },
            { label: 'Дата пров.:', value: <DateTimeValue size={12} date={item.eventStart} /> },
            { label: 'Длительность:', value: `${item.eventDuration} ч.` },
          ]}
        />
      ),
    },
    client: {
      title: 'Клиент',
      render: (val, item) => <ClientValue detail={val} showPhone={true} asLink={true} isBack={true} />,
    },

    actions: TableColumns.getColumnActions({
      getDetailUrl,
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(HallRequestActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        { name: 'delete', action: () => apiDelete(item[pkField]) },
      ]
    }),
  };

  return (
    <TableList
      queryKey="hallRequest"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default HallRequestList;
