import { Form } from 'antd'

import BuildingSelect from '../BuildingSelect'


const BuildingSelectField = ({ name, label, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <BuildingSelect required={required} disabled={disabled} />
    </Form.Item>
  );
}

BuildingSelectField.getValue = (values) => {
  return values;
}

BuildingSelectField.getDisplayValue = (values, config) => {
  return values
};

export default BuildingSelectField
