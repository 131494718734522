import { Page } from 'layout'

import { BuildingList, BuildingForm } from 'models/Building/components'



const BuildingListPage = () => {
  return (
    <Page
      title="Схемы этажей"
      basePath="/building/"
    >
      <BuildingList />

      <BuildingForm mode="list" />
    </Page>
  )
}

export default BuildingListPage
