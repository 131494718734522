import { useState, useMemo } from 'react'

import SchemePlaceInfo from './SchemePlaceInfo'
import SchemePlaceActions from './SchemePlaceActions'
import SchemePlacePointEdit from './SchemePlacePointEdit'
import SchemePlaceEntrance from './SchemePlaceEntrance'

import './styles.css'


const SchemePlace = ({ parentEl, place, scale, levelWidth, updatePlace, onPlaceEdit, onPlaceDelete }) => {
  const [position, setPosition] = useState(place.schemePosition);
  const [pointEditMode, setPointEditMode] = useState(false);

  const [points, setPoints] = useState(place.schemePoints);

  const [width, height] = useMemo(() => {
    const width = Math.max.apply(Math, points.map(o => o[0]));
    const height = Math.max.apply(Math, points.map(o => o[1]));
    return [width, height]
  }, [points]);

  const onPointEdit = () => {
    setPointEditMode(!pointEditMode);
  }

  const [isBusy, statusClass] = useMemo(() => {
    const used = place.hall || place.tenant;
    if (!used && (place.status === 'booked' || place.status === "busy")) {
      return [true, place.status];
    }
    return [false, used ? 'isUsed' : place.status];
  }, [place.status, place.hall, place.tenant]);

  let style = {
    width: `${width}px`,
    height: `${height}px`,
    transform: `translate(${position[0]}px, ${position[1]}px)`,
  }
  if (!pointEditMode) {
    style['clipPath'] = `url(#myPath${place.id})`;
  }

  return (
    <>
      <svg style={{ position: 'absolute', zIndex: -1 }}>
        <clipPath id={`myPath${place.id}`}>
          <polygon points={points} />
        </clipPath>
      </svg>
      <div
        className={`schemePlace ${statusClass} ${pointEditMode && 'pointEditMode'}`}
        style={style}
      >
        <div className="schemePlace__inner">
          <SchemePlaceInfo
            isBusy={isBusy}
            place={place}
            hall={place.hall}
            tenant={place.tenant}
            width={width}
            height={height}

            scale={scale}
            updatePlace={updatePlace}
          />

          <SchemePlaceActions
            placeId={place?.id}
            scale={scale}
            position={position}
            points={points}
            setPosition={setPosition}
            updatePlace={updatePlace}

            onPointEdit={onPointEdit}
            onPlaceEdit={onPlaceEdit}
            onPlaceDelete={onPlaceDelete}
          />
        </div>

        <SchemePlacePointEdit
          placeId={place.id}
          parentEl={parentEl}
          pointEditMode={pointEditMode}
          points={points}
          setPoints={setPoints}
          schemePosition={position}
          scale={scale}

          position={position}
          setPosition={setPosition}
          updatePlace={updatePlace}
        />

        <svg className="schemePlace__svg" width={width} height={height}>
          <defs>
            <pattern id="pattern" patternUnits="userSpaceOnUse" width="18.5" height="18.5" patternTransform="rotate(45)">
              <line x1="0" y="0" x2="0" y2="18.5" stroke="#ccc" strokeWidth="35" />
            </pattern>
          </defs>
          <polygon
            points={points}
            fill={isBusy ? "url(#pattern)" : null}
            opacity={isBusy ? "0.5" : "1.0"}
          />
        </svg>
      </div>
      <SchemePlaceEntrance
        place={place}
        scale={scale}
        levelWidth={levelWidth}
        pointEditMode={pointEditMode}
        updatePlace={updatePlace}
        outerPosition={position}
      />
    </>
  );
}

export default SchemePlace
