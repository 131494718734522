import { Image } from 'components/common'

import './styles.css'


const ImageGallery = ({ images }) => {
  return (
    <div>
      {images && images.map(img => (
        <div key={`photo${img.sha1}`}>
          <Image src={img.url} ratio={4 / 3} />
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
