import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from './api'


const initialState = {
  ...baseModelState,
}


export const cardUpload = createSlice({
  name: 'cardUpload',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateCardUpload);
  },
});

export default cardUpload.reducer;

export const CardUploadActions = cardUpload.actions;
