import { useSelector, useDispatch } from 'react-redux'
import { Tabs } from 'antd'

import { ModalForm } from 'components/common'
import TenantApi, { pkField, getDetailUrl } from 'models/Tenant/api'
import { TenantActions } from 'models/Tenant/slice'
import MainFieldset from './MainFieldset'
import GalleryFieldset from './GalleryFieldset'
import ContactsFieldset from './ContactsFieldset'


const TenantForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.tenant);

  const [apiGetFormDetail, apiGetFormDetailProps] = TenantApi.endpoints.getTenantFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = TenantApi.endpoints.updateTenant.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const tabs = [
    { key: 'detail', label: 'Основное', forceRender: true, children: <MainFieldset initials={initials} /> },
    { key: 'gallery', label: 'Галерея', forceRender: true, children: <GalleryFieldset initials={initials} /> },
    { key: 'contacts', label: 'Контакты', forceRender: true, children: <ContactsFieldset initials={initials} /> },
  ];

  return (
    <ModalForm
      name="Tenant"
      withTabs={true}
      title={formDetailPK ? `Арендатор «${formDetail?.name}»`: 'Новый арендатор'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={TenantApi}

      onFinish={onFinish}
      onClose={() => dispatch(TenantActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
        }
      }}
    >
      <Tabs className="tabsForm" type="card" items={tabs} />
    </ModalForm>
  );
}


export default TenantForm
