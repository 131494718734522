import { ExclamationCircleOutlined, FieldTimeOutlined, CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons"

import './styles.css'


const STATUS_LIST = [
  ['new', 'Новая'],
  ['inProgress', 'В обработке'],
  ['completed', 'Подтверждена'],
  ['declined', 'Отклонена'],
]

const STATUS = STATUS_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {});

const ICONS = {
  'new': <ExclamationCircleOutlined />,
  'inProgress': <FieldTimeOutlined />,
  'completed': <CheckCircleOutlined />,
  'declined': <CloseCircleOutlined />,
}


const CardRequestStatus = ({ detail }) => {
  const status = detail?.status;

  const icon = ICONS[status];

  return (
    <div className={`requestStatus ${status}`}>{icon} {STATUS[status] || status}</div>
  );
};

CardRequestStatus.STATUS_LIST = STATUS_LIST;
CardRequestStatus.STATUS = STATUS;


export default CardRequestStatus
