import { Form, Input } from 'antd'


const TextareaField = ({ name, label, required, disabled, rows = 4 }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <Input.TextArea rows={rows} size="large" disabled={disabled} />
    </Form.Item>
  );
};

export default TextareaField;
