import { useState } from 'react'
import { Button, Popconfirm } from 'antd'

import { getNoun } from 'utils/helpers'

import './styles.css'


const ButtonAction = ({ action, selected, clearSelected }) => {
  const [open, setOpen] = useState(false);

  const confirm = () => {
    action.action(selected);
    setOpen(false);
    clearSelected();
  };

  const cancel = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    if (!newOpen) {
      setOpen(newOpen);
      return;
    }
    if (!action.confirm) {
      confirm(); // next step
    } else {
      setOpen(newOpen);
    }
  };

  return (
    <Popconfirm
      open={open}
      onOpenChange={handleOpenChange}
      title={action.title}
      okText={action.confirm?.okText || 'Да'}
      cancelText={action.confirm?.cancelText || 'Нет'}
      onConfirm={confirm}
      onCancel={cancel}
      onClick={e => e.stopPropagation()}
    >
      <Button type={action.type} size="small">
        {action.title}
      </Button>
    </Popconfirm>
  )
}


const TableRawActions = ({ rowActions, selected, clearSelected }) => {
  if (!rowActions || rowActions.length === 0) {
    return null
  }
  const count = selected.length;

  if (count === 0) {
    return null
  }

  return (
    <div className="tableRowActions">
      <div className="tableRowActions__title">{count} {getNoun(count, 'элемент', 'элемента', 'элементов')}:</div>
      {rowActions.map((action, index) => (
        <div className="tableTopRowActions__item" key={`row-action${index}`}>
          <ButtonAction action={action} selected={selected} clearSelected={clearSelected} />
        </div>
      ))}
    </div>
  );
};

export default TableRawActions;
