import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { ClientSelectField } from 'models/Client/components'
import { CardTypeSelectField } from 'models/CardType/components'

const { TextField, SwitchField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <ClientSelectField label="Клиент" name="client" required={false} />

        <CardTypeSelectField label="Тип карты" name="cardType" required={true} />

        <TextField label="Номер карты" name="number" required={true} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />
      </Col>
    </Row>
  );
};

export default MainFieldset;
