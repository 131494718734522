import { useState, useEffect } from 'react'

import './styles.css'


const SchemePlaceHandle = ({ index, point, scale, movePoint, updatePoint }) => {
  const [moveMode, setMoveMode] = useState(false);
  const [moveStartPosition, setMoveStartPosition] = useState(false);
  const [position, setPosition] = useState(point);

  useEffect(() => {
    setPosition(point);
  }, [point]);

  useEffect(() => {
    if (!moveMode) return;

    function getXY(e) {
      const dx = e.pageX - moveStartPosition[0];
      const dy = e.pageY - moveStartPosition[1];
      const x = Math.round(position[0] + dx / scale);
      const y = Math.round(position[1] + dy / scale);
      return [x, y]
    }

    const move = (e) => {
      const pos = getXY(e);
      setPosition(pos);
      movePoint && movePoint(index, pos)
    }

    const endMove = (e) => {
      setMoveMode(false);
      const pos = getXY(e);
      setPosition(pos);
      updatePoint && updatePoint(index, pos)
    }

    window.addEventListener("mousemove", move);
    window.addEventListener("mouseup", endMove);
    return () => {
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", endMove);
    }
    // eslint-disable-next-line
  }, [moveMode]);

  return (
    <div
      className="schemePlace__handle"
      style={{ transform: `translate3d(${position[0]}px,${position[1]}px, 9999px)` }}
      data-idx={index}
      onMouseDown={e => {
        setMoveMode(true);
        setMoveStartPosition([e.pageX, e.pageY])
      }}
    />
  );
}

export default SchemePlaceHandle
