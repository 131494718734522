import { useSelector, useDispatch } from 'react-redux'

import { ModalForm } from 'components/common'
import CardUploadApi, { pkField, getDetailUrl } from 'models/CardUpload/api'
import { CardUploadActions } from 'models/CardUpload/slice'
import MainFieldset from './MainFieldset'


const CardPCCardUploadForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.cardUpload);

  const [apiGetFormDetail, apiGetFormDetailProps] = CardUploadApi.endpoints.getCardUploadFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CardUploadApi.endpoints.updateCardUpload.useMutation();

  const dispatch = useDispatch();

  const onFinish = (values) => {
    console.log('values', values);
    const data = new FormData();
    data.append("file", values.dbf_file[0].originFileObj);
    data.append("virtualNumberStart", values.virtualNumberStart || 0);
    data.append("virtualNumberEnd", values.virtualNumberEnd || 0);
    apiUpdate(data);
  };

  return (
    <ModalForm
      name="Card"
      withTabs={false}
      title="Загрузка карт из PC Cards"
      width={500}
      mode={mode}
      visible={activeForm === 'pcCardUploadForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={CardUploadApi}

      submitTitle="Загрузить"

      onFinish={onFinish}
      onClose={(apiUpdateProps) => {
        dispatch(CardUploadActions.closeForm())
      }}
      initialValues={{
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
        }
      }}
    >
      <MainFieldset initials={initials} />
    </ModalForm>
  );
}


export default CardPCCardUploadForm
