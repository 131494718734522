import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { TextField, TextareaField, HtmlField } = FormFields;


const ContactsFieldset = ({ detailForEdit }) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <TextField label="Адрес" name="address" required={false} />

        <TextField label="Телефон" name="phone" required={false} />

        <TextareaField label="Режим работы" name="worktime" required={false} />

        <HtmlField label="Как пройти" name="pathText" tags={['tenants']} required={false} />
      </Col>
    </Row>
  );
};

export default ContactsFieldset;
