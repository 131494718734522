import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'

import { HallAccessActions } from 'models/HallAccess/slice'
import { HallAccessList, HallAccessForm } from '../../components'


const filterFields = [
  SmartFilter.getFieldConfig('dateCreated', 'Дата', SmartFilter.FilterDateRange),
];


const STATUS_TABS = [
  { getLabel: (count) => `Активные ${count}`, key: `active` },
  { getLabel: (count) => `Завршенные ${count}`, key: `completed` },
  { getLabel: (count) => `Истекшие ${count}`, key: `expired` },
  { getLabel: (count) => `Отмененные ${count}`, key: `cancelled` },
]


const HallAccessListPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const listInfo = useSelector(state => state.hallAccess.listInfo);
  const statuses = listInfo?.statuses || {};

  const onEdit = () => {
    dispatch(HallAccessActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = ({ dataQuery, searchString }) => {
    //tableRef.current.loadData({ dataQuery, searchString });
  }

  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get('status');

  useEffect(() => {
    if (!status) {
      const key = STATUS_TABS[0].key;
      navigate({ search: `?status=${key}` }, { replace: true });
    }
    // eslint-disable-next-line
  }, [status]);

  const onTabChange = (key) => {
    navigate({ search: `?status=${key}` }, { replace: true });
  }

  const tabs = STATUS_TABS.map(item => ({ label: item.getLabel(statuses[item.key] || ''), key: item.key }));

  return (
    <Page
      title="Доступы к залам"

      headerMiddle={<SmartFilter queryKey="hallAccess" onChange={onFilterChange} fields={filterFields} />}
      mainActions={[
        { name: 'add', action: () => onEdit() },
      ]}

      basePath="/hall-access/"
      tabs={tabs}
      onTabChange={onTabChange}
    >
      <SmartFilterValues queryKey="hallAccess" onChange={onFilterChange} fields={filterFields} />

      {status && <HallAccessList filterParams={{ status }} />}

      <HallAccessForm mode="list" />
    </Page>
  )
}

export default HallAccessListPage
