import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { API_URL } from 'config'
import { combineDataQuery } from 'utils/urls'


const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/api/admin/v1/`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `JWT ${token}`);
    }

    if (endpoint !== 'updateCardUpload') {
      headers.set("Accept", 'application/json');
      headers.set("Content-Type", 'application/json');
    }
    return headers;
  }
})

export const api = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
})


export const getList = (builder, url, providesTag = null, additionalParams = null) => builder.query({
  providesTags: (result) => {
    const results = Array.isArray(result) ? result: result?.results;
    if (results) {
      return [...results.map(({ id }) => ({ type: providesTag, id })), { type: providesTag, id: 'LIST' }]
    }
    return [{ type: providesTag, id: 'LIST' }]
  },
  query: ({ params = null, filterParams = null } = {}) => {
    let combinedParams = combineDataQuery(params, filterParams);
    if (additionalParams) {
      combinedParams = combineDataQuery(combinedParams, additionalParams);
    }
    return {
      url,
      params: combinedParams,
    }
  },
})


export const getDetail = (builder, url, providesTag = null, additionalParams = null) => builder.query({
  providesTags: [providesTag],
  query: (id) => ({
    url: `${url}${id}/`,
    params: additionalParams,
  }),
})


export const update = (builder, baseUrl, pkField = 'id') => builder.mutation({
  query(values) {
    let url = baseUrl;
    let method = 'POST';
    const id = values[pkField];
    if (id) {
      url = `${url}${id}/`;
      method = 'put';
    }
    return {
      url,
      method,
      body: values,
    };
  },
})


export const remove = (builder, baseUrl) => builder.mutation({
  query(id) {
    const url = `${baseUrl}${id}/`;
    return {
      url,
      method: 'DELETE',
    };
  },
})


export const batchRemove = (builder, baseUrl) => builder.mutation({
  query(selected) {
    return {
      url: `${baseUrl}delete/`,
      method: 'POST',
      body: { selected },
    };
  },
})


export const batchUpdate = (builder, baseUrl) => builder.mutation({
  query(selected) {
    return {
      url: `${baseUrl}update/`,
      method: 'POST',
      body: { selected },
    };
  },
})
