import { api, getList, getDetail, update } from 'store/api/api'


const baseApiUrl = 'card/upload/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/card/upload/${detail[pkField]}/`
}


const CardUploadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCardUploadList: getList(builder, baseApiUrl, 'CardUploadList'),
    getCardUploadSimpleList: getList(builder, baseApiUrl, 'CardUploadSimpleList', { simple: true }),

    getCardUploadDetail: getDetail(builder, baseApiUrl, 'CardUploadDetail'),
    getCardUploadFormDetail: getDetail(builder, baseApiUrl, 'CardUploadFormDetail', { forEdit: true }),

    //updateCardUpload: update(builder, baseApiUrl, pkField),

    updateCardUpload: builder.mutation({
      query(formData) {
        return {
          url: 'card/upload/',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
    }),
  }),
});


export default CardUploadApi;
