import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { ModalForm } from 'components/common'
import HallAccessApi, { pkField, getDetailUrl } from 'models/HallAccess/api'
import { HallAccessActions } from 'models/HallAccess/slice'

import MainFieldSet from './MainFieldSet'


const HallAccessForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.hallAccess);

  const [apiGetFormDetail, apiGetFormDetailProps] = HallAccessApi.endpoints.getHallAccessFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = HallAccessApi.endpoints.updateHallAccess.useMutation();

  const dispatch = useDispatch();

  //const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    const data = {
      id: values.id,
      status: values.status,
      statusComment: values.statusComment,
      eventStart: values.eventStart,
      eventEnd: values.eventEnd,
    }
    console.log(data);
    apiUpdate(data); 
  };

  return (
    <ModalForm
      name="HallAccess"
      title={formDetailPK ? `Доступ №${formDetailPK}`: 'Новый доступ'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={HallAccessApi}

      onFinish={onFinish}
      onClose={() => dispatch(HallAccessActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return { 
          ...detail, 
          eventStart: dayjs(detail.eventStart),
          eventEnd: dayjs(detail.eventEnd)
         }
      }}
    >
      <MainFieldSet initials={initials} />
    </ModalForm>
  );
}


export default HallAccessForm
