import { FormFields } from 'components/common'

const { ImageUploadField } = FormFields;


const GalleryFieldset = ({ detailForEdit }) => {
  return (
    <ImageUploadField label="Галерея" name="images" data={{ tags: ['tenants'] }} columns={4} required={false} />
  );
};

export default GalleryFieldset;
