import { useSelector, useDispatch } from 'react-redux'
import { Tabs } from 'antd'
import dayjs from 'dayjs'

import { ModalForm } from 'components/common'
import EventApi, { pkField, getDetailUrl } from 'models/Event/api'
import { EventActions } from 'models/Event/slice'

import MainFieldset from './MainFieldset'
import GalleryFieldset from './GalleryFieldset'


const EventForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.event);

  const [apiGetFormDetail, apiGetFormDetailProps] = EventApi.endpoints.getEventFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = EventApi.endpoints.updateEvent.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const tabs = [
    { key: 'detail', label: 'Основное', forceRender: true, children: <MainFieldset /> },
    { key: 'gallery', label: 'Галерея', forceRender: true, children: <GalleryFieldset /> },
  ]

  return (
    <ModalForm
      name="Event"
      withTabs={true}
      title={formDetailPK ? `Событие «${formDetail?.name}»`: 'Новое событие'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={EventApi}

      onFinish={onFinish}
      onClose={() => dispatch(EventActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return { ...detail, start: dayjs(detail.start), end: dayjs(detail.end) }
      }}
    >
      <Tabs className="tabsForm" type="card" items={tabs} />
    </ModalForm>
  );
}


export default EventForm
