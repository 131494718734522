import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import HallAccessApi from 'models/HallAccess/api'
import { HallAccessActions } from 'models/HallAccess/slice'

import { DetailPage } from 'layout'
import { HallAccessForm, HallAccessDetail } from '../../components'


const HallAccessDetailPage = () => {
  const { accessPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = HallAccessApi.endpoints.getHallAccessDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = HallAccessApi.endpoints.deleteHallAccess.useMutation();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Доступ №${accessPK}`}
      detailPK={accessPK}
      detail={detail}

      basePath='/hall-access/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(HallAccessActions.openForm({ name: 'editForm', pk: accessPK }))}
    >
      <HallAccessDetail detail={detail} />

      <HallAccessForm mode="detail" />
    </DetailPage>
  );
}

export default HallAccessDetailPage
