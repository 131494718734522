import { useSelector, useDispatch } from 'react-redux'

import { ModalForm } from 'components/common'
import CardTypeApi, { pkField, getDetailUrl } from 'models/CardType/api'
import { CardTypeActions } from 'models/CardType/slice'
import MainFieldset from './MainFieldset'


const CardTypeForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.cardType);

  const [apiGetFormDetail, apiGetFormDetailProps] = CardTypeApi.endpoints.getCardTypeFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CardTypeApi.endpoints.updateCardType.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  return (
    <ModalForm
      name="CardType"
      withTabs={false}
      title={formDetailPK ? `Тип карты «${formDetail?.name}»`: 'Новый тип карт'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={CardTypeApi}

      onFinish={onFinish}
      onClose={() => dispatch(CardTypeActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
        }
      }}
    >
      <MainFieldset initials={initials} />
    </ModalForm>
  );
}


export default CardTypeForm
