import { ExclamationCircleOutlined, FieldTimeOutlined, CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons"

import { Status } from 'components/common'


const STATUS_LIST = [
  ['new', 'Новая'],
  ['inProgress', 'В обработке'],
  ['completed', 'Подтверждена'],
  ['declined', 'Отклонена'],
]

const STATUS = STATUS_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {});

const ICONS = {
  'new': <ExclamationCircleOutlined />,
  'inProgress': <FieldTimeOutlined />,
  'completed': <CheckCircleOutlined />,
  'declined': <CloseCircleOutlined />,
}


const HallRequestStatus = ({ detail, style }) => {
  return (
    <Status status={detail?.status} statuses={STATUS} icons={ICONS} style={style} />
  );
};

HallRequestStatus.STATUS_LIST = STATUS_LIST;
HallRequestStatus.STATUS = STATUS;


export default HallRequestStatus
