import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { TextField, NumberField, SwitchField, ImageUploadField, HtmlField, TextareaField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <Row gutter={32} style={{ marginBottom: '24px' }}>
          <Col flex="120px">
            <ImageUploadField
              label="Иконка" name="icon" data={{ tags: ['hall'] }} required={false}
              title="Загрузить иконку" single={true}
            />
          </Col>
          <Col flex="auto">
            <TextField label="Название" name="name" required={true} />

            <TextField label="Slug" name="slug" required={true} />
          </Col>
        </Row>

        <TextField label="Вместимость" name="capacity" required={false} />

        <TextField label="Оборудование" name="equipment" required={false} />

        <TextareaField label="Тизер" name="teaser" required={false} />

        <HtmlField label="Текст" name="text" tags={['hall']} required={false} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
