import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'
import { ClientActions } from 'models/Client/slice'
import { ClientList, ClientForm } from 'models/Client/components'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const ClientListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(ClientActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Клиенты"
      headerMiddle={<SmartFilter queryKey="client" onChange={onFilterChange} fields={filterFields} />}

      mainActions={[
        { name: 'add', action: (detail) => onEdit() },
      ]}

      basePath="/client/"
    >
      <SmartFilterValues queryKey="client" onChange={onFilterChange} fields={filterFields} />

      <ClientList params={params} />

      <ClientForm mode="list" />
    </Page>
  )
}

export default ClientListPage
