import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { Fieldset, Descriptions, DateTimeValue, OnOff, ImageGallery } from 'components/common'
import EventApi, { pkField } from 'models/Event/api'


const EventDetail = ({ detail }) => {
  const [apiUpdate, apiUpdateProps] = EventApi.endpoints.updateEvent.useMutation();
  const [apiGetDetail] = EventApi.endpoints.getEventDetail.useLazyQuery();

  const pk = detail[pkField];

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line
  }, [pk, apiUpdateProps.isSuccess]);

  return (
    <>
      <Fieldset>
        <Descriptions>
          <Descriptions.Item span={6} label="Активно">
            <OnOff name="Event" detail={detail} pkField={pkField} fieldName="isActive" apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />
          </Descriptions.Item>

          <Descriptions.Item span={6} label="Дата создания">
            <DateTimeValue date={detail?.date_created} />
          </Descriptions.Item>
          <Descriptions.Item span={6} label="Дата изменения">
            <DateTimeValue date={detail?.date_changed} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Row gutter={16}>
        <Col span={16}>
          <Fieldset title="Основное">
            <Descriptions>
              <Descriptions.Item label="Зал">{detail?.hall?.name}</Descriptions.Item>

              <Descriptions.Item label="Название" span={21}>{detail?.name}</Descriptions.Item>

              <Descriptions.Item span={12} label="Дата начала">
                <DateTimeValue date={detail?.start} />
              </Descriptions.Item>

              <Descriptions.Item span={12} label="Дата завершения">
                <DateTimeValue date={detail?.end} />
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>

          <Fieldset title="Текст">
            <Descriptions>
              <Descriptions.Item label="">
                <div style={{ overflowX: 'scroll', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: detail?.text }} />
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>

        <Col span={8}>
          <Fieldset title="Галерея">
            <ImageGallery images={detail?.images} />
          </Fieldset>
        </Col>
      </Row>
    </>
  );
}

export default EventDetail
