import { Link } from "react-router-dom"
import { ArrowLeftOutlined } from '@ant-design/icons'

import PageHeaderActions from './PageHeaderActions'
import PageHeaderTabs from './PageHeaderTabs'

import './styles.css'


const PageHeader = ({
  title,
  info,
  showBack,
  headerMiddle,
  basePath,
  tabs,
  onTabChange,
  activeTab,
  mainActions,
  otherActions,
  headerEnd,
}) => {
  if (!title && !mainActions && !otherActions && !tabs) return null;
  return (
    <header className="pageHeader">
      <div className="pageHeaderMain">
        <div className="pageHeader__start">
          {showBack &&
            <Link
              className="backBtn"
              to={-1}
              replace={true}
            >
              <ArrowLeftOutlined />
            </Link>}
          {title && <div className="pageHeaderTitle">{title}</div>}
          {info && <div className="pageHeaderInfo">{info}</div>}
        </div>

        <div className="pageHeader__middle">
          {headerMiddle}
        </div>

        <div className="pageHeader__end">
          {headerEnd}
          <PageHeaderActions mainActions={mainActions} otherActions={otherActions}/>
        </div>
      </div>

      {tabs && <PageHeaderTabs basePath={basePath} tabs={tabs} onTabChange={onTabChange} activeTab={activeTab} />}
    </header>
  );
}

export default PageHeader;
