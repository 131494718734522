import { useSelector, useDispatch } from 'react-redux'

import { ModalForm } from 'components/common'
import CardRequestApi, { pkField, getDetailUrl } from 'models/CardRequest/api'
import { CardRequestActions } from 'models/CardRequest/slice'
import MainFieldSet from './MainFieldSet'


const CardRequestStatusForm = ({ mode = "list" }) => {
  const { activeForm, formDetailPK, formInitials } = useSelector(state => state.cardRequest);

  const [apiGetFormDetail, apiGetFormDetailProps] = CardRequestApi.endpoints.getCardRequestFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CardRequestApi.endpoints.updateCardRequest.useMutation();

  const dispatch = useDispatch();

  //const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    const data = {
      id: values.id,
      status: values.status,
      message: values.message,
    }
    console.log(data);
    apiUpdate(data); 
  };

  return (
    <ModalForm
      name="CardRequest"
      title={formDetailPK ? `Заявка №${formDetailPK}`: 'Новая заявка'}
      width={700}
      mode={mode}
      visible={activeForm === 'statusForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={CardRequestApi}

      onFinish={onFinish}
      onClose={() => dispatch(CardRequestActions.closeForm())}
      initialValues={{
        ...formInitials
      }}

      prepareFormValues={(detail) => {
        return { 
          id: detail.id,
          status: formInitials?.status || detail.status,
         }
      }}
    >
      <MainFieldSet initials={formInitials} />
    </ModalForm>
  );
}


export default CardRequestStatusForm
