import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'layout'
import HallApi from 'models/Hall/api'
import { HallActions } from 'models/Hall/slice'
import { HallForm, HallDetail } from 'models/Hall/components'


const HallDetailPage = () => {
  const { hallPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = HallApi.endpoints.getHallDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = HallApi.endpoints.deleteHall.useMutation();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Зал «${detail?.name}»`}
      detailPK={hallPK}
      detail={detail}

      basePath='/hall/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(HallActions.openForm({ name: 'editForm', pk: hallPK }))}
    >
      <HallDetail detail={detail} />

      <HallForm mode="detail" />
    </DetailPage>
  );
}

export default HallDetailPage
