import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'

import { CardTypeActions } from 'models/CardType/slice'
import { CardTypeList, CardTypeForm } from 'models/CardType/components'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const CardTypeListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(CardTypeActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Типы карт"
      headerMiddle={<SmartFilter queryKey="cardType" onChange={onFilterChange} fields={filterFields} />}

      mainActions={[
        { name: 'add', action: (detail) => onEdit() },
      ]}

      basePath="/card/"
      tabs={[
        { label: 'Карты', key: '' },
        { label: 'Типы карт', key: 'type/' },
      ]}
    >
      <SmartFilterValues queryKey="cardType" onChange={onFilterChange} fields={filterFields} />

      <CardTypeList params={params} />

      <CardTypeForm mode="list" />
    </Page>
  )
}

export default CardTypeListPage
