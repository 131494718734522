import { Row, Col } from 'antd'

import { FormFields, Fieldset } from 'components/common'

const { FileUploadField, NumberField } = FormFields;


const MainFieldset = () => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <FileUploadField
          label="Файл dbf"
          name="dbf_file"
          required={true}
          title="Выбрать файл"
          accept=".dbf"
          mode="single"
          beforeUpload={(file) => {
            return false;
          }}
        />

       <Fieldset title="Электронные карты:">
          <Row gutter={32}>
            <Col span={10}>
              <NumberField label="Первый номер" name="virtualNumberStart" required={false} />
            </Col>
            <Col span={12}>
              <NumberField label="Последний номер" name="virtualNumberEnd" required={false} />
            </Col>
          </Row>
        </Fieldset>
      </Col>
    </Row>
  );
};

export default MainFieldset;
