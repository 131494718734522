import './styles.css'


const Status = ({ style, status, statuses = {}, icons = {} }) => {
  const icon = icons[status];

  return (
    <div className={`status ${status}`} style={style}>{icon} {statuses[status] || status}</div>
  );
};


export default Status
