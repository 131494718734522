import { FormFields } from 'components/common'
import HallAccessStatus from '../HallAccessStatus'

const { SelectField } = FormFields;


const HallAccessStatusField = ({ name, label, required, disabled }) => {
  return (
    <SelectField
      label={label}
      name={name}
      required={true}
      disabled={disabled}
      rules={[{ required: required }]}
      options={
        HallAccessStatus.STATUS_LIST.map(item => ({ value: item[0], label: item[1] }))
      }
    />
  );
}

HallAccessStatusField.getValue = (values) => {
  return values;
}

HallAccessStatusField.getDisplayValue = (values, config) => {
  return values
};

export default HallAccessStatusField
