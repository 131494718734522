import { useState, useEffect } from 'react'

import './styles.css'


const SchemePlaceInfo = ({ width, height, place, hall, tenant, scale, updatePlace }) => {
  const [position, setPosition] = useState(place.schemeInfoPosition || [0, 0]);

  const item = hall || tenant || place;

  const placeId = place.id;

  const [moveMode, setMoveMode] = useState(false);
  const [moveStartPosition, setMoveStartPosition] = useState(false);

  useEffect(() => {
    if (!moveMode) return;

    function getXY(e) {
      const dx = e.pageX - moveStartPosition[0];
      const dy = e.pageY - moveStartPosition[1];
      const x = Math.round(position[0] + dx / scale);
      const y = Math.round(position[1] + dy / scale);
      return [x, y]
    }

    const move = (e) => {
      const pos = getXY(e);
      setPosition(pos);
    }

    const endMove = (e) => {
      setMoveMode(false);
      const pos = getXY(e);
      setPosition(pos);
      updatePlace && updatePlace({ id: placeId, schemeInfoPosition: pos })
    }

    window.addEventListener("mousemove", move);
    window.addEventListener("mouseup", endMove);
    return () => {
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", endMove);
    }
    // eslint-disable-next-line
  }, [moveMode]);

  const onStartMoveInfo = (e) => {
    setMoveMode(true);
    setMoveStartPosition([e.pageX, e.pageY])
  }

  const maxWidth = place.schemeInfoRotate === 0 ? width : height;

  let title2 = '';
  if (place.status === 'freeToRent' || place.status === 'free') {
    title2 = 'Сдаётся в аренду';
  } else if (place.status === 'freeToBuy') {
    title2 = 'Продаётся';
  }

  const innerStyle = {
    transform: `translate(${position[0]}px, ${position[1]}px) rotate(-${place.schemeInfoRotate}deg)`, 
    maxWidth: `${maxWidth}px`, 
    width: `${maxWidth}px`
  };

  return (
    <div className="schemePlaceInfo">
      <div
        className="schemePlaceInfo__inner"
        style={innerStyle}
        onMouseDown={onStartMoveInfo}
      >
        {place?.schemeShowIcon && item.icon && item.icon.length > 0 ?
          <div className="schemePlaceInfo__logo" style={{ width: `${place.schemeIconWidth}px` }}>
            <img src={item.icon[0].url} alt="" />
          </div>
          :
          <div className="schemePlaceInfo__title" style={{ width: `${place.schemeTitleWidth}px`, fontSize: `${place.schemeTitleFontSize}px` }}>
            <div>{item.number || item.name}</div>
            {title2 && <div className="schemePlaceInfo__titleSub">{title2}</div>}
          </div>
        }
      </div>
    </div>
  )
}

export default SchemePlaceInfo
