import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'news/post/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/news/${detail[pkField]}/`
}


const NewsPostApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNewsPostList: getList(builder, baseApiUrl, 'NewsPostList'),
    getNewsPostSimpleList: getList(builder, baseApiUrl, 'NewsPostSimpleList', { simple: true }),

    getNewsPostDetail: getDetail(builder, baseApiUrl, 'NewsPostDetail'),
    getNewsPostFormDetail: getDetail(builder, baseApiUrl, 'NewsPostFormDetail', { forEdit: true }),

    updateNewsPost: update(builder, baseApiUrl, pkField),

    deleteNewsPost: remove(builder, baseApiUrl),
    batchDeleteNewsPost: batchRemove(builder, baseApiUrl),
  }),
});


export default NewsPostApi;
