import { Select } from 'antd'

import { ListLoadComponent } from 'components/common'
import BuildingApi from 'models/Building/api'



const BuildingSelect = ({ filter, disabled, required, ...props }) => {
  const [getObjectList, getObjectListProps] = BuildingApi.endpoints.getBuildingSimpleList.useLazyQuery();

  return (
    <ListLoadComponent
      getObjectList={getObjectList}
      getObjectListProps={getObjectListProps}
      filter={filter}

      render={({ items }) => {
        return (
          <Select
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Выберите"
            showSearch={true}
            allowClear={true}
            disabled={disabled}
            optionFilterProp="label"
            size="large"
            onChange={props.onChange}
            {...props}
          >
            {!required && <Select.Option value={''}>---</Select.Option>}
            {items.map(item => {
              return (
                <Select.Option key={`category${item.id}`} value={item.id} label={item.name}>
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
        );
      }}
    />
  );
}

export default BuildingSelect
