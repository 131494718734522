import { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Spin } from 'antd'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'
import { updateDataQueryString } from 'utils/urls'

import ParkingCardApi from 'models/ParkingCard/api'
import { ParkingCardList, ParkingCardForm } from 'models/ParkingCard/components'

import { RPSCompanySelectField } from 'models/RPSCompany/components'


const STATUS_TABS = [
  { getLabel: (count) => `Все физлица ${count}`, key: `all` },
  { getLabel: (count) => `Через неделю ${count}`, key: `week` },
  { getLabel: (count) => `Через 3 дня ${count}`, key: `3day` },
  { getLabel: (count) => `Сегодня ${count}`, key: `today` },
  { getLabel: (count) => `Просроченные ${count}`, key: `expired` },
  { getLabel: (count) => `В архиве ${count}`, key: `archived` },
  { getLabel: (count) => `Юрлица ${count}`, key: `company` },
]


const ParkingCardListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [params, setParams] = useState(null);

  const [apiSync, apiSyncProps] = ParkingCardApi.endpoints.syncParkingCard.useLazyQuery();

  const onSync = () => {
    apiSync();
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  const { status } = useParams();

  const [apiGetStatuses, apiGetStatusesProps] = ParkingCardApi.endpoints.getParkingCardStatuses.useLazyQuery();

  useEffect(() => {
    apiGetStatuses();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!status) {
      const key = STATUS_TABS[0].key;
      navigate({ pathname: `/parking-card/status/${key}/` }, { replace: true });
    }
    // eslint-disable-next-line
  }, [status]);

  const onTabChange = (key) => {
    console.log('location', location);
    const newSearchString = updateDataQueryString(location.search, 'parkingCard', { offset: '' });
    console.log('newSearchString', newSearchString);

    navigate({
      pathname: `/parking-card/status/${key}/`,
      search: newSearchString ? `?${newSearchString}` : ''
    },
      { replace: true }
    );
  }

  const statuses = apiGetStatusesProps.data?.statuses || {};
  const tabs = STATUS_TABS.map(item => ({ label: item.getLabel(statuses[item.key] || ''), key: item.key }));

  const companies = apiGetStatusesProps.data?.companies || [];
  const companyOptions = companies.map(item => ({ value: item.Id, label: item.CompanyName }));

  const filterFields = [
    SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
    SmartFilter.getFieldConfig('phoneExist', 'Телефон заполнен', SmartFilter.FilterBool),
    SmartFilter.getFieldConfig('rpsCompany', 'Компания', RPSCompanySelectField, { options: companyOptions }),
  ];

  return (
    <Page
      title="Парковочные карты"

      headerMiddle={<SmartFilter queryKey="parkingCard" onChange={onFilterChange} fields={filterFields} />}

      mainActions={[
        { name: 'sync', label: 'Синхронизировать', action: (detail) => onSync(), loading: apiSyncProps.isFetching },
      ]}

      basePath="/parking-card/status/"
      tabs={tabs}
      onTabChange={onTabChange}
    >
      <Spin spinning={apiSyncProps.isFetching}>
        <SmartFilterValues queryKey="parkingCard" onChange={onFilterChange} fields={filterFields} />

        {status && <ParkingCardList params={params} filterParams={{ status }} />}

        <ParkingCardForm mode="list" />
      </Spin>
    </Page>
  )
}

export default ParkingCardListPage
