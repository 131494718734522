import { Form, Row, Col } from 'antd'
import slugify from '@sindresorhus/slugify';

import { FormFields } from 'components/common'

const { TextField, SwitchField, DateField, ImageUploadField, HtmlField } = FormFields;


const MainFieldSet = () => {
  const form = Form.useFormInstance();

  const onTitleChange = (e) => {
    console.log('e', e.target.value);
    const slug = slugify(e.target.value).substring(0, 200);
    form.setFieldValue('slug', slug);
  }

  return (
    <Row gutter={32}>
      <Col span={16}>
        <TextField label="Заголовок" name="title" required={true} onChange={onTitleChange} />

        <TextField label="Slug" name="slug" required={true} />

        <HtmlField label="Текст" name="text" tags={['news']} required={false} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <DateField label="Дата публикации" name="date" required={true} format='DD.MM.YYYY' showTime={false} />

        <ImageUploadField label="Картинки" name="images" data={{ tags: ['news'] }} required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldSet;
