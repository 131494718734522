import { Form, Input } from 'antd'

import "./styles.css"


const TextField = ({ name, label, required, disabled, ...otherProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <Input size="large" disabled={disabled} {...otherProps} />
    </Form.Item>
  );
};

export default TextField;
