import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'tenants/service/';

export const pkField = 'slug';

export const getDetailUrl = (detail) => {
  return `/tenant/service/${detail[pkField]}/`
}


const TenantServiceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTenantServiceList: getList(builder, baseApiUrl, 'TenantServiceList'),
    getTenantServiceSimpleList: getList(builder, baseApiUrl, 'TenantServiceSimpleList', { simple: true }),

    getTenantServiceDetail: getDetail(builder, baseApiUrl, 'TenantServiceDetail'),
    getTenantServiceFormDetail: getDetail(builder, baseApiUrl, 'TenantServiceFormDetail', { forEdit: true }),

    updateTenantService: update(builder, baseApiUrl, pkField),

    deleteTenantService: remove(builder, baseApiUrl),
    batchDeleteTenantService: batchRemove(builder, baseApiUrl),
  }),
});


export default TenantServiceApi;
