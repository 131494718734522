import { PlusOutlined } from '@ant-design/icons'

import { Page } from 'layout'
// import { EventList, EventForm } from '../../components'


const EventListPage = () => {
  //const ref = useRef(null);
  //const [editFormState, setEditFormState] = useState({});

  const onEdit = (objectId = null) => {
    //setEditFormState({ visible: true, objectId });
  };

  return (
    <Page
      title="Поддержка"
      actions={[
        { mode: 'button', name: 'edit', icon: <PlusOutlined />, label: 'Добавить', action: (detail) => onEdit() },
      ]}
    >
      
    </Page>
  )
}

export default EventListPage
