import { Form } from 'antd'

import { ImageUpload } from 'components/common'

import "./styles.css"


const ImageUploadField = ({ name, label, required, single, data, ...otherProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]} valuePropName="fileList">
      <ImageUpload
        data={data}
        mode={single ? "single" : "multiple"}
        {...otherProps}
      />
    </Form.Item>
  );
};

export default ImageUploadField;
