import { Row, Col } from 'antd'

import { Fieldset, Descriptions, DateTimeValue, Image } from 'components/common'
import { ClientValue } from 'models/Client/components'
import { CardTypeValue } from 'models/CardType/components'
import { Card } from 'models/Card/components'
import CardRequestStatus from '../CardRequestStatus'
// import CardRequestMessageList from '../CardRequestMessageList'


const CardRequestDetail = ({ detail }) => {
  const card = detail?.findedCard;

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset title="Основное">
          <Descriptions>
            <Descriptions.Item label="Клиент" span={10}>
              <ClientValue detail={detail?.client} showPhone={true} asLink={true} isBack={true} />
            </Descriptions.Item>

            <Descriptions.Item label="Номер карты" span={7}>
              {detail?.number}
            </Descriptions.Item>

            <Descriptions.Item label="Номер телефона" span={7}>
              {detail?.clientInfo?.phone}
            </Descriptions.Item>

            <Descriptions.Item label="Фотография карты" span={8}>
              <Image src={detail?.image} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset title="Найденная карта">
          {card ?
            <>
              <Card card={card} />

              <Descriptions>
                <Descriptions.Item span={24} label="Тип карты">
                  <CardTypeValue detail={card?.cardType} asLink={true} isBack={true} />
                </Descriptions.Item>

                <Descriptions.Item span={24} label="Клиент">
                  {card?.client && <ClientValue detail={card?.client} asLink={true} isBack={true} />}
                </Descriptions.Item>

                <Descriptions.Item span={24} label="ФИО">{card?.first_name} {detail?.last_name}</Descriptions.Item>

                <Descriptions.Item span={24} label="Телефон">{card?.phone}</Descriptions.Item>

                <Descriptions.Item span={24} label="Email">{card?.email}</Descriptions.Item>
              </Descriptions>
            </>
            :
            `Карта с номером «${detail?.number}» не найдена`
          }
        </Fieldset>
      </Col>
    </Row>
  );
}

/*
<Col span={24}>
        <Fieldset title="Сообщения">
          <CardRequestMessageList messages={detail?.messages} />
        </Fieldset>
      </Col>
*/

export default CardRequestDetail
