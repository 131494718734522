import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'

import CardRequestApi from 'models/CardRequest/api'
import { CardRequestList } from 'models/CardRequest/components'


const filterFields = [
  SmartFilter.getFieldConfig('dateCreated', 'Дата', SmartFilter.FilterDateRange),
];


const STATUS_TABS = [
  { getLabel: (count) => `Новые ${count}`, key: `new` },
  { getLabel: (count) => `В обработке ${count}`, key: `inProgress` },
  { getLabel: (count) => `Подтвержденные ${count}`, key: `completed` },
  { getLabel: (count) => `Отклоненные ${count}`, key: `declined` },
]


const CardRequestListPage = () => {
  const navigate = useNavigate();

  const [params, setParams] = useState(null);

  const onFilterChange = (params) => {
    setParams(params)
  }

  const { status } = useParams();

  const [apiGetStatuses, apiGetStatusesProps] = CardRequestApi.endpoints.getCardRequestStatuses.useLazyQuery();

  useEffect(() => {
    apiGetStatuses();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!status) {
      const key = STATUS_TABS[0].key;
      navigate({ pathname: `/card-request/status/${key}/` }, { replace: true });
    }
    // eslint-disable-next-line
  }, [status]);

  const statuses = apiGetStatusesProps.data || {};
  const tabs = STATUS_TABS.map(item => ({ label: item.getLabel(statuses[item.key] || ''), key: item.key }));

  return (
    <Page
      title="Заявки на клубную карту"
      headerMiddle={<SmartFilter queryKey="cardRequest" onChange={onFilterChange} fields={filterFields} />}

      basePath="/card-request/status/"
      tabs={tabs}
    >
      <SmartFilterValues queryKey="cardRequest" onChange={onFilterChange} fields={filterFields} />

      {status && <CardRequestList params={params} filterParams={{ status }} />}
    </Page>
  )
}

export default CardRequestListPage
