import { DetailValue, Image } from 'components/common'
import { getDetailUrl } from 'models/TenantCategory/api'


const TenantCategoryValue = ({ detail, asLink, isBack }) => {
  return (
    <DetailValue
      url={asLink && getDetailUrl(detail)}
      isBack={isBack}
      className="flex"
      render={() => (
        <>
          <Image src={detail?.icon[0]?.url} width={30} height={30} />
          {detail?.name || '-'}
        </>
      )}
    />
  );
};


export default TenantCategoryValue
