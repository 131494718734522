const ENV = {
  local: {
    apiUrl: 'http://localhost:8000',
    wsUrl: 'ws://localhost:8000/ws/',
  },
  production: {
    apiUrl: 'https://office-api.territory3000.ru',
    wsUrl: 'wss://office-ws.territory3000.ru/ws/',
  },
};

const urls = ENV['production'];

export const API_URL = urls.apiUrl;
export const WS_URL = urls.wsUrl;
