import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'

import { HallActions } from 'models/Hall/slice'
import { HallList, HallForm } from 'models/Hall/components'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const HallListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(HallActions.openForm({ name: 'editForm' }))
  };
 
  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Залы"
      mainActions={[
        { name: 'add', action: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="hall" onChange={onFilterChange} fields={filterFields} />}
    >
      <SmartFilterValues queryKey="hall" onChange={onFilterChange} fields={filterFields} />

      <HallList params={params} />

      <HallForm mode="list" />
    </Page>
  )
}

export default HallListPage
