import { useEffect } from 'react'

import SchemePlaceHandle from './SchemePlaceHandle'

import './styles.css'


const SchemePlacePointEdit = ({ placeId, parentEl, pointEditMode, points, setPoints, schemePosition, scale, position, setPosition, updatePlace }) => {
  
  useEffect(() => {
    if (!pointEditMode) return

    const addPoint = (e) => {
      console.log('addPoint');
      console.log(e.target);
      if (e.target.classList.contains('schemePlace__handle')) {
        const idx = parseInt(e.target.dataset.idx);

        let _newPoints = [...points];
        _newPoints.splice(idx, 1);

        setPoints(_newPoints);
        updatePlace && updatePlace({ id: placeId, schemePoints: _newPoints });

      } else {
        const offset = parentEl.current.getBoundingClientRect()
        let x = Math.round((e.pageX - offset.x) / scale - position[0]);
        let y = Math.round((e.pageY - offset.y) / scale - position[1]);
        const lengths = points.map(point => {
          return Math.abs(x - point[0]) + Math.abs(y - point[1]);
        });
        const index = lengths.indexOf(Math.min(...lengths));

        let _newPoints = [...points];
        _newPoints.splice(index + 1, 0, [x, y]);
        const [newPosition, newPoints] = normalizePoints(_newPoints);
        setPoints(newPoints);
        setPosition(newPosition);
        updatePlace && updatePlace({ id: placeId, schemePosition: newPosition, schemePoints: newPoints });
      }
    }

    window.addEventListener("dblclick", addPoint);
    return () => window.removeEventListener("dblclick", addPoint);
    // eslint-disable-next-line
  }, [pointEditMode, points, position]);

  const normalizePoints = (_points) => {
    const minX = Math.min.apply(Math, _points.map(o => o[0]));
    const minY = Math.min.apply(Math, _points.map(o => o[1]));

    let dx = 0;
    let dy = 0;

    let newPoints = [..._points];

    if (minX !== 0 || minY !== 0) {
      newPoints = newPoints.map(point => {
        let [x, y] = point;
        x -= minX;
        y -= minY;
        return [x, y];
      });
      dx -= minX;
      dy -= minY;
    }

    const newPosition = [position[0] - dx, position[1] - dy];
    return [newPosition, newPoints];
  }

  const getNewPoints = (index, pointPos) => {
    return points.map((point, idx) => {
      if (idx === index) {
        return pointPos;
      }
      return point;
    });
  };

  const movePoint = (index, pointPos) => {
    const _newPoints = getNewPoints(index, pointPos);
    const [newPosition, newPoints] = normalizePoints(_newPoints);
    setPoints(newPoints);
    setPosition(newPosition);
  };

  const updatePoint = (index, pointPos) => {
    const _newPoints = getNewPoints(index, pointPos);
    const [newPosition, newPoints] = normalizePoints(_newPoints);
    setPoints(newPoints);
    setPosition(newPosition);
    updatePlace && updatePlace({ id: placeId, schemePosition: newPosition, schemePoints: newPoints });
  };

  if (!pointEditMode) return null;
  return (
    <>
      {points.map((point, index) => (
        <SchemePlaceHandle
          key={`hdl${index}`}
          index={index}
          schemePosition={schemePosition}
          point={point}
          scale={scale}
          movePoint={movePoint}
          updatePoint={updatePoint}
        />
      ))}
    </>
  );
}

export default SchemePlacePointEdit
