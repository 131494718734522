import { DetailValue } from 'components/common'
import { getDetailUrl } from 'models/BuildingLevel/api'


const BuildingLevelValue = ({ detail, asLink, isBack }) => {
  return (
    <DetailValue
      url={asLink && getDetailUrl(detail)}
      isBack={isBack}
      className="flex"
      render={() => (
        <>
          {detail?.name || '-'}
        </>
      )}
    />
  );
};


export default BuildingLevelValue
