import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { Fieldset, Descriptions, DateTimeValue, OnOff, Image } from 'components/common'
import TenantCategoryApi, { pkField } from 'models/TenantCategory/api'


const TenantCategoryDetail = ({ detail }) => {
  const [apiUpdate, apiUpdateProps] = TenantCategoryApi.endpoints.updateTenantCategory.useMutation();
  const [apiGetDetail] = TenantCategoryApi.endpoints.getTenantCategoryDetail.useLazyQuery();

  const pk = detail[pkField];

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line
  }, [pk, apiUpdateProps.isSuccess]);

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset title="Основное">
          <Descriptions>
            <Descriptions.Item className="pad0" span={3}>
              <Image src={detail?.icon[0]?.url} width={32} height={32} />
            </Descriptions.Item>

            <Descriptions.Item label="Название" span={21}>{detail?.name}</Descriptions.Item>

            <Descriptions.Item label="Slug">{detail?.slug}</Descriptions.Item>


          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1}>
            <Descriptions.Item label="Активно">
              <OnOff detail={detail} pkField={pkField} fieldName="isActive" name="TenantCategory" apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />
            </Descriptions.Item>
            <Descriptions.Item label="Сортировка">{detail?.sort}</Descriptions.Item>
            <Descriptions.Item label="Дата создания">
              <DateTimeValue date={detail?.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения">
              <DateTimeValue date={detail?.date_changed} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
}

export default TenantCategoryDetail
