import { Row, Col } from 'antd'
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined } from '@ant-design/icons'

import { FormFields } from 'components/common'

const {  NumberField, SwitchField, RadioField } = FormFields;


const DecorationFieldset = ({ initials }) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <Row gutter={32} style={{ marginBottom: '16px' }}>
          <Col>
            <SwitchField label="Показывать иконку" name="schemeShowIcon" />
          </Col>
          <Col>
            <NumberField label="Ширина иконки" name="schemeIconWidth" required={true} />
          </Col>
        </Row>

        <Row gutter={32} style={{ marginBottom: '16px' }}>
          <Col>
            <NumberField label="Ширина заголовка" name="schemeTitleWidth" required={true} />
          </Col>
          <Col>
            <NumberField label="Размер заголовка" name="schemeTitleFontSize" required={true} />
          </Col>
          <Col>
            <NumberField label="Угол заголовка" name="schemeInfoRotate" required={true} />
          </Col>
        </Row>

        <RadioField
          label="Направление входа"
          name="schemeEntranceDirection"
          required={true}
          optionType="button"
          options={[
            { value: '-', label: '-' },
            { value: 'down', label: <ArrowDownOutlined /> },
            { value: 'left', label: <ArrowLeftOutlined /> },
            { value: 'up', label: <ArrowUpOutlined /> },
            { value: 'right', label: <ArrowRightOutlined /> },
          ]}
        />
      </Col>
    </Row>
  );
};

export default DecorationFieldset;
