import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { getFullNameOrEmail } from 'utils/helpers'
import { DetailPage } from 'layout'
import ClientApi from 'models/Client/api'
import { ClientActions } from 'models/Client/slice'
import { ClientForm, ClientDetail } from 'models/Client/components'


const ClientDetailPage = () => {
  const { clientPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = ClientApi.endpoints.getClientDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = ClientApi.endpoints.deleteClient.useMutation();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Клиент «${getFullNameOrEmail(detail)}»`}
      detailPK={clientPK}
      detail={detail}

      basePath='/client/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(ClientActions.openForm({ name: 'editForm', pk: clientPK }))}
    >
      <ClientDetail detail={detail} />

      <ClientForm mode="detail" />

    </DetailPage>
  );
}

export default ClientDetailPage
