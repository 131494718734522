import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from './api'


const initialState = {
  ...baseModelState,
}


export const hallRequest = createSlice({
  name: 'hallRequest',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateHallRequest);
  },
});

export default hallRequest.reducer;

export const HallRequestActions = hallRequest.actions;
