import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import HallRequestStatusField from '../../HallRequestStatusField'

const { TextareaField, TextField, NumberField, DateField } = FormFields;


const MainFieldSet = ({ detailForEdit, initials, status }) => {
  const isForCompleted = status === 'completed';

  return (
    <Row gutter={32}>
      <Col span={24}>
        <HallRequestStatusField label="Статус" name="status" disabled={!!initials?.status} />

        {isForCompleted && <TextField label="Название события" name="eventName" required={true} disabled={false} />}

        {isForCompleted &&
          <Row gutter={32} style={{ marginBottom: '12px' }}>
            <Col span={12}>
              <DateField label="Дата проведения" name="eventStart" required={true} disabled={false} />
            </Col>
            <Col span={12}>
              <NumberField label="Длительность" name="eventDuration" required={true} disabled={false} />
            </Col>
          </Row>}

        <TextareaField label="Комментарий администратора" name="message" rows={4} required={false} />
      </Col>
    </Row>
  );
};

export default MainFieldSet;
