import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'

import { NewsPostActions } from 'models/NewsPost/slice'
import { NewsPostList, NewsPostForm } from 'models/NewsPost/components'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];

const NewsPostListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(NewsPostActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Новости"
      headerMiddle={<SmartFilter queryKey="newsPost" onChange={onFilterChange} fields={filterFields} />}

      mainActions={[
        { name: 'add', action: (detail) => onEdit() },
      ]}
    >
      <SmartFilterValues queryKey="newsPost" onChange={onFilterChange} fields={filterFields} />

      <NewsPostList params={params} />

      <NewsPostForm mode="list" />
    </Page>
  )
}

export default NewsPostListPage
