import { Select } from 'antd'


const RPSCompanySelect = ({ options, disabled, required, ...props }) => {
  return (
    <Select
      style={{ width: props.width || '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Выберите компанию"
      showSearch={true}
      allowClear={props.allowClear}
      disabled={disabled}
      optionFilterProp="label"
      size="large"
      onChange={props.onChange}
      options={options || []}
      {...props}
    />
  )
}

export default RPSCompanySelect
