import { Form, Select } from 'antd'


const FilterSelect = ({ label, name, options }) => {
  return (
    <Form.Item name={name} label={label}>
      <Select options={options} />
    </Form.Item>
  );
}

FilterSelect.getValue = (values) => {
  return values;
}


export default FilterSelect;
