import { Select } from 'antd'

import { ListLoadComponent } from 'components/common'
import CardApi from 'models/Card/api'


const CardSelect = ({ filter, disabled, required, onLoaded, ...props }) => {
  const [getObjectList, getObjectListProps] = CardApi.endpoints.getCardSimpleList.useLazyQuery();

  return (
    <ListLoadComponent
      getObjectList={getObjectList}
      getObjectListProps={getObjectListProps}
      filter={filter}
      onLoaded={onLoaded}

      render={({ items }) => {
        return (
          <Select
            style={{ width: props.width || '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Выберите карту"
            showSearch={props.showSearch}
            allowClear={props.allowClear}
            disabled={disabled}
            optionFilterProp="label"
            size="large"
            onChange={props.onChange}
            {...props}
          >
            {!required && <Select.Option value={''}>---</Select.Option>}
            {items.map(item => {
              return (
                <Select.Option key={`category${item.id}`} value={item.id} label={item.number}>
                  {item.number}
                </Select.Option>
              )
            })}
          </Select>
        );
      }}
    />
  );
}

export default CardSelect
