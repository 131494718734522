import { useEffect, useMemo } from 'react'
import { Spin } from 'antd'


const ListLoadComponent = ({ getObjectList, getObjectListProps, filter, render, onLoaded }) => {
  const items = useMemo(() => {
    if (getObjectListProps.isSuccess) {
      const results = Array.isArray(getObjectListProps.data) ? getObjectListProps.data: getObjectListProps.data?.results || [];
      return results;
    }
    return []
  }, [getObjectListProps.isSuccess, getObjectListProps.data])

  useEffect(() => {
    const params = { ...filter };
    getObjectList({ params });
  }, [filter, getObjectList]);

  useEffect(() => {
    if (getObjectListProps.isSuccess) {
      onLoaded && onLoaded(items);
    }
  }, [onLoaded, getObjectListProps.isSuccess, items]);

  return <Spin spinning={getObjectListProps.isLoading}>{render({ items })}</Spin>;
};

export default ListLoadComponent;
