import TopTabs from './TopTabs'

import './styles.css'


const Top = ({ title, basePath, tabs, onTabChange }) => {
  return (
    <>
      <header className="top">
        <div className="top__start">
          {title && <div className="topTitle">{title}</div>}
        </div>

        <div className="top__end">
         
        </div>
      </header>

      {tabs && <TopTabs basePath={basePath} tabs={tabs} onTabChange={onTabChange} />}
    </>
  );
}


export default Top;
