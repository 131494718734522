import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { TextField, SwitchField, ImageUploadField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <Row gutter={32} style={{ marginBottom: '24px' }}>
          <Col span={12}>
            <TextField label="Фамилия" name="last_name" required={true} />
          </Col>
          <Col span={12}>
            <TextField label="Имя" name="first_name" required={true} />
          </Col>
        </Row>

        <Row gutter={32} style={{ marginBottom: '24px' }}>
          <Col span={12}>
            <TextField label="Телефон" name="phone" required={true} />
          </Col>
          <Col span={12}>
            <TextField label="Email" name="email" required={true} />
          </Col>
        </Row>
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="is_active" />

        <ImageUploadField label="Фотография" single={true} name="photo" data={{ tags: ['user'] }} columns={1} required={false} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
