import { useDispatch } from 'react-redux'
import { EditOutlined } from "@ant-design/icons"

import { TableColumns, TableList, PropertyList } from 'components/common'
import ParkingCardApi, { pkField } from 'models/ParkingCard/api'
import { ParkingCardActions } from 'models/ParkingCard/slice'
import ParkingCardValue from '../ParkingCardValue'


const defaultColumnList = ['name', 'client', 'rpsTariff', 'dateLastPaid', 'dateNextPaid', 'isActive', 'actions'];


const ParkingCardList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = ParkingCardApi.endpoints.getParkingCardList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ParkingCardApi.endpoints.updateParkingCard.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = ParkingCardApi.endpoints.batchDeleteParkingCard.useMutation();

  const dispatch = useDispatch();

  /*const toArchive = (id) => {
    apiUpdate({ id, isArchived: true })
  }*/

  const fields = {
    name: {
      title: 'Номер карты',
      sorter: true,
      width: 150,
      render: (val, item) => (<ParkingCardValue detail={item} asLink={false} isBack={true} />),
    },
    rpsTariff: {
      title: 'Тариф',
      width: 200,
      render: (val, item) => (item.rpsTariff?.Name),
    },
    client: {
      title: 'Владелец',
      render: (val, item) => (
        <PropertyList
          items={[
            { label: 'Компания:', value: item.rpsCompany?.CompanyName },
            { label: 'ФИО:', value: item?.name },
            { label: 'Телефон:', value: item?.phone },
          ]}
        />
      ),
    },
    dateLastPaid: TableColumns.getDateTime({ title: 'Дата последней оплаты', fieldName: 'dateLastPaid', onlyDate: true, sorter: true, }),
    dateNextPaid: TableColumns.getDateTime({ title: 'Дата след. оплаты', fieldName: 'dateNextPaid', onlyDate: true, sorter: true, }),

    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      icon: <EditOutlined />,
      onClick: (pk) => {
        dispatch(ParkingCardActions.openForm({ name: 'editForm', pk }))
      },
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(ParkingCardActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        /*{
          name: 'archive', label: 'В архив', action: () => toArchive(item.id), confirm: {
            title: 'Уверены, что хотите отправить в архив?',
            okText: 'В архив',
            cancelText: 'Отмена'
          }
        },*/
      ]
    }),
  };

  return (
    <TableList
      queryKey="parkingCard"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default ParkingCardList;
