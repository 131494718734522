import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { Fieldset, Descriptions, DateTimeValue, OnOff, Image, ImageGallery } from 'components/common'
import HallApi, { pkField } from 'models/Hall/api'


const HallDetail = ({ detail }) => {
  const [apiUpdate, apiUpdateProps] = HallApi.endpoints.updateHall.useMutation();
  const [apiGetDetail] = HallApi.endpoints.getHallDetail.useLazyQuery();

  const pk = detail[pkField];

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line
  }, [pk, apiUpdateProps.isSuccess]);

  return (
    <>
      <Fieldset>
        <Descriptions>
          <Descriptions.Item span={6} label="Активно">
            <OnOff name="Hall" detail={detail} pkField={pkField} fieldName="isActive" apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />
          </Descriptions.Item>

          <Descriptions.Item span={6} label="Сортировка">{detail?.sort}</Descriptions.Item>

          <Descriptions.Item span={6} label="Дата создания">
            <DateTimeValue date={detail?.date_created} />
          </Descriptions.Item>
          <Descriptions.Item span={6} label="Дата изменения">
            <DateTimeValue date={detail?.date_changed} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Row gutter={16}>
        <Col span={16}>
          <Fieldset title="Основное">
            <Descriptions>
              <Descriptions.Item label="Помещение">{detail?.place?.name}</Descriptions.Item>

              <Descriptions.Item span={3} label1="Лого" className="pad0">
                <Image src={detail?.icon[0]?.url} width={56} height={56} style={{ margin: '-8px' }} />
              </Descriptions.Item>

              <Descriptions.Item label="Название" span={21}>{detail?.name}</Descriptions.Item>

              <Descriptions.Item label="Slug">{detail?.slug}</Descriptions.Item>

              <Descriptions.Item label="Вместимость">{detail?.capacity}</Descriptions.Item>

              <Descriptions.Item label="Оборудование">{detail?.equipment}</Descriptions.Item>
            </Descriptions>
          </Fieldset>

          <Fieldset title="Текст">
            <Descriptions>
              <Descriptions.Item label="">
                <div style={{ overflowX: 'scroll', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: detail?.text }} />
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>

        <Col span={8}>
          <Fieldset title="Галерея">
            <ImageGallery images={detail?.images} />
          </Fieldset>
        </Col>
      </Row>
    </>
  );
}

export default HallDetail
