import { Form, Input } from 'antd'
import slugify from '@sindresorhus/slugify'


const getMainFieldChangeEvent = (form, fieldName) => (e) => {
  console.log('e', e.target.value);
  const slug = slugify(e.target.value).substring(0, 200);
  form.setFieldValue(fieldName, slug);
}


const SlugField = ({ name, label, required, disabled, maxLength, ...otherProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <Input size="large" disabled={disabled} maxLength={maxLength || 200} {...otherProps} />
    </Form.Item>
  );
};


SlugField.getMainFieldChangeEvent = getMainFieldChangeEvent;

export default SlugField;
