import { useDispatch } from 'react-redux'

import { TableList, TableColumns, PropertyList } from 'components/common'
import CardApi, { getDetailUrl, pkField } from 'models/Card/api'
import { CardActions } from 'models/Card/slice'
import { CardTypeValue } from 'models/CardType/components'
import { ClientValue } from 'models/Client/components'
import CardValue from '../CardValue'


const defaultColumnList = ['number', 'contacts', 'client', 'cardType', 'isVirtual', 'isActive', 'actions'];


const CardList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = CardApi.endpoints.getCardList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CardApi.endpoints.updateCard.useMutation();
  const [apiDelete, apiDeleteProps] = CardApi.endpoints.deleteCard.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = CardApi.endpoints.batchDeleteCard.useMutation();
  const [apiBatchUpdate, apiBatchUpdateProps] = CardApi.endpoints.batchUpdateCard.useMutation();

  const dispatch = useDispatch();

  const fields = {
    number: {
      title: 'Номер карты',
      sorter: true,
      width: 130,
      render: (val, item) => (<CardValue detail={item} asLink={true} isBack={true} />),
    },
    cardType: {
      title: 'Тип карты',
      width: 150,
      render: (val, item) => (<CardTypeValue detail={val} asLink={true} isBack={true} />),
    },
    contacts: {
      title: 'Контакты',
      sorter: false,
      render: (val, item) => (
        <PropertyList
          style={{ padding: '0', border: '0' }}
          items={[
            { label: 'ФИО:', value: `${item?.first_name} ${item.last_name}` },
            { label: 'Телефон:', value: `${item?.phone}` },
            { label: 'Email:', value: item?.email },
          ]}
        />
      ),
    },
    client: {
      title: 'Клиент',
      width: 200,
      render: (val, item) => (val ? <ClientValue detail={val} showPhone={true} asLink={true} isBack={true} />: '-'),
    },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps }),
    isVirtual: TableColumns.getColumnOnOff({ title: 'Электронная', fieldName: 'isVirtual', apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getDetailUrl,
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(CardActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        { name: 'delete', action: () => apiDelete(item[pkField]) },
      ]
    }),
  };

  const batchActivate = (selected) => {
    const items = selected.map(id => ({ id: id, fields: { isActive: true } }));
    apiBatchUpdate(items);
  };

  const batchDeactivate = (selected) => {
    const items = selected.map(id => ({ id: id, fields: { isActive: false } }));
    apiBatchUpdate(items);
  };

  return (
    <TableList
      queryKey="card"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      // apiBatchUpdate={apiBatchUpdate}
      apiBatchUpdateProps={apiBatchUpdateProps}
      onEdit={onEdit}

      hidePagination={false}
      hideStat={false}

      rowSelection={true}
      rowActions={[
        {
          title: 'Активировать',
          action: (selected) => batchActivate(selected),
          confirm: true,
        },
        {
          title: 'Деактивировать',
          action: (selected) => batchDeactivate(selected),
          confirm: true,
        },
      ]}
    />
  );
}

export default CardList;
