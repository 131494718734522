import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { api } from './api/api'
import app from './features/appSlice'
import auth from './features/authSlice'

import newsPost from 'models/NewsPost/slice'
import tenant from 'models/Tenant/slice'
import tenantCategory from 'models/TenantCategory/slice'
import tenantService from 'models/TenantService/slice'
import building from 'models/Building/slice'
import buildingLevel from 'models/BuildingLevel/slice'
import buildingPlace from 'models/BuildingPlace/slice'
import hall from 'models/Hall/slice'
import hallRequest from 'models/HallRequest/slice'
import hallAccess from 'models/HallAccess/slice'
import event from 'models/Event/slice'
import client from 'models/Client/slice'
import card from 'models/Card/slice'
import cardRequest from 'models/CardRequest/slice'
import cardType from 'models/CardType/slice'
import cardTypeBenefit from 'models/CardTypeBenefit/slice'
import cardUpload from 'models/CardUpload/slice'
import parkingCard from 'models/ParkingCard/slice'
import rpsTariff from 'models/RPSTariff/slice'


const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app,
  auth,
  newsPost,
  tenantCategory,
  tenantService,
  tenant,
  building,
  buildingLevel,
  buildingPlace,
  hall,
  hallRequest,
  hallAccess,
  event,
  client,
  card,
  cardRequest,
  cardType,
  cardTypeBenefit,
  cardUpload,
  parkingCard,
  rpsTariff,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  /*middleware: [
    thunk,
    api.middleware, 
  ],*/

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
})

export const persistor = persistStore(store)
