import { Link } from "react-router-dom"

import './styles.css'


const Page404 = () => {
  return (
    <div className="app page404">
      <div className="block404">
        <div className="block404__code">404</div>
        <div className="block404__title">Страница не найдена</div>
        <Link className="block404__link" to={-1}>Назад</Link>
      </div>
    </div>
  );
};

export default Page404;
