import { API_URL } from 'config'
import { api, getList, getDetail, update, remove, batchRemove, batchUpdate } from 'store/api/api'


const baseApiUrl = 'card/card/';

export const exportUrl = `${API_URL}/api/admin/v1/card/card/export/`;

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/card/${detail[pkField]}/`
}


const CardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCardList: getList(builder, baseApiUrl, 'CardList'),
    getCardSimpleList: getList(builder, baseApiUrl, 'CardSimpleList', { simple: true }),

    getCardDetail: getDetail(builder, baseApiUrl, 'CardDetail'),
    getCardFormDetail: getDetail(builder, baseApiUrl, 'CardFormDetail', { forEdit: true }),

    updateCard: update(builder, baseApiUrl, pkField),

    deleteCard: remove(builder, baseApiUrl),
    batchDeleteCard: batchRemove(builder, baseApiUrl),
  
    batchUpdateCard: batchUpdate(builder, baseApiUrl),
  }),
});


export default CardApi;
