import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'tenants/category/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/tenant/category/${detail[pkField]}/`
}


const TenantCategoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTenantCategoryList: getList(builder, baseApiUrl, 'TenantCategoryList'),
    getTenantCategorySimpleList: getList(builder, baseApiUrl, 'TenantCategorySimpleList', { simple: true }),

    getTenantCategoryDetail: getDetail(builder, baseApiUrl, 'TenantCategoryDetail'),
    getTenantCategoryFormDetail: getDetail(builder, baseApiUrl, 'TenantCategoryFormDetail', { forEdit: true }),

    updateTenantCategory: update(builder, baseApiUrl, pkField),

    deleteTenantCategory: remove(builder, baseApiUrl),
    batchDeleteTenantCategory: batchRemove(builder, baseApiUrl),
  }),
});


export default TenantCategoryApi;
