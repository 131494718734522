import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { Modal, Form, Button, Dropdown, Spin } from 'antd'

import FormError from './FormError'

import "./styles.css"


/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '«${label}» обязательное поле',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


const ModalForm = ({
  name,
  mode,
  showGoToDetailButton,
  visible,
  pkField,
  formDetailPK,
  getDetailUrl,
  apiGetFormDetail,
  apiGetFormDetailProps,
  apiUpdateProps,
  api,

  initialValues,
  onFinish,
  onClose,
  submitTitle = 'Сохранить',
  ...props
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [submitState, setSubmitState] = useState(null);

  const { isLoading, data } = apiGetFormDetailProps;
  const loadedPK = data ? data[pkField] : null;

  const updateError = apiUpdateProps.error?.data || {};

  // load form detail
  useEffect(() => {
    if (formDetailPK && formDetailPK !== loadedPK && !isLoading) {
      apiGetFormDetail(formDetailPK);
    }
  }, [loadedPK, formDetailPK, isLoading, apiGetFormDetail]);

  // update fields from loaded form detail
  useEffect(() => {
    if (visible) {
      form.resetFields();

      // eslint-disable-next-line
      if (formDetailPK && formDetailPK == loadedPK) {
        const values = props.prepareFormValues ? props.prepareFormValues(data) : { ...data };
        form.setFieldsValue(values);
      }
    }
    // eslint-disable-next-line
  }, [visible, data]);

  // set errors
  useEffect(() => {
    if (updateError) {
      const fields = Object.keys(updateError || {}).map(fieldName => {
        return { name: fieldName, errors: updateError[fieldName] }
      })
      form.setFields(fields);
    }
    // eslint-disable-next-line
  }, [updateError]);

  // logic after saving data
  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      if (showGoToDetailButton && submitState === 'goToDetail') {
        setSubmitState(null);
        navigate(getDetailUrl(apiUpdateProps.data));
      } else if (mode === 'detail') {
        dispatch(api.util.invalidateTags([{ type: `${name}Detail` }]))
      } else {
        dispatch(api.util.invalidateTags([{ type: `${name}List` }]))
      }

      apiUpdateProps.reset();
      dispatch(api.util.invalidateTags([{ type: `${name}FormDetail` }]))
      form.resetFields();
      onClose(apiUpdateProps);
    }
    // eslint-disable-next-line
  }, [apiUpdateProps.isSuccess]);

  const saveButton =
    showGoToDetailButton ?
      <Dropdown.Button
        key="submit"
        type="primary"
        onClick={() => form.submit()}
        loading={apiUpdateProps.isLoading}
        menu={{
          items: [
            {
              key: 'default', label: `${submitTitle} и показать детали`, onClick: (e) => {
                setSubmitState('goToDetail');
                form.submit();
              }
            }
          ],
        }}
      >
        {submitTitle}
      </Dropdown.Button>
      :
      <Button key="submit" type="primary" loading={apiUpdateProps.isLoading} onClick={() => form.submit()}>
        {submitTitle}
      </Button>;


  return (
    <Modal
      className="modalForm"
      width={props.width || 600}
      open={visible}
      onCancel={() => onClose()}
      maskClosable={false}
      destroyOnClose={true}
      forceRender={true}
      footer={[
        <Button key="cancel" onClick={() => onClose()} disabled={apiUpdateProps.isLoading}>
          Отмена
        </Button>,
        saveButton
      ]}
    >
      <Spin spinning={isLoading || apiUpdateProps.isLoading}>
        <Form
          name={name}
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={values => {
            onFinish && onFinish({ ...values, [pkField]: formDetailPK })
          }}
          onFinishFailed={props.onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages}
        >
          <div className={`modalFormHeader ${props.withTabs && "modalFormHeader__withTabs"}`}>
            <div className="modalFormTitle">{props.title}</div>
          </div>
          <FormError error={updateError} />
          {props.children}
        </Form>
      </Spin>
    </Modal>
  );
};

ModalForm.Error = FormError;

export default ModalForm;
