import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'rps/tariff/';

export const pkField = 'Id';

export const getDetailUrl = (detail) => {
  return `/rps-tariff/${detail[pkField]}/`
}


const RPSTariffApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRPSTariffList: getList(builder, baseApiUrl, 'RPSTariffList'),
    getRPSTariffSimpleList: getList(builder, baseApiUrl, 'RPSTariffSimpleList', { simple: true }),

    getRPSTariffDetail: getDetail(builder, baseApiUrl, 'RPSTariffDetail'),
    getRPSTariffFormDetail: getDetail(builder, baseApiUrl, 'RPSTariffFormDetail', { forEdit: true }),

    updateRPSTariff: update(builder, baseApiUrl, pkField),

    deleteRPSTariff: remove(builder, baseApiUrl),
    batchDeleteRPSTariff: batchRemove(builder, baseApiUrl),
  }),
});


export default RPSTariffApi;
