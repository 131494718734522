import { useSelector, useDispatch } from 'react-redux'

import { ModalForm } from 'components/common'
import TenantCategoryApi, { pkField, getDetailUrl } from 'models/TenantCategory/api'
import { TenantCategoryActions } from 'models/TenantCategory/slice'

import MainFieldSet from './MainFieldSet'


const TenantCategoryForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.tenantCategory);

  const [apiGetFormDetail, apiGetFormDetailProps] = TenantCategoryApi.endpoints.getTenantCategoryFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = TenantCategoryApi.endpoints.updateTenantCategory.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  return (
    <ModalForm
      name="TenantCategory"
      title={formDetailPK ? `Категория «${formDetail?.name}»`: 'Новая категория'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={TenantCategoryApi}

      onFinish={onFinish}
      onClose={() => dispatch(TenantCategoryActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
        }
      }}
    >
      <MainFieldSet />
    </ModalForm>
  );
}


export default TenantCategoryForm
