
/**
+ * Returns the URL search parameters for a given search string and query key.
+ *
+ * @param {string} searchString - The search string containing the URL parameters.
+ * @param {string} queryKey - The key of the query parameter to retrieve.
+ * @return {URLSearchParams} - The URL search parameters for the specified query key.
+ */
export function getObjectSearchParams(searchString, queryKey) {
  const query = new URLSearchParams(searchString).get(queryKey);
  let dataQuery = '';
  if (query) {
    dataQuery = query.slice(1, -1).replaceAll('|', '&');
  }
  return new URLSearchParams(dataQuery);
}


/**
 * Generates a data query string based on the provided search string, object search parameters, and query key.
 *
 * @param {string} searchString - The search string to generate the query from.
 * @param {object} objectSearchParams - The object containing the search parameters.
 * @param {string} queryKey - The key of the query parameter to set or delete.
 * @return {array} An array containing the original data query string and the new search string with the updated query parameter.
 */
export function getDataQueryString(searchString, objectSearchParams, queryKey) {
  let searchParams = new URLSearchParams(searchString);

  const dataQueryString = objectSearchParams.toString();
  if (dataQueryString) {
    searchParams.set(queryKey, `[${dataQueryString.replaceAll('&', '|')}]`);
  } else {
    searchParams.delete(queryKey);
  }
  const newSearchString = decodeURIComponent(searchParams.toString());
  return [dataQueryString, newSearchString]
}

export function updateDataQueryString(searchString, queryKey, newParams) {
  let searchParams = new URLSearchParams(searchString);

  let queryParams = getObjectSearchParams(searchString, queryKey);

  Object.keys(newParams).forEach(key => {
    if (newParams[key]) {
      queryParams.set(key, newParams[key]);
    } else {
      queryParams.delete(key);
    }
  });

  const queryParamsString = queryParams.toString().replaceAll('&', '|');
  if (queryParamsString) {
    searchParams.set(queryKey, `[${queryParamsString}]`);
  } else {
    searchParams.delete(queryKey);
  }
  
  const newSearchString = decodeURIComponent(searchParams.toString());
  return newSearchString
}


/**
 * Combines a data query string with filter parameters.
 *
 * @param {string} dataQueryString - The data query string to combine.
 * @param {string} filterParams - The filter parameters to add to the data query string.
 * @return {string} The combined data query string.
 */
export function combineDataQuery(dataQueryString, filterParams) {
  if (!filterParams) return dataQueryString;
  const params1 = new URLSearchParams(dataQueryString ? dataQueryString : '');
  const params2 = new URLSearchParams(filterParams);
  for (let [key, val] of params2.entries()) {
    params1.set(key, val);
  }
  return params1.toString();
}
