import { Form } from 'antd'

import BuildingPlaceSelect from '../BuildingPlaceSelect'


const BuildingPlaceSelectField = ({ name, label, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <BuildingPlaceSelect required={required} disabled={disabled} />
    </Form.Item>
  );
}

BuildingPlaceSelectField.getValue = (values) => {
  return values;
}

BuildingPlaceSelectField.getDisplayValue = (values, config) => {
  return values
};

export default BuildingPlaceSelectField
