import { Form } from 'antd'

import RPSCompanySelect from '../RPSCompanySelect'


const RPSCompanySelectField = ({ options, name, label, required, disabled, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <RPSCompanySelect options={options} allowClear={!required} required={required} disabled={disabled} onChange={onChange} />
    </Form.Item>
  );
}

RPSCompanySelectField.getValue = (values) => {
  return values;
}

const DisplayValue = ({ value, config }) => {
  const options = config?.params?.options || [];
  const opts = options.filter(option => option.value === value);
  if (opts.length > 0) {
    return opts[0].label;
  }
  return value
};

RPSCompanySelectField.DisplayValue = DisplayValue;

export default RPSCompanySelectField
