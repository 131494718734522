import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import CardRequestStatusField from '../../CardRequestStatusField'

const { TextareaField } = FormFields;


const MainFieldSet = ({ initials }) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <CardRequestStatusField label="Статус" name="status" disabled={!!initials?.status} />

        <TextareaField label="Комментарий администратора" name="message" rows={4} required={false} />
      </Col>
    </Row>
  );
};

export default MainFieldSet;
