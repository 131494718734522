import { useDispatch } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'

import { Page } from 'layout'
import { EventActions } from 'models/Event/slice'
import { EventCalendar, EventForm } from '../../components'


const EventCalendarPage = () => {
  const dispatch = useDispatch();

  const onEdit = (objectId = null, initials = null) => {
    dispatch(EventActions.openForm({ name: 'editForm', pk: objectId, initials }))
  };

  return (
    <Page
      title="События"
      actions={[
        { mode: 'button', name: 'edit', icon: <PlusOutlined />, label: 'Добавить', action: (detail) => onEdit() },
      ]}
    >
      <EventCalendar onEdit={onEdit} />

      <EventForm mode="list" />
    </Page>
  )
}

export default EventCalendarPage
