import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'layout'
import CardTypeApi from 'models/CardType/api'
import { CardTypeActions } from 'models/CardType/slice'
import { CardTypeForm, CardTypeDetail } from 'models/CardType/components'


const CardTypeDetailPage = () => {
  const { cardTypePK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = CardTypeApi.endpoints.getCardTypeDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = CardTypeApi.endpoints.deleteCardType.useMutation();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Тип карты «${detail?.name}»`}
      detailPK={cardTypePK}
      detail={detail}

      basePath='/card/type/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(CardTypeActions.openForm({ name: 'editForm', pk: cardTypePK }))}
    >
      <CardTypeDetail detail={detail} />

      <CardTypeForm mode="detail" />
    </DetailPage>
  );
}

export default CardTypeDetailPage
