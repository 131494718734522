import { useDispatch } from 'react-redux'

import { TableList, TableColumns, BoolValue, DateTimeValue } from 'components/common'
import ClientApi, { getDetailUrl, pkField } from 'models/Client/api'
import { ClientActions } from 'models/Client/slice'
import ClientValue from '../ClientValue'


const defaultColumnList = ['name', 'phone', 'isPhoneConfirmed', 'date_joined', 'isActive', 'actions'];


const ClientList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = ClientApi.endpoints.getClientList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ClientApi.endpoints.updateClient.useMutation();
  const [apiDelete, apiDeleteProps] = ClientApi.endpoints.deleteClient.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = ClientApi.endpoints.batchDeleteClient.useMutation();

  const dispatch = useDispatch();

  const fields = {
    name: {
      title: 'Имя',
      sorter: true,
      render: (val, item) => (<ClientValue detail={item} asLink={true} isBack={true} />),
    },
    phone: {
      title: 'Телефон',
      width: 130,
      sorter: true,
    },
    isPhoneConfirmed: {
      title: 'Телефон подтвержден',
      width: 130,
      sorter: true,
      render: (val, item) => (<BoolValue value={val} />),
    },
    date_joined: {
      title: 'Дата регистрации',
      width: 150,
      render: (val, item) => (<DateTimeValue date={val} />),
    },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getDetailUrl,
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(ClientActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        { name: 'delete', action: () => apiDelete(item[pkField]) },
      ]
    }),
  };

  return (
    <TableList
      queryKey="client"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default ClientList;
