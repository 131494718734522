import dayjs from 'dayjs'


const DateTimeValue = ({ className, date, onlyDate, format = null }) => {
  let text = '';

  if (!date) {
    text = '-';
  } else {
    if (onlyDate) {
      text = dayjs(date).format(format || 'DD.MM.YYYY');
    } else {
      text = dayjs(date).format(format || 'DD.MM.YYYY HH:mm');
    }
  }

  return <span className={className}>{text}</span>;
};

export default DateTimeValue;
