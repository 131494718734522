import { useDispatch } from 'react-redux'

import { TableList, TableColumns, PropertyList } from 'components/common'
import BuildingPlaceApi, { getDetailUrl, pkField } from 'models/BuildingPlace/api'
import { BuildingPlaceActions } from 'models/BuildingPlace/slice'
import { HallValue } from 'models/Hall/components'
import { BuildingPlaceValue, BuildingPlaceStatus } from 'models/BuildingPlace/components'


const defaultColumnList = ['name', 'status', 'info', 'hallOrBuildingPlace', 'isActive', 'actions'];


const BuildingPlaceList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = BuildingPlaceApi.endpoints.getBuildingPlaceList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = BuildingPlaceApi.endpoints.updateBuildingPlace.useMutation();
  const [apiDelete, apiDeleteProps] = BuildingPlaceApi.endpoints.deleteBuildingPlace.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = BuildingPlaceApi.endpoints.batchDeleteBuildingPlace.useMutation();

  const dispatch = useDispatch();

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<BuildingPlaceValue detail={item} asLink={true} isBack={true} />),
    },
    status: {
      title: 'Статус',
      sorter: true,
      render: (val, item) => (<BuildingPlaceStatus detail={item} />),
    },
    hallOrBuildingPlace: {
      title: 'Зал/Арендатор',
      sorter: false,
      render: (val, item) => {
        if (item.hall) {
          return <HallValue detail={item.hall} asLink={true} isBack={true} />
        }
        if (item.tenant) {
          return <BuildingPlaceValue detail={item.tenant} asLink={true} isBack={true} />
        }
        return null
      },
    },
    info: {
      title: 'Информация',
      sorter: false,
      render: (val, item) => (
        <PropertyList
          style={{ padding: '0', border: '0' }}
          items={[
            { label: 'Назначение:', value: `${item?.purpose}` },
            { label: 'Отделка:', value: `${item?.furnish}` },
            { label: 'Площадь:', value: `${item?.area} м²` },
          ]}
        />
      ),
    },
    area: { title: 'Площадь', width: 100, sorter: true },
    purpose: { title: 'назначение', width: 130, sorter: true },
    furnish: { title: 'отделка', width: 130, sorter: true },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps }),
    actions: TableColumns.getColumnActions({
      getDetailUrl,
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(BuildingPlaceActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        { name: 'delete', action: () => apiDelete(item[pkField]) },
      ]
    }),
  };

  return (
    <TableList
      queryKey="buildingPlace"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default BuildingPlaceList;
