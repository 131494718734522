import { useState, useEffect } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import { WS_URL } from 'config'


export function useCardUploadWS() {
  const [cardUpload, setCardUpload] = useState(null);

  const { readyState, sendMessage } = useWebSocket(`${WS_URL}cardUpload/`, {
    onMessage: (event) => {
      const msg = JSON.parse(event.data);
      if (msg?.type === 'sendCardUpload') {
        setCardUpload(msg.object);
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendMessage(JSON.stringify({ type: 'getCardUpload'}));
    } else if (readyState === ReadyState.CLOSED) {
      setCardUpload(null);
    }
  }, [readyState, sendMessage]);

  return { cardUpload };
}
