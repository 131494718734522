import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { TextField, NumberField, SwitchField, ImageUploadField } = FormFields;


const MainFieldSet = () => {
  return (
    <Row gutter={[32, 32]}>
      <Col span={16}>
        <TextField label="Название" name="name" required={true} />

        <TextField label="Slug" name="slug" required={true} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>

      <Col span={24}>
        <ImageUploadField label="Картинки" name="images" data={{ tags: ['building'] }} columns={4} required={false} />
      </Col>
    </Row>
  );
};

export default MainFieldSet;
