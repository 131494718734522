import { useSelector, useDispatch } from 'react-redux'

import { ModalForm } from 'components/common'
import CardApi, { pkField, getDetailUrl } from 'models/Card/api'
import { CardActions } from 'models/Card/slice'
import MainFieldset from './MainFieldset'


const CardForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.card);

  const [apiGetFormDetail, apiGetFormDetailProps] = CardApi.endpoints.getCardFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CardApi.endpoints.updateCard.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const title = formDetail?.number || '';

  return (
    <ModalForm
      name="Card"
      withTabs={false}
      title={formDetailPK ? `Клубная карта «${title}»`: 'Новая клубная карта'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={CardApi}

      onFinish={onFinish}
      onClose={() => dispatch(CardActions.closeForm())}
      initialValues={{
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
        }
      }}
    >
      <MainFieldset initials={initials} />
    </ModalForm>
  );
}


export default CardForm
