//import { useDispatch } from 'react-redux'

import { TableList, DateTimeValue, PropertyList } from 'components/common'
import HallAccessApi, { pkField } from 'models/HallAccess/api'

import { ClientValue } from 'models/Client/components'
import { HallValue } from 'models/Hall/components'
import HallAccessValue from '../HallAccessValue'
import HallAccessStatus from '../HallAccessStatus'

const defaultColumnList = ['id', 'hall', 'status', 'event', 'client', 'actions'];


const HallAccessList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = HallAccessApi.endpoints.getHallAccessList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = HallAccessApi.endpoints.updateHallAccess.useMutation();
  const [apiDelete, apiDeleteProps] = HallAccessApi.endpoints.deleteHallAccess.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = HallAccessApi.endpoints.batchDeleteHallAccess.useMutation();

  //const dispatch = useDispatch();

  const fields = {
    id: {
      title: 'Номер',
      sorter: true,
      width: 140,
      render: (val, item) => <HallAccessValue detail={item} asLink={true} isBack={true} />,
    },
    hall: {
      title: 'Зал',
      width: 150,
      render: (val, item) => <HallValue detail={val} asLink={true} isBack={true} />,
    },
    status: {
      title: 'Статус',
      width: 144,
      render: (val, item) => <HallAccessStatus detail={item} />,
    },
    event: {
      title: 'Событие',
      render: (val, item) => (
        <PropertyList
          style={{ padding: '0', border: '0' }}
          items={[
            { label: 'Событие:', value: `${item.eventName}` },
            { label: 'Дата начала:', value: <DateTimeValue size={12} date={item.eventStart} /> },
            { label: 'Дата заверш.:', value: <DateTimeValue size={12} date={item.eventEnd} /> },
            { label: 'Длительность:', value: `${item.eventDuration} ч.` },
          ]}
        />
      ),
    },
    client: {
      title: 'Клиент',
      render: (val, item) => <ClientValue detail={val} showPhone={true} asLink={true} isBack={true} />,
    },
  };

  return (
    <TableList
      queryKey="hallAccess"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default HallAccessList;
