import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'building/level/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/building/level/${detail[pkField]}/`
}


const BuildingLevelApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuildingLevelList: getList(builder, baseApiUrl, 'BuildingLevelList'),
    getBuildingLevelSimpleList: getList(builder, baseApiUrl, 'BuildingLevelSimpleList', { simple: true }),

    getBuildingLevelDetail: getDetail(builder, baseApiUrl, 'BuildingLevelDetail'),
    getBuildingLevelFormDetail: getDetail(builder, baseApiUrl, 'BuildingLevelFormDetail', { forEdit: true }),

    updateBuildingLevel: update(builder, baseApiUrl, pkField),

    deleteBuildingLevel: remove(builder, baseApiUrl),
    batchDeleteBuildingLevel: batchRemove(builder, baseApiUrl),
  }),
});


export default BuildingLevelApi;
