import { Select } from 'antd'

import { ListLoadComponent } from 'components/common'
import HallApi from 'models/Hall/api'


const HallSelect = ({ filter, disabled, required, onLoaded, ...props }) => {
  const [getObjectList, getObjectListProps] = HallApi.endpoints.getHallSimpleList.useLazyQuery();

  return (
    <ListLoadComponent
      getObjectList={getObjectList}
      getObjectListProps={getObjectListProps}
      filter={filter}
      onLoaded={onLoaded}

      render={({ items }) => {
        return (
          <Select
            style={{ width: props.width || '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Выберите зал"
            showSearch={props.showSearch}
            allowClear={props.allowClear}
            disabled={disabled}
            optionFilterProp="label"
            size="large"
            onChange={props.onChange}
            {...props}
          >
            {!required && <Select.Option value={''}>---</Select.Option>}
            {items.map(item => {
              return (
                <Select.Option key={`category${item.id}`} value={item.id} label={item.name}>
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
        );
      }}
    />
  );
}

export default HallSelect
