import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'layout'
import CardApi from 'models/Card/api'
import { CardActions } from 'models/Card/slice'
import { CardForm, CardDetail } from '../../components'


const CardDetailPage = () => {
  const { cardPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = CardApi.endpoints.getCardDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = CardApi.endpoints.deleteCard.useMutation();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Карта «${detail?.number}»`}
      detailPK={cardPK}
      detail={detail}

      basePath='/card/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(CardActions.openForm({ name: 'editForm', pk: cardPK }))}
    >
      <CardDetail detail={detail} />

      <CardForm mode="detail" />
    </DetailPage>
  );
}

export default CardDetailPage
