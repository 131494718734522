import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, getSimpleListExtraReducers, openForm, closeForm } from 'store/common'
import api, { pkField } from './api'


const initialState = {
  ...baseModelState,
}


export const tenantCategory = createSlice({
  name: 'tenantCategory',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateTenantCategory);
    getSimpleListExtraReducers(builder, api.endpoints.getTenantCategorySimpleList, pkField);
  },
});

export default tenantCategory.reducer;

export const TenantCategoryActions = tenantCategory.actions;
