import { useDispatch } from 'react-redux'

import { TableList, TableColumns } from 'components/common'
import TenantCategoryApi, { getDetailUrl, pkField } from 'models/TenantCategory/api'
import { TenantCategoryActions } from 'models/TenantCategory/slice'

import TenantCategoryValue from '../TenantCategoryValue'


const defaultColumnList = ['name', 'sort', 'isActive', 'actions'];


const TenantCategoryList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = TenantCategoryApi.endpoints.getTenantCategoryList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = TenantCategoryApi.endpoints.updateTenantCategory.useMutation();
  const [apiDelete, apiDeleteProps] = TenantCategoryApi.endpoints.deleteTenantCategory.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = TenantCategoryApi.endpoints.batchDeleteTenantCategory.useMutation();

  const dispatch = useDispatch();

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<TenantCategoryValue detail={item} asLink={true} isBack={true} />),
    },
    sort: { title: 'Сортировка', sorter: true, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps }),
    actions: TableColumns.getColumnActions({
      getDetailUrl,
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(TenantCategoryActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        { name: 'delete', action: () => apiDelete(item[pkField]) },
      ]
    }),
  };

  return (
    <TableList
      queryKey="tenantCategory"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default TenantCategoryList;
