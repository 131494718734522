import { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { Skeleton } from 'antd'

import { Page } from 'layout'


const DetailPage = ({
  children,
  detailPK,
  detail,

  apiGetDetail,
  apiGetDetailProps,
  apiDelete,
  apiDeleteProps,

  basePath,
  title,
  showBack,
  tabs,

  mainActions,
  otherActions,

  onEdit,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    apiGetDetail(detailPK);
  }, [detailPK, apiGetDetail]);

  useEffect(() => {
    if (apiDeleteProps?.isSuccess) {
      navigate(basePath)
    }
  }, [apiDeleteProps?.isSuccess, basePath, navigate]);

  /*useEffect(() => {
    if (getObjectDetailProps.isError) {
      navigate('/404/', { replace: true })
    }
    // eslint-disable-next-line
  }, [getObjectDetailProps.error?.status]);*/

  const onDelete = () => {
    apiDelete && apiDelete(detailPK)
  }

  return (
    <Page
      title={title}
      showBack={showBack}
      mainActions={mainActions ? [...mainActions] : [{ name: 'edit', action: () => onEdit() }]}
      otherActions={otherActions !== false ? [{ name: 'delete', action: () => onDelete() }] : null}
      basePath={basePath}
      tabs={tabs}
    >
      {detail ? children : <Skeleton active={apiGetDetailProps.isLoading} />}
    </Page>
  );
}

export default DetailPage
