
export const baseModelState = {
  activeForm: null,
  formDetailPK: null,
  formInitials: null,

  simpleList: [],
  simpleListByPk: {},
}


export const openForm = (state, action) => {
  state.activeForm = action.payload?.name || 'editForm';
  state.formDetailPK = action.payload?.pk || null;
  state.formInitials = action.payload?.initials || null;
}

export const closeForm = (state) => {
  state.activeForm = null;
  state.formDetailPK = null;
}


export const getSimpleListExtraReducers = (builder, apiEndpoint, pkField) => {
  builder
    .addMatcher(apiEndpoint.matchFulfilled, (state, action) => {
      const results = Array.isArray(action.payload) ? action.payload : action.payload?.results || [];

      state.simpleList = results;
      state.simpleListByPk = results.reduce(
        (res, item) => ({ ...res, [item[pkField]]: item }),
        {}
      );
    })
}




export const updateExtraReducers = (builder, apiEndpoint) => {
  builder
    .addMatcher(apiEndpoint.matchFulfilled, (state, action) => {
      state.formDetailPK = null;
    })
}
