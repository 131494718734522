import { Form, Radio } from 'antd'

import "./styles.css"


const RadioField = ({ name, label, optionType, options, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <Radio.Group size="large" options={options} disabled={disabled} optionType={optionType} buttonStyle="solid" />
    </Form.Item>
  );
};

export default RadioField;
