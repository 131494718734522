import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'layout'
import HallRequestApi from 'models/HallRequest/api'
import { HallRequestActions } from 'models/HallRequest/slice'
import { HallRequestForm, HallRequestStatusForm, HallRequestDetail } from 'models/HallRequest/components'


const HallRequestDetailPage = () => {
  const { requestId } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = HallRequestApi.endpoints.getHallRequestDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = HallRequestApi.endpoints.deleteHallRequest.useMutation();

  const detail = apiGetDetailProps.data;

  const onChangeStatus = (status) => {
    console.log(status);
    dispatch(HallRequestActions.openForm({ name: 'statusForm', pk: requestId, initials: { status } }))
  }


  const mainActions = useMemo(() => {
    let _actions = [];
    if (detail?.status === 'new') {
      _actions.push({
        label: 'Взять в работу',
        type: 'dashed toInProgress',
        name: 'toInProgress',
        action: () => onChangeStatus('inProgress')
      });
      _actions.push({
        label: 'Отклонить',
        type: 'dashed toDeclined',
        name: 'toDeclined',
        action: () => onChangeStatus('declined')
      })
    } else if (detail?.status === 'inProgress') {
      _actions.push({
        label: 'Одобрить',
        type: 'dashed toCompleted',
        name: 'toCompleted',
        action: () => onChangeStatus('completed')
      });
      _actions.push({
        label: 'Отклонить',
        type: 'dashed toDeclined',
        name: 'toDeclined',
        action: () => onChangeStatus('declined')
      })
    }
    return _actions;
  }, [detail?.status])

  return (
    <DetailPage
      title={`Заявка №${requestId}`}
      detailPK={requestId}
      detail={detail}

      basePath='/hall-request/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(HallRequestActions.openForm({ name: 'editForm', pk: requestId }))}

      mainActions={mainActions}
      otherActions1={false}
    >
      <HallRequestDetail detail={detail} />

      <HallRequestForm mode="detail" />

      <HallRequestStatusForm mode="detail" />
    </DetailPage>
  );
}


export default HallRequestDetailPage
