import { FieldTimeOutlined, CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons"

import './styles.css'


const STATUS_LIST = [
  ['active', 'Активный'],
  ['completed', 'Завершенный'],
  ['expired', 'Истекший'],
  ['cancelled', 'Отмененный'],
]

const STATUS = STATUS_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {});

const ICONS = {
  'active': <FieldTimeOutlined />,
  'completed': <CheckCircleOutlined />,
  'expired': <CloseCircleOutlined />,
  'cancelled': <CloseCircleOutlined />,
}


const HallAccessStatus = ({ detail }) => {
  const status = detail?.status;

  const icon = ICONS[status];

  return (
    <div className={`accessStatus ${status}`}>{icon} {STATUS[status] || status}</div>
  );
};

HallAccessStatus.STATUS_LIST = STATUS_LIST;
HallAccessStatus.STATUS = STATUS;


export default HallAccessStatus
