import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import BuildingApi from 'models/Building/api'
import { BuildingActions } from 'models/Building/slice'
import { DetailPage } from 'layout'
import { BuildingForm, BuildingDetail } from 'models/Building/components'


const BuildingDetailPage = () => {
  const { buildingPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = BuildingApi.endpoints.getBuildingDetail.useLazyQuery();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Здание «${detail?.name}»`}
      detailPK={buildingPK}
      detail={detail}

      basePath='/building/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}

      onEdit={() => dispatch(BuildingActions.openForm({ name: 'editForm', pk: buildingPK }))}
      otherActions={false}
    >
      <BuildingDetail detail={detail} />

      <BuildingForm mode="detail" />
    </DetailPage>
  );
}

export default BuildingDetailPage
