import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'layout'
import BuildingLevelApi from 'models/BuildingLevel/api'
import { BuildingLevelActions } from 'models/BuildingLevel/slice'

import { BuildingLevelForm, BuildingLevelDetail } from 'models/BuildingLevel/components'


const BuildingLevelDetailPage = () => {
  const { levelPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = BuildingLevelApi.endpoints.getBuildingLevelDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = BuildingLevelApi.endpoints.deleteBuildingLevel.useMutation();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Этаж «${detail?.name}»`}
      detailPK={levelPK}
      detail={detail}

      basePath='/bulding/level/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(BuildingLevelActions.openForm({ name: 'editForm', pk: levelPK }))}
    >
      <BuildingLevelDetail detail={detail} />

      <BuildingLevelForm mode="detail" />
    </DetailPage>
  );
}

export default BuildingLevelDetailPage
