import { EyeOutlined } from "@ant-design/icons"

import { ActionButton, OnOff, DateTimeValue, BoolValue } from 'components/common'


export function getColumnActions({ pkField, label, icon, getDetailUrl, onClick, getActions, onEdit, onDelete, ...props }) {
  return {
    align: 'right',
    width: 80,
    ...props,
    render: (val, item) => (
      <ActionButton
        label={label}
        icon={icon === undefined ? <EyeOutlined />: icon}
        detailUrl={getDetailUrl ? getDetailUrl(item): null}
        onClick={onClick ? () => onClick(item[pkField || 'id']): null}
        actions={getActions ? getActions(item) : [
          { name: 'edit', action: () => onEdit(item[pkField || 'id']) },
          { name: 'delete', action: () => onDelete(item[pkField || 'id']) }
        ]}
      />
    ),
  }
}


export function getColumnOnOff({title, fieldName, apiUpdate, apiUpdateProps, pkField, ...props}) {
  return {
    title: title,
    width: 86,
    ...props,
    render: (val, item) => (<OnOff detail={item} pkField={pkField || 'id'} fieldName={fieldName} apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />),
  }
}


export function getColumnBool({title, fieldName, ...props}) {
  return {
    title: title,
    width: 80,
    ...props,
    render: (val, item) => (<BoolValue value={item[fieldName]} />),
  }
}


export function getDateTime({title, fieldName, onlyDate, size, ...props}) {
  return {
    title: title,
    width: 100,
    ...props,
    render: (val, item) => (<DateTimeValue date={item[fieldName]} onlyDate={onlyDate} size={size} />),
  }
}
