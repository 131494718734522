import { DetailValue, Image } from 'components/common'
import { getDetailUrl } from 'models/NewsPost/api'


const NewsPostValue = ({ detail, asLink, isBack }) => {
  return (
    <DetailValue
      url={asLink && getDetailUrl(detail)}
      isBack={isBack}
      className="flex"
      render={() => (
        <>
          <Image src={detail?.images[0]?.url} width={80} height={60} />
          {detail?.title || '-'}
        </>
      )}
    />
  );
};


export default NewsPostValue
