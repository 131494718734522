import { CheckCircleFilled, StopOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import './styles.css'


const BoolValue = ({ value, className }) => {
  const cls = ['boolValue', className, value && 'boolValue_isTrue'];
  const cx = classNames(cls);

  return (
    <div className={cx}>
      {value ? <CheckCircleFilled /> : <StopOutlined />}
    </div>
  );
};

export default BoolValue;
