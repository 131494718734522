import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'card/type/benefit/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/card/type/benefit/${detail[pkField]}/`
}


const CardTypeBenefitApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCardTypeBenefitList: getList(builder, baseApiUrl, 'CardTypeBenefitList'),
    getCardTypeBenefitSimpleList: getList(builder, baseApiUrl, 'CardTypeBenefitSimpleList', { simple: true }),

    getCardTypeBenefitDetail: getDetail(builder, baseApiUrl, 'CardTypeBenefitDetail'),
    getCardTypeBenefitFormDetail: getDetail(builder, baseApiUrl, 'CardTypeBenefitFormDetail', { forEdit: true }),

    updateCardTypeBenefit: update(builder, baseApiUrl, pkField),

    deleteCardTypeBenefit: remove(builder, baseApiUrl),
    batchDeleteCardTypeBenefit: batchRemove(builder, baseApiUrl),
  }),
});


export default CardTypeBenefitApi;
