import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'layout'
import EventApi from 'models/Event/api'
import { EventActions } from 'models/Event/slice'
import { EventForm, EventDetail } from 'models/Event/components'


const EventDetailPage = () => {
  const { eventPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = EventApi.endpoints.getEventDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = EventApi.endpoints.deleteEvent.useMutation();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Событие «${detail?.name}»`}
      detailPK={eventPK}
      detail={detail}

      basePath='/event/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(EventActions.openForm({ name: 'editForm', pk: eventPK }))}
    >
      <EventDetail detail={detail} />

      <EventForm mode="detail" />
    </DetailPage>
  );
}

export default EventDetailPage
