import { useDispatch } from 'react-redux'
import { EditOutlined } from "@ant-design/icons"

import { TableList, TableColumns } from 'components/common'
import TenantServiceApi, { pkField } from 'models/TenantService/api'
import { TenantServiceActions } from 'models/TenantService/slice'

import TenantServiceValue from '../TenantServiceValue'


const defaultColumnList = ['name', 'sort', 'isActive', 'actions'];


const TenantServiceList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = TenantServiceApi.endpoints.getTenantServiceList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = TenantServiceApi.endpoints.updateTenantService.useMutation();
  const [apiDelete, apiDeleteProps] = TenantServiceApi.endpoints.deleteTenantService.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = TenantServiceApi.endpoints.batchDeleteTenantService.useMutation();

  const dispatch = useDispatch();

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<TenantServiceValue detail={item} asLink={false} isBack={true} />),
    },
    sort: { title: 'Сортировка', sorter: true, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps, pkField }),
    actions: TableColumns.getColumnActions({
      //getDetailUrl,
      pkField,
      icon: <EditOutlined />,
      onClick: (slug) => {
        dispatch(TenantServiceActions.openForm({ name: 'editForm', pk: slug }))
      },
      getActions: (item) => [
        //{ name: 'edit', action: () => dispatch(TenantServiceActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        { name: 'delete', action: () => apiDelete(item[pkField]) },
      ]
    }),
  };

  return (
    <TableList
      queryKey="tenantService"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default TenantServiceList;
