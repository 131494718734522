import './styles.css'


const SvgIcon = ({ className, icon }) => {
  return (
    <svg className={`${className} icon`}>
      <use xlinkHref={`/img/icons.svg#${icon}`}></use>
    </svg>
  );
};

export default SvgIcon;
