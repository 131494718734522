import { useEffect } from 'react'

import { Fieldset, Descriptions, DateTimeValue, OnOff } from 'components/common'
import BuildingLevelApi, { pkField } from 'models/BuildingLevel/api'
import BuildingLevelScheme from '../BuildingLevelScheme'


const BuildingLevelDetail = ({ detail }) => {
  const [apiUpdate, apiUpdateProps] = BuildingLevelApi.endpoints.updateBuildingLevel.useMutation();
  const [apiGetDetail] = BuildingLevelApi.endpoints.getBuildingLevelDetail.useLazyQuery();

  const pk = detail[pkField];

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line
  }, [pk, apiUpdateProps.isSuccess]);

  return (
    <>
      <Fieldset>
        <Descriptions>
          <Descriptions.Item span={6} label="Активно">
            <OnOff name="BuildingLevel" detail={detail} pkField={pkField} fieldName="isActive" apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />
          </Descriptions.Item>

          <Descriptions.Item span={6} label="Сортировка">{detail?.sort}</Descriptions.Item>

          <Descriptions.Item span={6} label="Дата создания">
            <DateTimeValue date={detail?.date_created} />
          </Descriptions.Item>
          <Descriptions.Item span={6} label="Дата изменения">
            <DateTimeValue date={detail?.date_changed} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="Основное">
        <Descriptions>
          <Descriptions.Item label="Здание">{detail?.building.name}</Descriptions.Item>

          <Descriptions.Item label="Название" span={21}>{detail?.name}</Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <BuildingLevelScheme levelId={pk} level={detail} />
    </>
  );
}

export default BuildingLevelDetail
