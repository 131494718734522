import { Form } from 'antd'

import CardSelect from '../CardSelect'


const CardSelectField = ({ name, label, required, disabled, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <CardSelect allowClear={!required} required={required} disabled={disabled} filter={filter} onChange={onChange} />
    </Form.Item>
  );
}

CardSelectField.getValue = (values) => {
  return values;
}

CardSelectField.getDisplayValue = (values, config) => {
  return values
};

export default CardSelectField
