import { Form } from 'antd'

import TenantSelect from '../TenantSelect'


const TenantSelectField = ({ name, label, required, disabled, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <TenantSelect required={required} disabled={disabled} filter={filter} onChange={onChange} />
    </Form.Item>
  );
}

TenantSelectField.getValue = (values) => {
  return values;
}

TenantSelectField.getDisplayValue = (values, config) => {
  return values
};

export default TenantSelectField
