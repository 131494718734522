import { Form } from 'antd'

import ClientSelect from '../ClientSelect'


const ClientSelectField = ({ name, label, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <ClientSelect required={required} disabled={disabled} allowClear={!required} />
    </Form.Item>
  );
}

ClientSelectField.getValue = (values) => {
  return values;
}

ClientSelectField.getDisplayValue = (values, config) => {
  return values
};

export default ClientSelectField
