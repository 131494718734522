import { Dropdown, Popconfirm } from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined, MoreOutlined, DownloadOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import classNames from 'classnames'

import './styles.css'


const DefaultActionByName = {
  add: {
    label: 'Добавить',
    icon: <PlusOutlined />,
  },
  edit: {
    label: 'Редактировать',
    icon: <EditOutlined />,
  },
  export: {
    label: 'Выгрузить',
    icon: <DownloadOutlined />,
  },
  delete: {
    label: 'Удалить',
    icon: <DeleteOutlined />,
    confirm: {
      title: 'Уверены, что хотите удалить?',
      okText: 'Удалить',
      cancelText: 'Отмена'
    }
  },
}

const ActionButton = ({ className, label, icon, detailUrl, onClick, actions }) => {
  const navigate = useNavigate();

  const cx = classNames({
    'actionButton': true,
    'actionButton_isSingle': !icon && !label,
    [className]: true,
  });

  const items = actions.map(item => {
    const defaultItem = DefaultActionByName[item.name] || {};

    const confirm = item.confirm || defaultItem.confirm;
    const icon = item.icon || defaultItem.icon;
    const label = item.label || defaultItem.label;

    const btn = confirm ?
      <Popconfirm
        placement="bottomRight"
        title={confirm.title}
        okText={confirm.okText}
        cancelText={confirm.cancelText}
        onConfirm={() => item.action()}
        onClick={e => e.stopPropagation()}
      >
        {label}
      </Popconfirm>
      :
      <div onClick={() => item.action()}>{label}</div>;


    return {
      key: item.name,
      icon,
      label: btn,
      danger: !!confirm,
    }
  });

  const menuProps = {
    items,
  };

  if (!icon && !label) {
    return (
      <Dropdown className={cx} menu={menuProps} trigger="click">
        <MoreOutlined />
      </Dropdown>
    )
  }

  const onButtonClick = () => {
    if (onClick) {
      onClick();
    } else if (detailUrl) {
      console.log('detailUrl', detailUrl);
      navigate(detailUrl)
    }
  }

  return (
    <Dropdown.Button className={cx} menu={menuProps} onClick={onButtonClick} trigger="click">
      {icon}{label}
    </Dropdown.Button>
  );
};

ActionButton.DefaultActionByName = DefaultActionByName;

export default ActionButton;
