import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'card/type/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/card/type/${detail[pkField]}/`
}


const CardTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCardTypeList: getList(builder, baseApiUrl, 'CardTypeList'),
    getCardTypeSimpleList: getList(builder, baseApiUrl, 'CardTypeSimpleList', { simple: true }),

    getCardTypeDetail: getDetail(builder, baseApiUrl, 'CardTypeDetail'),
    getCardTypeFormDetail: getDetail(builder, baseApiUrl, 'CardTypeFormDetail', { forEdit: true }),

    updateCardType: update(builder, baseApiUrl, pkField),

    deleteCardType: remove(builder, baseApiUrl),
    batchDeleteCardType: batchRemove(builder, baseApiUrl),
  }),
});


export default CardTypeApi;
