import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'parking/card/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/parking/card/${detail[pkField]}/`
}


const ParkingCardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getParkingCardStatuses: builder.query({
      providesTags: [{ type: 'ParkingCardList', id: 'LIST' }],
      query: () => {
        return {
          url: 'parking/card/statuses/',
        }
      },
    }),

    syncParkingCard: builder.query({
      providesTags: [{ type: 'ParkingCardList', id: 'LIST' }],
      query: () => {
        return {
          url: 'parking/card/sync/',
        }
      },
    }),

    getParkingCardList: getList(builder, baseApiUrl, 'ParkingCardList'),
    getParkingCardSimpleList: getList(builder, baseApiUrl, 'ParkingCardSimpleList', { simple: true }),

    getParkingCardDetail: getDetail(builder, baseApiUrl, 'ParkingCardDetail'),
    getParkingCardFormDetail: getDetail(builder, baseApiUrl, 'ParkingCardFormDetail', { forEdit: true }),

    updateParkingCard: update(builder, baseApiUrl, pkField),

    deleteParkingCard: remove(builder, baseApiUrl),
    batchDeleteParkingCard: batchRemove(builder, baseApiUrl),
  }),
});


export default ParkingCardApi;
