import { useState } from 'react'
import { Modal } from 'antd'
import { FileImageOutlined, EyeOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import './image.css'


const Image = ({ className, style, width, height, src, ratio, zoom = false }) => {
  const [previewVisible, setPreviewVisible] = useState(false);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = () => {
    setPreviewVisible(true);
  };

  const cls = ['image', className];

  let mainStyle = {};
  if (width) {
    mainStyle['width'] = isNaN(width) ? width : `${width}px`;
  }
  if (height) {
    mainStyle['height'] = isNaN(height) ? height : `${height}px`;
  }

  if (ratio) {
    cls.push('image__withRatio');
    mainStyle['height'] = "0";
    mainStyle["paddingBottom"] = `${100 / ratio}%`;
  }

  mainStyle = Object.assign(
    mainStyle,
    style || {},
  );

  const cx = classNames(cls);

  return (
    <>
      <div className={cx} style={mainStyle}>
        <div className="imageInner">
          {src ? <img src={src} alt="" /> : <div className="imageEmpty"><FileImageOutlined /></div>}
        </div>
        {zoom && <div className="imageHover">
          <div className="imagePreviewBtn" onClick={handlePreview}><EyeOutlined /></div>
        </div>}
      </div>
      {zoom &&
        <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
          <img style={{ width: '100%' }} src={src} alt="" />
        </Modal>}
    </>
  );
};

export default Image;
