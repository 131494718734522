import { useSelector, useDispatch } from 'react-redux'

import { ModalForm } from 'components/common'
import CardTypeBenefitApi, { pkField, getDetailUrl } from 'models/CardTypeBenefit/api'
import { CardTypeBenefitActions } from 'models/CardTypeBenefit/slice'
import MainFieldset from './MainFieldset'


const CardTypeBenefitForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.cardTypeBenefit);

  const [apiGetFormDetail, apiGetFormDetailProps] = CardTypeBenefitApi.endpoints.getCardTypeBenefitFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CardTypeBenefitApi.endpoints.updateCardTypeBenefit.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  return (
    <ModalForm
      name="CardTypeBenefit"
      withTabs={false}
      title={formDetailPK ? `Привилегия «${formDetail?.name}»`: 'Новая привилегия'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={CardTypeBenefitApi}

      onFinish={onFinish}
      onClose={() => dispatch(CardTypeBenefitActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
        }
      }}
    >
      <MainFieldset detailForEdit={formDetail} initials={initials} />
    </ModalForm>
  );
}


export default CardTypeBenefitForm
