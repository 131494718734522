import { TableColumns, TableList } from 'components/common'
import RPSTariffApi, { pkField } from 'models/RPSTariff/api'
import RPSTariffValue from '../RPSTariffValue'


const defaultColumnList = ['Name', 'forParkingCard', 'allowNotify'];


const RPSTariffList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = RPSTariffApi.endpoints.getRPSTariffList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = RPSTariffApi.endpoints.updateRPSTariff.useMutation();

  const fields = {
    Name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<RPSTariffValue detail={item} asLink={false} isBack={true} />),
    },

    forParkingCard: TableColumns.getColumnOnOff({ title: 'Для учёта парковочных карт', pkField: "Id", fieldName: 'forParkingCard', apiUpdate, apiUpdateProps }),
    allowNotify: TableColumns.getColumnOnOff({ title: 'Разрешены уведомления', pkField: "Id", fieldName: 'allowNotify', apiUpdate, apiUpdateProps }),
    
    IsDeleted: TableColumns.getColumnBool({ title: 'Удалено', fieldName: 'IsDeleted' }),
    dateLastPaid: TableColumns.getDateTime({ title: 'Дата оследней оплаты', fieldName: 'dateLastPaid', onlyDate: true, sorter: true, }),
    dateNextPaid: TableColumns.getDateTime({ title: 'Дата след. оплаты', fieldName: 'dateNextPaid', onlyDate: true, sorter: true, }),
  };

  return (
    <TableList
      queryKey="rpsTariff"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default RPSTariffList;
