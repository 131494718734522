import { Form, Checkbox } from 'antd'


const FilterCheckboxGroup = ({ label, name, options }) => {
  return (
    <Form.Item name={name} label={label}>
      <Checkbox.Group options={options} />
    </Form.Item>
  );
}

FilterCheckboxGroup.getValue = (values) => {
  return values;
}


export default FilterCheckboxGroup;
