import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'hall/request/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/hall-request/${detail[pkField]}/`
}


const HallRequestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHallRequestStatuses: builder.query({
      providesTags: [{ type: 'HallRequestList', id: 'LIST' }],
      query: () => {
        return {
          url: 'hall/request/statuses/',
        }
      },
    }),

    getHallRequestList: getList(builder, baseApiUrl, 'HallRequestList'),
    getHallRequestSimpleList: getList(builder, baseApiUrl, 'HallRequestSimpleList', { simple: true }),

    getHallRequestDetail: getDetail(builder, baseApiUrl, 'HallRequestDetail'),
    getHallRequestFormDetail: getDetail(builder, baseApiUrl, 'HallRequestFormDetail', { forEdit: true }),

    updateHallRequest: update(builder, baseApiUrl, pkField),

    deleteHallRequest: remove(builder, baseApiUrl),
    batchDeleteHallRequest: batchRemove(builder, baseApiUrl),
  }),
});


export default HallRequestApi;
