import { useDispatch } from 'react-redux'

import { TableList, TableColumns } from 'components/common'
import BuildingApi, { getDetailUrl, pkField } from 'models/Building/api'
import { BuildingActions } from 'models/Building/slice'
import BuildingValue from '../BuildingValue'


const defaultColumnList = ['name', 'sort', 'isActive', 'actions'];


const BuildingList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = BuildingApi.endpoints.getBuildingList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = BuildingApi.endpoints.updateBuilding.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = BuildingApi.endpoints.batchDeleteBuilding.useMutation();

  const dispatch = useDispatch();

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<BuildingValue detail={item} asLink={true} isBack={true} />),
    },
    sort: { title: 'Сортировка', sorter: true, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getDetailUrl,
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(BuildingActions.openForm({ name: 'editForm', pk: item[pkField] })) },
      ]
    }),
  };

  return (
    <TableList
      queryKey="building"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default BuildingList;
