import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import TenantCategoryApi from 'models/TenantCategory/api'
import { TenantCategoryActions } from 'models/TenantCategory/slice'
import { DetailPage } from 'layout'
import { TenantCategoryForm, TenantCategoryDetail } from '../../components'


const TenantCategoryDetailPage = () => {
  const { tenantCategoryPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = TenantCategoryApi.endpoints.getTenantCategoryDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = TenantCategoryApi.endpoints.deleteTenantCategory.useMutation();

  const detail = apiGetDetailProps.data;


  return (
    <DetailPage
      title={`Категория арендатора «${detail?.name}»`}
      detailPK={tenantCategoryPK}
      detail={detail}

      basePath='/tenant/category/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(TenantCategoryActions.openForm({ name: 'editForm', pk: tenantCategoryPK }))}
    >
      <TenantCategoryDetail detail={detail} />

      <TenantCategoryForm mode="detail" />
    </DetailPage>
  );
}

export default TenantCategoryDetailPage
