import { DetailValue, DateTimeValue } from 'components/common'
import { getDetailUrl } from 'models/HallRequest/api'


const HallRequestValue = ({ detail, asLink, isBack }) => {
  return (
    <DetailValue
      url={asLink && getDetailUrl(detail)}
      isBack={isBack}
      render={() => (
        <>
          Заявка №{detail?.id || '-'}
          <div className="secondary nowrap" style1={{ display: 'flex', alignItems: 'baseline', marginTop: '4px', color: '#56636f', whiteSpace: 'nowrap' }}>
            <span style1={{ marginRight: '4px', fontSize: '12px' }}>от </span>
            <DateTimeValue date={detail?.dateCreated} size={14} />
          </div>
        </>
      )}
    />
  );
};


export default HallRequestValue
