import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'

import { Fieldset } from 'components/common'
import BuildingPlaceApi from 'models/BuildingPlace/api'
import { BuildingPlaceActions } from 'models/BuildingPlace/slice'
import { BuildingPlaceForm, BuildingPlaceList } from 'models/BuildingPlace/components'
import SchemeEditor from 'components/SchemeEditor'


const BuildingLevelScheme = ({ levelId, level }) => {
  const [apiGetList, apiGetListProps] = BuildingPlaceApi.endpoints.getBuildingPlaceList.useLazyQuery();
  const [itemUpdate] = BuildingPlaceApi.endpoints.updateBuildingPlace.useMutation();

  const [apiDelete, apiDeleteProps] = BuildingPlaceApi.endpoints.deleteBuildingPlace.useMutation();

  const dispatch = useDispatch();

  const placeList = apiGetListProps.data?.results || [];

  const loadPlaceList = useCallback(() => {
    apiGetList({ filterParams: { level: levelId } });
  }, [levelId, apiGetList]);

  useEffect(() => {
    loadPlaceList();
  }, [levelId, loadPlaceList]);

  const onPlaceEdit = (placeId = null) => {
    dispatch(BuildingPlaceActions.openForm({ name: 'editForm', pk: placeId }));
  }

  useEffect(() => {
    if (apiDeleteProps.isSuccess) {
      dispatch(BuildingPlaceApi.util.invalidateTags([{ type: 'BuildingPlaceList' }]))
    }
    // eslint-disable-next-line
  }, [apiDeleteProps.isSuccess]);

  const onPlaceDelete = (objectId) => {
    apiDelete(objectId);
  }

  return (
    <>
      <Fieldset title="Схема" actions={[
        <Button key="submit" type="primary" stize="small" onClick={() => onPlaceEdit()}>
          Добавить помещение
        </Button>]}>

        <SchemeEditor
          schemeUrl={level?.scheme[0]?.url}
          width={level?.width}
          height={level?.height}
          placeList={placeList}
          updatePlace={itemUpdate}
          onPlaceEdit={onPlaceEdit}
          onPlaceDelete={onPlaceDelete}
        />
      </Fieldset>

      <Fieldset title="Помещения">
        <BuildingPlaceList filterParams={{ level: levelId }} />
      </Fieldset>

      <BuildingPlaceForm mode="list" initials={{ level: levelId }} />
    </>
  );
}


export default BuildingLevelScheme
