import { useDispatch } from 'react-redux'
import { Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { useCardUploadWS } from 'models/CardUpload/useHooks'
import { CardUploadActions } from 'models/CardUpload/slice'
import { CardPCCardUploadForm } from 'models/CardUpload/components'

import './styles.css'


const CardTypeDetail = ({ detail }) => {
  const { cardUpload } = useCardUploadWS();

  console.log('cardUpload', cardUpload);

  const dispatch = useDispatch();

  const onUpload = () => {
    dispatch(CardUploadActions.openForm({ name: 'pcCardUploadForm' }))
  }

  const fileUrl = decodeURI(cardUpload?.file || '');
  const fileName = fileUrl.split('/').pop().split('?')[0];

  return (
    <>
      {cardUpload ?
        <div className="cardUploadInfo">
          <Spin indicator={<LoadingOutlined className="cardUploadInfo__spin" spin />} />
          <div className="cardUploadInfo__main">
            <div className="cardUploadInfo__title">Идёт обработка файла</div>
            <div className="cardUploadInfo_file">{fileName}</div>
          </div>

        </div>
        :
        <Button type="primary" size="middle" onClick={onUpload}>
          Загрузить из PC Cards
        </Button>
      }

      <CardPCCardUploadForm />
    </>
  );
}

export default CardTypeDetail
