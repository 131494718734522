import { DetailValue, DateTimeValue } from 'components/common'
import { getDetailUrl } from 'models/HallAccess/api'


const HallAccessValue = ({ detail, asLink, isBack }) => {
  return (
    <DetailValue
      url={asLink && getDetailUrl(detail)}
      isBack={isBack}
      className="flex"
      render={() => (
        <div>
          Доступ №{detail?.id || '-'}
          <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '4px', color: '#56636f', whiteSpace: 'nowrap' }}>
            <span style={{ marginRight: '4px', fontSize: '12px' }}>от</span>
            <DateTimeValue date={detail?.dateCreated} size={12} />
          </div>
        </div>
      )}
    />
  );
};


export default HallAccessValue
