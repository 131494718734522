import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'

import { EventActions } from 'models/Event/slice'
import { EventList, EventForm } from 'models/Event/components'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];

const EventListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(EventActions.openForm({ name: 'editForm' }))
  };
 
  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="События"
      headerMiddle={<SmartFilter queryKey="event" onChange={onFilterChange} fields={filterFields} />}

      mainActions={[
        { name: 'add', action: (detail) => onEdit() },
      ]}
    >
      <SmartFilterValues queryKey="tenant" onChange={onFilterChange} fields={filterFields} />
 
      <EventList params={params} />

      <EventForm mode="list" />
    </Page>
  )
}

export default EventListPage
