import { useState, useEffect, useCallback, useRef } from 'react'

import SchemePlace from './SchemePlace'

import './styles.css'


const SchemeEditor = ({ schemeUrl, width, height, placeList, updatePlace, onPlaceEdit, onPlaceDelete }) => {
  const ref = useRef();
  const schemeRef = useRef();
  const placesRef = useRef();
  const [scale, setScale] = useState(1);

  const resize = useCallback(event => {
    setScale(ref.current.offsetWidth / width);
    if (schemeRef.current.offsetHeight > 100) {
      const newHeight = ref.current.offsetWidth / width * schemeRef.current.offsetHeight;
      ref.current.style.height = `${newHeight}px`;
    }
  }, [width]);

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
    // eslint-disable-next-line
  }, [resize]);

  return (
    <div ref={ref} className="schemeEditor">
      <div ref={schemeRef} className="schemeEditor__scheme" style={{ width: `${width}px`, transform: `scale(${scale})` }}>
        <img src={schemeUrl} alt="" onLoad={() => resize()} />
      </div>

      <div ref={placesRef} className="schemeEditor__places" style={{ width: `${width}px`, transform: `scale(${scale})` }}>
        {placeList.map(place => (
          <SchemePlace
            key={`place${place.id}`}
            parentEl={placesRef}
            levelWidth={width}
            place={place}
            scale={scale}
            updatePlace={updatePlace}
            onPlaceEdit={onPlaceEdit}
            onPlaceDelete={onPlaceDelete}
          />
        ))}
      </div>
    </div>
  )
}

export default SchemeEditor
