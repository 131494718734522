import { useDispatch } from 'react-redux'

import { TableList, TableColumns } from 'components/common'
import EventApi, { getDetailUrl, pkField } from 'models/Event/api'
import { EventActions } from 'models/Event/slice'
import EventValue from '../EventValue'


const defaultColumnList = ['name', 'sort', 'isActive', 'actions'];


const EventList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = EventApi.endpoints.getEventList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = EventApi.endpoints.updateEvent.useMutation();
  const [apiDelete, apiDeleteProps] = EventApi.endpoints.deleteEvent.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = EventApi.endpoints.batchDeleteEvent.useMutation();

  const dispatch = useDispatch();

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<EventValue detail={item} asLink={true} isBack={true} />),
    },
    sort: { title: 'Сортировка', sorter: true, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getDetailUrl,
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(EventActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        { name: 'delete', action: () => apiDelete(item[pkField]) },
      ]
    }),
  };

  return (
    <TableList
      queryKey="event"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default EventList;
