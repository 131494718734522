import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { Fieldset, Descriptions, DateTimeValue, OnOff, ImageGallery } from 'components/common'
import NewsPostApi, { pkField } from 'models/NewsPost/api'


const NewsPostDetail = ({ detail }) => {
  const [apiUpdate, apiUpdateProps] = NewsPostApi.endpoints.updateNewsPost.useMutation();
  const [apiGetDetail] = NewsPostApi.endpoints.getNewsPostDetail.useLazyQuery();

  const pk = detail[pkField];

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line
  }, [pk, apiUpdateProps.isSuccess]);

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset title="Основное">
          <Descriptions>
            <Descriptions.Item label="Заголовок" span={12}>{detail?.title}</Descriptions.Item>

            <Descriptions.Item label="Slug" span={12}>{detail?.slug}</Descriptions.Item>

            <Descriptions.Item label="Дата публикации">
              <DateTimeValue date={detail?.date} />
            </Descriptions.Item>


          </Descriptions>
        </Fieldset>

        <Fieldset title="Текст">
          <Descriptions column={1}>
            <Descriptions.Item label="">
              <div style={{ overflowX: 'scroll', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: detail?.text }} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1}>
            <Descriptions.Item label="Активно">
              <OnOff name="NewsPost" detail={detail} pkField={pkField} fieldName="isActive" apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата создания">
              <DateTimeValue date={detail?.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения">
              <DateTimeValue date={detail?.date_changed} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset>
          <ImageGallery images={detail?.images} />
        </Fieldset>
      </Col>
    </Row>
  );
}

export default NewsPostDetail
