import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { BuildingSelectField } from 'models/Building/components/'

const { TextField, NumberField, SwitchField, ImageUploadField } = FormFields;


const MainFieldset = ({ initials }) => {
  const initialBuilding = initials && initials.building;

  return (
    <Row gutter={32}>
      <Col span={16}>
        <BuildingSelectField label="Здание" name="building" required={true} disabled={!!initialBuilding} />

        <TextField label="Название" name="name" required={true} />

        <Row gutter={32}>
          <Col span={12}>
            <NumberField label="Ширина" name="width" required={true} />
          </Col>
          <Col span={12}>
            <NumberField label="Высота" name="height" required={true} />
          </Col>
        </Row>

        <ImageUploadField
          label="Схема" name="scheme" data={{ tags: ['level'] }} required={true}
          title="Загрузить схему" single={true}
        />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
