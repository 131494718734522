import { getFullNameOrEmail } from 'utils/helpers'
import { DetailValue, Avatar } from 'components/common'

import "./styles.css"


const ClientValue = ({ detail, asLink, isBack, showPhone }) => {
  const name = getFullNameOrEmail(detail);

  return (
    <DetailValue
      className="clientValue"
      url={asLink && detail?.id && `/client/${detail.id}/`}
      isBack={isBack}
      render={() => (
        <>
          <Avatar image={detail?.photo} icon={<b>{name[0]}</b>} size={32} />
          <div>
            {name}
            {showPhone &&
              <div className="secondary">
                {detail?.phone && !detail?.phone.startsWith('@') && <div>{detail?.phone}</div>}
                {detail?.email && !detail?.email.includes('@tmp.ru') && <div>{detail?.email}</div>}
              </div>}
          </div>
        </>
      )}
    />
  );
};


export default ClientValue
