import { useDispatch } from 'react-redux'

import { TableList, TableColumns, DateTimeValue } from 'components/common'
import NewsPostApi, { getDetailUrl, pkField } from 'models/NewsPost/api'
import { NewsPostActions } from 'models/NewsPost/slice'
import NewsPostValue from '../NewsPostValue'


const defaultColumnList = ['title', 'date', 'isActive', 'actions'];


const NewsPostList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = NewsPostApi.endpoints.getNewsPostList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = NewsPostApi.endpoints.updateNewsPost.useMutation();
  const [apiDelete, apiDeleteProps] = NewsPostApi.endpoints.deleteNewsPost.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = NewsPostApi.endpoints.batchDeleteNewsPost.useMutation();

  const dispatch = useDispatch();

  const fields = {
    title: {
      title: 'Заголовок',
      sorter: true,
      render: (val, item) => (<NewsPostValue detail={item} asLink={true} isBack={true} />),
    },
    date: {
      title: 'Дата',
      width: 150,
      render: (val, item) => (<DateTimeValue date={val} />),
    },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getDetailUrl,
      getActions: (item) => [
        { name: 'edit', action: () => dispatch(NewsPostActions.openForm({ name: 'editForm', pk: item[pkField] })) },
        { name: 'delete', action: () => apiDelete(item[pkField]) },
      ]
    }),
  };

  return (
    <TableList
      queryKey="newsPost"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default NewsPostList;
