import { Form, Radio } from 'antd'


const FilterBool = ({ label, name, size }) => {
  return (
    <Form.Item name={name} label={label}>
      <Radio.Group buttonStyle="solid" size={size}>
        <Radio.Button value="">Все</Radio.Button>
        <Radio.Button value="true">Да</Radio.Button>
        <Radio.Button value="false">Нет</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

FilterBool.getValue = (values) => {
  if (Array.isArray(values)) values = values[0];
  if (!values) {
    return '';
  }
  return values;
}

const DisplayValue = ({ value }) => {
  if (value === 'true') return 'Да';
  return 'Нет';
};

FilterBool.DisplayValue = DisplayValue;


export default FilterBool;
