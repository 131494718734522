import { useDispatch } from 'react-redux'

import { TableList } from 'components/common'

import CardRequestApi, { getDetailUrl, pkField } from 'models/CardRequest/api'
import { CardRequestActions } from 'models/CardRequest/slice'

import { ClientValue } from 'models/Client/components'
import CardRequestValue from '../CardRequestValue'
import CardRequestStatus from '../CardRequestStatus'

const defaultColumnList = ['title', 'status', 'number', 'client', 'actions'];


const CardRequestList = ({ columnList, params, filterParams, onEdit }) => {
  const [apiGetList, apiGetListProps] = CardRequestApi.endpoints.getCardRequestList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CardRequestApi.endpoints.updateCardRequest.useMutation();
  const [apiDelete, apiDeleteProps] = CardRequestApi.endpoints.deleteCardRequest.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = CardRequestApi.endpoints.batchDeleteCardRequest.useMutation();

  const dispatch = useDispatch();

  const fields = {
    title: {
      title: 'Номер',
      sorter: true,
      width: 140,
      render: (val, item) => <CardRequestValue detail={item} asLink={true} isBack={true} />,
    },
    status: {
      title: 'Статус',
      width: 180,
      render: (val, item) => <CardRequestStatus detail={item} />,
    },
    number: {
      title: 'Номер карты',
      sorter: true,
      width: 150,
    },
    client: {
      title: 'Клиент',
      render: (val, item) => <ClientValue detail={val} showPhone={true} asLink={true} isBack={true} />,
    },
  };

  return (
    <TableList
      queryKey="cardRequest"
      rowKey={pkField}
      params={params}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
      hidePagination={false}
      hideStat={false}
    />
  );
}

export default CardRequestList;
