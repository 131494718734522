import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'layout'
import TenantApi from 'models/Tenant/api'
import { TenantActions } from 'models/Tenant/slice'
import { TenantForm, TenantDetail } from 'models/Tenant/components'


const TenantDetailPage = () => {
  const { tenantPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = TenantApi.endpoints.getTenantDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = TenantApi.endpoints.deleteTenant.useMutation();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Арендатор «${detail?.name}»`}
      detailPK={tenantPK}
      detail={detail}

      basePath='/tenant/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(TenantActions.openForm({ name: 'editForm', pk: tenantPK }))}
    >
      <TenantDetail detail={detail} />

      <TenantForm mode="detail" />
    </DetailPage>
  );
}

export default TenantDetailPage
