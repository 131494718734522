import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Button } from 'antd'

import { Fieldset, Descriptions, DateTimeValue, OnOff } from 'components/common'
import CardTypeApi, { pkField } from 'models/CardType/api'
import { CardTypeBenefitActions } from 'models/CardTypeBenefit/slice'
import { CardTypeBenefitForm, CardTypeBenefitList } from 'models/CardTypeBenefit/components'


const CardTypeDetail = ({ detail }) => {
  const [apiUpdate, apiUpdateProps] = CardTypeApi.endpoints.updateCardType.useMutation();
  const [apiGetDetail] = CardTypeApi.endpoints.getCardTypeDetail.useLazyQuery();

  const dispatch = useDispatch();

  const pk = detail[pkField];

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line
  }, [pk, apiUpdateProps.isSuccess]);

  const onBenefitEdit = () => {
    dispatch(CardTypeBenefitActions.openForm({ name: 'editForm' }))
  }

  return (
    <>
      <Fieldset>
        <Descriptions>
          <Descriptions.Item span={6} label="Активно">
            <OnOff name="CardType" detail={detail} pkField={pkField} fieldName="isActive" apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />
          </Descriptions.Item>

          <Descriptions.Item span={6} label="Сортировка">{detail?.sort}</Descriptions.Item>

          <Descriptions.Item span={6} label="Дата создания">
            <DateTimeValue date={detail?.date_created} />
          </Descriptions.Item>
          <Descriptions.Item span={6} label="Дата изменения">
            <DateTimeValue date={detail?.date_changed} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Row gutter={16}>
        <Col span={16}>
          <Fieldset title="Основное">
            <Descriptions>
              <Descriptions.Item label="Название" span={21}>{detail?.name}</Descriptions.Item>

              <Descriptions.Item label="Описание" span={21}>
                <div style={{ overflowX: 'scroll', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: detail?.text }} />
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>
      </Row>

      {detail?.id &&
        <Fieldset
          title="Привилегии"
          actions={[
            <Button key="add" type="primary" onClick={() => onBenefitEdit()}>Добавить</Button>
          ]}
        >
          <CardTypeBenefitList filterParams={{ cardType: detail?.id }} />
        </Fieldset>}

      <CardTypeBenefitForm mode="list" initials={{ cardType: detail?.id }} />
    </>
  );
}

/*

*/

//

export default CardTypeDetail
