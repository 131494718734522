import { DetailValue } from 'components/common'
import { getDetailUrl } from 'models/Card/api'


const CardValue = ({ detail, asLink, isBack }) => {
  const number = detail?.number;
  const chunks = [number.substring(0, 4), number.substring(4, 7), number.substring(7, 10)];

  return (
    <DetailValue
      url={asLink && getDetailUrl(detail)}
      isBack={isBack}
      className="flex"
      render={() => (
        <>
          {chunks.join(' ') || '-'}
        </>
      )}
    />
  );
};


export default CardValue
