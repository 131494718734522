import { Form } from 'antd'
import { useSelector } from 'react-redux'

import TenantCategorySelect from '../TenantCategorySelect'


const TenantCategorySelectField = ({ name, label, required, mode }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <TenantCategorySelect required={required} mode={mode} />
    </Form.Item>
  );
}

TenantCategorySelectField.getValue = (values) => {
  return values.map(v => parseInt(v));
}

const DisplayValue = ({ value }) => {
  const simpleListByPk = useSelector(state => state.tenantCategory.simpleListByPk);
  return (
    <span>{simpleListByPk[value]?.name || value}</span>
  )
};

TenantCategorySelectField.DisplayValue = DisplayValue;


export default TenantCategorySelectField
