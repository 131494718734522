import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'tenants/tenant/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/tenant/${detail[pkField]}/`
}


const TenantApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTenantList: getList(builder, baseApiUrl, 'TenantList'),
    getTenantSimpleList: getList(builder, baseApiUrl, 'TenantSimpleList', { simple: true }),

    getTenantDetail: getDetail(builder, baseApiUrl, 'TenantDetail'),
    getTenantFormDetail: getDetail(builder, baseApiUrl, 'TenantFormDetail', { forEdit: true }),

    updateTenant: update(builder, baseApiUrl, pkField),

    deleteTenant: remove(builder, baseApiUrl),
    batchDeleteTenant: batchRemove(builder, baseApiUrl),
  }),
});


export default TenantApi;
