import { Form } from 'antd'

import { FileUpload } from 'components/common'

import "./styles.css"


const FileUploadField = ({ name, label, required, data, ...otherProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]} valuePropName="fileList">
      <FileUpload
        {...otherProps}
      />
    </Form.Item>
  );
};

export default FileUploadField;
