import { Top } from 'layout'


const HomeScreen = () => {
  return (
    <div>
      <Top
        title="Рабочий стол"
        basePath="/"
      />
    </div>
  );
};

export default HomeScreen;
