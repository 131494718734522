import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'hall/hall/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/hall/${detail[pkField]}/`
}


const HallApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHallList: getList(builder, baseApiUrl, 'HallList'),
    getHallSimpleList: getList(builder, baseApiUrl, 'HallSimpleList', { simple: true }),

    getHallDetail: getDetail(builder, baseApiUrl, 'HallDetail'),
    getHallFormDetail: getDetail(builder, baseApiUrl, 'HallFormDetail', { forEdit: true }),

    updateHall: update(builder, baseApiUrl, pkField),

    deleteHall: remove(builder, baseApiUrl),
    batchDeleteHall: batchRemove(builder, baseApiUrl),
  }),
});


export default HallApi;
