import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { TenantCategorySelectField } from 'models/TenantCategory/components/'
import { TenantServiceSelectField } from 'models/TenantService/components/'

const { TextField, HtmlField, NumberField, SwitchField, ImageUploadField } = FormFields;


const MainFieldset = () => {

  return (
    <Row gutter={32}>
      <Col span={16}>
        <TenantCategorySelectField label="Категория" name="category" required={true} />

        <Row gutter={32} style={{ marginBottom: '24px' }}>
          <Col flex="120px">
            <ImageUploadField
              label="Лого" name="icon" data={{ tags: ['tenants'] }} required={false}
              title="Загрузить лого" single={true}
            />
          </Col>
          <Col flex="auto">
            <TextField label="Название" name="name" required={true} />

            <TextField label="Slug" name="slug" required={true} />
          </Col>
        </Row>

        <TenantServiceSelectField label="Услуги" name="services" mode="multiple" required={false} />

        <HtmlField label="Текст" name="text" tags={['tenants']} required={false} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
