import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { CardTypeSelectField } from 'models/CardType/components'
import { TenantSelectField } from 'models/Tenant/components'

const { TextField, NumberField, SwitchField, TextareaField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  const initialCardType = (initials && initials?.cardType) || detailForEdit?.cardType?.id;

  return (
    <Row gutter={32}>
      <Col span={16}>
        <CardTypeSelectField label="Тип карты" name="cardType" required={true} disabled={!!initialCardType} />

        <TenantSelectField label="Арендатор" name="tenant" required={true} />

        <Row gutter={32} style={{ marginBottom: '12px' }}>
          <Col span={12}>
            <TextField label="Скидка" name="value" required={true} />
          </Col>
          <Col span={12}>
            <TextField label="Подпись" name="valueLabel" required={true} />
          </Col>
        </Row>

        <Row gutter={32} style={{ marginBottom: '12px' }}>
          <Col span={12}>
            <TextField label="Скидка 2" name="value2" required={false} />
          </Col>
          <Col span={12}>
            <TextField label="Подпись" name="value2Label" required={false} />
          </Col>
        </Row>

        <TextareaField label="Текст" name="text" required={false} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
