import { Button } from 'antd'

import { ActionButton } from 'components/common'

import './styles.css'


const PageHeaderActions = ({ detail, mainActions, otherActions }) => {
  return (
    <div className="pageHeaderActions">
      {mainActions && mainActions.map((item) => {
        const defaultItem = ActionButton.DefaultActionByName[item.name];
        const icon = item?.icon || defaultItem?.icon;
        const label = item?.label || defaultItem?.label;

        return (
          <div className="pageHeaderActionsItem" key={`btn${item.name}`}>
            <Button type={item.type || 'primary'} onClick={() => item.action(detail)} size="middle" loading={item.loading}>
              {icon}
              {label}
            </Button>
          </div>
        )
      })}

      {otherActions && <ActionButton actions={otherActions} />}
    </div>
  );
}


export default PageHeaderActions;