import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'card/request/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/card-request/${detail[pkField]}/`
}


const CardRequestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCardRequestStatuses: builder.query({
      providesTags: [{ type: 'CardRequestList', id: 'LIST' }],
      query: () => {
        return {
          url: 'card/request/statuses/',
        }
      },
    }),
  
    getCardRequestList: getList(builder, baseApiUrl, 'CardRequestList'),
    getCardRequestSimpleList: getList(builder, baseApiUrl, 'CardRequestSimpleList', { simple: true }),

    getCardRequestDetail: getDetail(builder, baseApiUrl, 'CardRequestDetail'),
    getCardRequestFormDetail: getDetail(builder, baseApiUrl, 'CardRequestFormDetail', { forEdit: true }),

    updateCardRequest: update(builder, baseApiUrl, pkField),

    deleteCardRequest: remove(builder, baseApiUrl),
    batchDeleteCardRequest: batchRemove(builder, baseApiUrl),
  }),
});


export default CardRequestApi;
