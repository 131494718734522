import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'layout'
import NewsPostApi from 'models/NewsPost/api'
import { NewsPostActions } from 'models/NewsPost/slice'
import { NewsPostForm, NewsPostDetail } from 'models/NewsPost/components'


const NewsPostDetailPage = () => {
  const { newsPostPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = NewsPostApi.endpoints.getNewsPostDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = NewsPostApi.endpoints.deleteNewsPost.useMutation();

  const detail = apiGetDetailProps.data;

  return (
    <DetailPage
      title={`Новость «${detail?.title}»`}
      detailPK={newsPostPK}
      detail={detail}

      basePath='/news/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(NewsPostActions.openForm({ name: 'editForm', pk: newsPostPK }))}
    >
      <NewsPostDetail detail={detail} />

      <NewsPostForm mode="detail" />
    </DetailPage>
  );
}

export default NewsPostDetailPage
