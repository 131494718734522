import { Select } from 'antd'

import { ListLoadComponent } from 'components/common'
import ClientApi from 'models/Client/api'


const ClientSelect = ({ filter, disabled, required, onLoaded, ...props }) => {
  const [getObjectList, getObjectListProps] = ClientApi.endpoints.getClientSimpleList.useLazyQuery();

  return (
    <ListLoadComponent
      getObjectList={getObjectList}
      getObjectListProps={getObjectListProps}
      filter={filter}
      onLoaded={onLoaded}

      render={({ items }) => {
        return (
          <Select
            style={{ width: props.width || '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Выберите клиента"
            showSearch={props.showSearch}
            allowClear={props.allowClear}
            disabled={disabled}
            optionFilterProp="label"
            size="large"
            onChange={props.onChange}
            {...props}
          >
            {!required && <Select.Option value={''}>---</Select.Option>}
            {items.map(item => {
              let contacts = item.phone ? [`${item.phone}`]: [];
              if (item.email) contacts.push(item.email);
              contacts = contacts.join(', ');
              let label = (item.first_name || '') + ' ' + (item.last_name || '') + ' ' + contacts;
              
              return (
                <Select.Option key={`client${item.id}`} value={item.id} label={label}>
                  {item.first_name} {item.last_name} ({contacts})
                </Select.Option>
              )
            })}
          </Select>
        );
      }}
    />
  );
}

export default ClientSelect
