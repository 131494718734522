import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'layout'
import { DateTimeValue } from 'components/common'

import CardRequestApi from 'models/CardRequest/api'
import { CardRequestActions } from 'models/CardRequest/slice'

import { CardRequestStatusForm, CardRequestDetail, CardRequestStatus } from '../../components'


const CardRequestDetailPage = () => {
  const { requestPK } = useParams();

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = CardRequestApi.endpoints.getCardRequestDetail.useLazyQuery();
  const [apiDelete, apiDeleteProps] = CardRequestApi.endpoints.deleteCardRequest.useMutation();

  const detail = apiGetDetailProps.data;

  const onChangeStatus = (status) => {
    dispatch(CardRequestActions.openForm({ name: 'statusForm', pk: requestPK, initials: { status } }))
  }

  const mainActions = ['new', 'inProgress'].includes(detail?.status) ? [
    { label: 'Одобрить', type: 'dashed toCompleted', name: 'toCompleted', action: () => onChangeStatus('completed') },
    { label: 'Отклонить', type: 'dashed toDeclined', name: 'toDeclined', action: () => onChangeStatus('declined') },
  ] : [];

  return (
    <DetailPage
      title={
        <>
          <span style={{ marginRight: '8px' }}>
            Заявка №{requestPK} <DateTimeValue date={detail?.dateCreated} format="от DD MMM YYYY в HH:mm" />
          </span> 
          <CardRequestStatus detail={detail} />
        </>
      }
      detailPK={requestPK}
      detail={detail}

      basePath='/card-request/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      onEdit={() => dispatch(CardRequestActions.openForm({ name: 'editForm', pk: requestPK }))}

      mainActions={mainActions}
      otherActions1={false}
    >
      <CardRequestDetail detail={detail} />

      <CardRequestStatusForm mode="detail" />
    </DetailPage>
  );
}

export default CardRequestDetailPage
