import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'user/client/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  return `/client/${detail[pkField]}/`
}


const ClientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClientList: getList(builder, baseApiUrl, 'ClientList'),
    getClientSimpleList: getList(builder, baseApiUrl, 'ClientSimpleList', { simple: true }),

    getClientDetail: getDetail(builder, baseApiUrl, 'ClientDetail'),
    getClientFormDetail: getDetail(builder, baseApiUrl, 'ClientFormDetail', { forEdit: true }),

    updateClient: update(builder, baseApiUrl, pkField),

    deleteClient: remove(builder, baseApiUrl),
    batchDeleteClient: batchRemove(builder, baseApiUrl),
  }),
});


export default ClientApi;
