import { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { ConfigProvider } from 'antd'

import { appInit } from 'store/features/appSlice'
import { AppLayout } from 'layout'
import LoginPage from 'pages/LoginPage'
import HomePage from 'pages/HomePage'
import Page404 from 'pages/Page404'
import { NewsPostListPage, NewsPostDetailPage } from 'models/NewsPost/pages'
import { TenantCategoryListPage, TenantCategoryDetailPage } from 'models/TenantCategory/pages'
import { TenantServiceListPage } from 'models/TenantService/pages'
import { TenantListPage, TenantDetailPage } from 'models/Tenant/pages'
import { BuildingListPage, BuildingDetailPage } from 'models/Building/pages'
import { BuildingLevelDetailPage } from 'models/BuildingLevel/pages'
import { HallListPage, HallDetailPage } from 'models/Hall/pages'
import { HallRequestListPage, HallRequestDetailPage } from 'models/HallRequest/pages'
import { HallAccessListPage, HallAccessDetailPage } from 'models/HallAccess/pages'
import { EventListPage, EventDetailPage, EventCalendarPage } from 'models/Event/pages'
import { ClientListPage, ClientDetailPage } from 'models/Client/pages'
import { ParkingCardListPage } from 'models/ParkingCard/pages'
import { SupportListPage } from 'models/Support/pages'
import { CardListPage, CardDetailPage } from 'models/Card/pages'
import { CardRequestListPage, CardRequestDetailPage } from 'models/CardRequest/pages'
import { CardTypeListPage, CardTypeDetailPage } from 'models/CardType/pages'
import { RPSTariffListPage } from 'models/RPSTariff/pages'

import "antd/dist/reset.css"

const COLORS = {
  colorPrimary: '#8bb8a2',
  colorPrimaryHover: '#93c4ac',
  colorPrimaryActive: '#6f9382',
};


const ProtectedRoute = ({ allowedRoles }) => {
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const location = useLocation();

  return token && (user?.is_superuser || user?.is_staff) ? (
    <Outlet />
  ) : token && user ? (
    <Navigate to='/' replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appInit());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: COLORS,
          Button: COLORS,
          Switch: COLORS,
          Tabs: COLORS,
          Pagination: COLORS,
          colorLink: '#8bb8a2',
        },
        token: {
          colorLink: '#8bb8a2',
          colorLinkHover: '#93c4ac',
          colorLinkActive: '#6f9382',
        }
      }}
    >
      <Routes>
        <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<HomePage />} />

            <Route path="/news/">
              <Route index element={<NewsPostListPage />} />
              <Route path=':newsPostPK/' element={<NewsPostDetailPage />} />
            </Route>

            <Route path="/client/">
              <Route index element={<ClientListPage />} />
              <Route path=':clientPK/' element={<ClientDetailPage />} />
            </Route>

            <Route path="/parking-card/">
              <Route index element={<ParkingCardListPage />} />
              <Route path='status/:status/' element={<ParkingCardListPage />} />
            </Route>

            <Route path="/rps-tariff/">
              <Route index element={<RPSTariffListPage />} />
            </Route>

            <Route path="/support/">
              <Route index element={<SupportListPage />} />
            </Route>

            <Route path="/tenant/">
              <Route index element={<TenantListPage />} />
              <Route path=':tenantPK/' element={<TenantDetailPage />} />

              <Route path='/tenant/category/' element={<TenantCategoryListPage />} />
              <Route path='/tenant/category/:tenantCategoryPK/' element={<TenantCategoryDetailPage />} />

              <Route path='/tenant/service/' element={<TenantServiceListPage />} />
            </Route>

            <Route path="/building/">
              <Route index element={<BuildingListPage />} />
              <Route path=':buildingPK/' element={<BuildingDetailPage />} />
              <Route path='level/:levelPK' element={<BuildingLevelDetailPage />} />
            </Route>

            <Route path="/hall/">
              <Route index element={<HallListPage />} />
              <Route path=':hallPK/' element={<HallDetailPage />} />
            </Route>

            <Route path="/hall-request/">
              <Route index element={<HallRequestListPage />} />
              <Route path='status/:status/' element={<HallRequestListPage />} />
              <Route path=':requestPK/' element={<HallRequestDetailPage />} />
            </Route>

            <Route path="/hall-access/">
              <Route index element={<HallAccessListPage />} />
              <Route path=':accessPK/' element={<HallAccessDetailPage />} />
            </Route>

            <Route path="/event/">
              <Route index element={<EventCalendarPage />} />
              <Route path='list/' element={<EventListPage />} />
              <Route path=':eventPK/' element={<EventDetailPage />} />
            </Route>

            <Route path="/card/">
              <Route index element={<CardListPage />} />
              <Route path='type/' element={<CardTypeListPage />} />
              <Route path='type/:cardTypePK/' element={<CardTypeDetailPage />} />
              <Route path=':cardPK/' element={<CardDetailPage />} />
            </Route>

            <Route path="/card-request/">
              <Route index element={<CardRequestListPage />} />
              <Route path='status/:status/' element={<CardRequestListPage />} />
              <Route path=':requestPK/' element={<CardRequestDetailPage />} />
            </Route>
          </Route>
        </Route>

        <Route path='/login/' element={<LoginPage />} />

        <Route path='*' element={<Page404 />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
