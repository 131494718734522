import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { ModalForm } from 'components/common'
import ParkingCardApi, { pkField, getDetailUrl } from 'models/ParkingCard/api'
import { ParkingCardActions } from 'models/ParkingCard/slice'
import MainFieldset from './MainFieldset'


const ParkingCardForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.parkingCard);

  const [apiGetFormDetail, apiGetFormDetailProps] = ParkingCardApi.endpoints.getParkingCardFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ParkingCardApi.endpoints.updateParkingCard.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    console.log('values', values);
    const data = {
      ...values,
      phone: values?.phone?.valid ? values?.phone?.value : '',
      dateLastPaid: values.dateLastPaid.format('YYYY-MM-DD'),
      dateNextPaid: values.dateNextPaid.format('YYYY-MM-DD'),
    }
    console.log('data', data);
    apiUpdate(data);
  };

  const title = formDetail?.rpsCard_id || '';

  return (
    <ModalForm
      name="ParkingCard"
      withTabs={false}
      title={formDetailPK ? `Парковочная карта «${title}»`: 'Новая парковочная карта'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={ParkingCardApi}

      onFinish={onFinish}
      onClose={() => dispatch(ParkingCardActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}
      prepareFormValues={(detail) => {
        return {
          ...detail,
          dateLastPaid: detail.dateLastPaid ? dayjs(detail.dateLastPaid): null,
          dateNextPaid: detail.dateNextPaid ? dayjs(detail.dateNextPaid): null,
        }
      }}
    >
      <MainFieldset />
    </ModalForm>
  );
}


export default ParkingCardForm
