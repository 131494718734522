import { Form, Select } from 'antd'
import dayjs from 'dayjs'


const ParkingCardExpiringSelectField = ({ name, label, required, disabled, onChange }) => {
  const today = dayjs();

  const options = [
    {value: today.format('YYYY-MM-DD'), label: 'Сегодня'},
    {value: today.add(3, 'day').format('YYYY-MM-DD'), label: 'Через 3 дня'},
    {value: today.add(7, 'day').format('YYYY-MM-DD'), label: 'Через неделю'},
  ];

  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <Select
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="Выберите срок оплаты"
        showSearch={true}
        allowClear={!required}
        disabled={disabled}
        optionFilterProp="label"
        size="large"
        onChange={onChange}
        options={options}
      />
    </Form.Item>
  );
}

ParkingCardExpiringSelectField.getValue = (values) => {
  return values;
}

const DisplayValue = ({ value, config }) => {
  return value
};

ParkingCardExpiringSelectField.DisplayValue = DisplayValue;

export default ParkingCardExpiringSelectField
