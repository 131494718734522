import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'
import HallRequestApi from 'models/HallRequest/api'
import { HallRequestActions } from 'models/HallRequest/slice'
import { HallRequestList, HallRequestForm } from 'models/HallRequest/components'


const filterFields = [
  SmartFilter.getFieldConfig('dateCreated', 'Дата', SmartFilter.FilterDateRange),
];


const STATUS_TABS = [
  { getLabel: (count) => <>Новые <span>{count}</span></>, key: `new` },
  { getLabel: (count) => <>В обработке <span>{count}</span></>, key: `inProgress` },
  { getLabel: (count) => <>Подтвержденные <span>{count}</span></>, key: `completed` },
  { getLabel: (count) => <>Отклоненные <span>{count}</span></>, key: `declined` },
]


const HallRequestListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params, setParams] = useState(null);

  const [apiGetStatuses, apiGetStatusesProps] = HallRequestApi.endpoints.getHallRequestStatuses.useLazyQuery();

  useEffect(() => {
    apiGetStatuses();
    // eslint-disable-next-line
  }, []);

  const onEdit = () => {
    dispatch(HallRequestActions.openForm({ name: 'editForm' }))
  };
 
  const onFilterChange = (params) => {
    setParams(params)
  }

  const { status } = useParams();

  useEffect(() => {
    if (!status) {
      const key = STATUS_TABS[0].key;
      navigate({ pathname: `/hall-request/status/${key}/` }, { replace: true });
    }
    // eslint-disable-next-line
  }, [status]);

  const statuses = apiGetStatusesProps.data || {};
  const tabs = STATUS_TABS.map(item => ({ label: item.getLabel(statuses[item.key] || ''), key: item.key }));

  return (
    <Page
      title="Заявки на бронь"

      headerMiddle={<SmartFilter queryKey="hallRequest" onChange={onFilterChange} fields={filterFields} />}

      basePath="/hall-request/status/"
      tabs={tabs}
      onTabChange1={(key) => {
        //navigate({ pathname: `/hall-request/status/${key}/`, search: location.search }, { replace: true });
      }}
    >
      <SmartFilterValues queryKey="hallRequest" onChange={onFilterChange} fields={filterFields} />

      {status && <HallRequestList queryKey="hallRequest" filterParams={{ status }} />}
      
      <HallRequestForm mode="list" />
    </Page>
  )
}


export default HallRequestListPage
