import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { HallSelectField } from 'models/Hall/components'
import { ClientSelectField } from 'models/Client/components'
import HallRequestStatusField from '../../HallRequestStatusField'

const { TextField, DateField, NumberField, TextareaField } = FormFields;


const MainFieldSet = ({ formDetail, initials }) => {
  const initialHall = (initials && initials?.hall) || formDetail?.hall?.id;
  const initialClient = (initials && initials?.client) || formDetail?.client?.id;

  return (
    <Row gutter={32}>
      <Col span={16}>
        <HallSelectField label="Зал" name="hall" required={true} disabled={!!initialHall} />

        <ClientSelectField label="Клиент" name="client" required={true} disabled={!!initialClient} />

        <TextField label="Название события" name="eventName" required={true} disabled={true} />

        <Row gutter={32} style={{ marginBottom: '12px' }}>
          <Col span={12}>
            <DateField label="Дата проведения" name="eventStart" required={true} disabled={true} />
          </Col>
          <Col span={12}>
            <NumberField label="Длительность" name="eventDuration" required={true} disabled={true} />
          </Col>
        </Row>

        <TextareaField label="Комментарий" name="eventComment" rows={2} required={false} disabled={true} />
      </Col>

      <Col span={8}>
        <HallRequestStatusField label="Статус" name="status" />

        <TextareaField label="Комментарий администратора" name="statusComment" rows={4} required={false} />
      </Col>
    </Row>
  );
};

export default MainFieldSet;
