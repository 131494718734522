import { Link, useLocation } from 'react-router-dom'

import './styles.css'


const Card = ({ card, size }) => {
  console.log('card', card);

  const { number, cardType, first_name, last_name, email, phone, client, isActive } = card;
  const chunks = [number.substring(0, 4), number.substring(4, 7), number.substring(7, 10)];

  const location = useLocation();

  return (
    <Link
      className={`clubCard ${cardType?.code || 'black'} ${size || ''}`}
      to={`/card/${card.id}`}
      state={{ backUrl: location.pathname, backSearch: location.search }}
    >
      <div className="clubCard__inner">
        <div className="clubCard__face clubCard__face--front">
          <div className="clubCard__title">{cardType?.name}</div>
          <div className="clubCard__number">{chunks.join(' ')}</div>
          <div className="clubCard__name">{first_name} {last_name}</div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
