import { DetailValue } from 'components/common'
import { getDetailUrl } from 'models/BuildingPlace/api'


const BuildingPlaceValue = ({ detail, asLink, isBack }) => {
  if (!detail) return null
  return (
    <DetailValue
      url={asLink && getDetailUrl(detail)}
      isBack={isBack}
      className="flex"
      render={() => (
        <div className="valueFlex">
          {detail?.name || '-'}
        </div>
      )}
    />
  );
};


export default BuildingPlaceValue
