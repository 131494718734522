import { Link, useLocation } from 'react-router-dom'

import "./styles.css"


const DetailValue = ({ className, url, isBack, render }) => {
  const location = useLocation();

  const state = isBack ? { backUrl: location.pathname, backSearch: location.search } : null;

  const cls = className || '';

  if (url) {
    return (
      <Link className={`detailLink ${cls}`} to={url} state={state}>{render()}</Link>
    )
  }

  return (
    <span className={`detailValue ${cls}`}>{render()}</span>
  );
}

export default DetailValue
