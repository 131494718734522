import { useState, useEffect } from 'react'
import { Popconfirm } from 'antd'
import { EditFilled, DeleteFilled, NodeIndexOutlined, DragOutlined } from '@ant-design/icons'

import './styles.css'


const MoveAction = ({ placeId, scale, size, fontSize, margin, position, setPosition, updatePlace }) => {
  const [moveMode, setMoveMode] = useState(false);
  const [moveStartPosition, setMoveStartPosition] = useState(false);

  useEffect(() => {
    if (!moveMode) return;

    function getXY(e) {
      const dx = e.pageX - moveStartPosition[0];
      const dy = e.pageY - moveStartPosition[1];
      const x = Math.round(position[0] + dx / scale);
      const y = Math.round(position[1] + dy / scale);
      return [x, y]
    }

    const move = (e) => {
      const pos = getXY(e);
      setPosition(pos);
    }

    const endMove = (e) => {
      setMoveMode(false);
      const pos = getXY(e);
      setPosition(pos);
      updatePlace && updatePlace({ id: placeId, schemePosition: pos })
    }

    window.addEventListener("mousemove", move);
    window.addEventListener("mouseup", endMove);
    return () => {
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", endMove);
    }
    // eslint-disable-next-line
  }, [moveMode]);

  const onStartMove = (e) => {
    setMoveMode(true);
    setMoveStartPosition([e.pageX, e.pageY])
  }

  return (
    <div
      className="schemePlace__action"
      style={{ width: `${size}px`, height: `${size}px`, fontSize: `${fontSize}px`, margin: `${margin}px 0 0 ${margin}px` }}
      title="Переместить"
      onMouseDown={onStartMove}
    >
      <DragOutlined />
    </div>
  )
}


const SchemePlaceActions = ({ placeId, scale, points, position, setPosition, updatePlace, onPointEdit, onPlaceEdit, onPlaceDelete }) => {
  const size = Math.floor(16 / scale);
  const fontSize = Math.floor(8 / scale);
  const margin = Math.floor(2 / scale);

  const style = {
    transform: `translate(${points[0][0]}px, ${points[0][1]}px)`
  }

  return (
    <div className="schemePlace__actions" onMouseDown={e => e.stopPropagation()} style={style}>
      <MoveAction
        placeId={placeId}
        scale={scale}
        size={size}
        fontSize={fontSize}
        margin={margin}
        position={position}
        setPosition={setPosition}
        updatePlace={updatePlace}
      />

      <div
        className="schemePlace__action editModeAction"
        style={{ width: `${size}px`, height: `${size}px`, fontSize: `${fontSize}px`, margin: `${margin}px 0 0 ${margin}px` }}
        title="Редактировать точки"
        onClick={onPointEdit}
      >
        <NodeIndexOutlined />
      </div>

      <div
        className="schemePlace__action"
        style={{ width: `${size}px`, height: `${size}px`, fontSize: `${fontSize}px`, margin: `${margin}px 0 0 ${margin}px` }}
        title="Изменить"
        onClick={() => onPlaceEdit(placeId)}>
        <EditFilled />
      </div>

      <div
        className="schemePlace__action"
        style={{ width: `${size}px`, height: `${size}px`, fontSize: `${fontSize}px`, margin: `${margin}px 0 0 ${margin}px` }}
        data-delete title="Удалить"
      >
        <Popconfirm
          placement="bottomRight"
          title="Вы уверены, что хотите удалить помещение?"
          okText="Да"
          cancelText="Отмена"
          onConfirm={() => onPlaceDelete(placeId)}
        >
          <DeleteFilled />
        </Popconfirm>
      </div>
    </div>
  );
}

export default SchemePlaceActions
