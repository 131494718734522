import { FormFields } from 'components/common'
import CardRequestStatus from '../CardRequestStatus'

const { SelectField } = FormFields;


const CardRequestStatusField = ({ name, label, required, disabled }) => {
  return (
    <SelectField
      label={label}
      name={name}
      required={true}
      disabled={disabled}
      rules={[{ required: required }]}
      options={
        CardRequestStatus.STATUS_LIST.map(item => ({ value: item[0], label: item[1] }))
      }
    />
  );
}

CardRequestStatusField.getValue = (values) => {
  return values;
}

CardRequestStatusField.getDisplayValue = (values, config) => {
  return values
};

export default CardRequestStatusField
