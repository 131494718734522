import { DetailValue } from 'components/common'
import { getDetailUrl } from 'models/Hall/api'


const HallValue = ({ detail, asLink, isBack }) => {
  return (
    <DetailValue
      url={asLink && getDetailUrl(detail)}
      isBack={isBack}
      className="flex"
      render={() => (
        detail?.name || '-'
      )}
    />
  );
};


export default HallValue
