import { useSelector, useDispatch } from 'react-redux'

import { ModalForm } from 'components/common'
import BuildingLevelApi, { pkField, getDetailUrl } from 'models/BuildingLevel/api'
import { BuildingLevelActions } from 'models/BuildingLevel/slice'
import MainFieldset from './MainFieldset'


const BuildingLevelForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.buildingLevel);

  const [apiGetFormDetail, apiGetFormDetailProps] = BuildingLevelApi.endpoints.getBuildingLevelFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = BuildingLevelApi.endpoints.updateBuildingLevel.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  return (
    <ModalForm
      name="BuildingLevel"
      withTabs={false}
      title={formDetailPK ? `Этаж «${formDetail?.name}»`: 'Новый этаж'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={BuildingLevelApi}

      onFinish={onFinish}
      onClose={() => dispatch(BuildingLevelActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
        }
      }}
    >
      <MainFieldset initials={initials} />
    </ModalForm>
  );
}


export default BuildingLevelForm
