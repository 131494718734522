import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'building/place/';

export const pkField = 'id';

export const getDetailUrl = (detail) => {
  const levelId = detail?.level?.id || detail?.level;
  return `/building/level/${levelId}/`
}


const BuildingPlaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuildingPlaceList: getList(builder, baseApiUrl, 'BuildingPlaceList'),
    getBuildingPlaceSimpleList: getList(builder, baseApiUrl, 'BuildingPlaceSimpleList', { simple: true }),

    getBuildingPlaceDetail: getDetail(builder, baseApiUrl, 'BuildingPlaceDetail'),
    getBuildingPlaceFormDetail: getDetail(builder, baseApiUrl, 'BuildingPlaceFormDetail', { forEdit: true }),

    updateBuildingPlace: update(builder, baseApiUrl, pkField),

    deleteBuildingPlace: remove(builder, baseApiUrl),
    batchDeleteBuildingPlace: batchRemove(builder, baseApiUrl),
  }),
});


export default BuildingPlaceApi;
