import { Form, Row, Col } from 'antd'

import { TenantSelectField } from 'models/Tenant/components/'
import { HallSelectField } from 'models/Hall/components/'


const TenantFieldset = ({ formDetail, initials }) => {
  const form = Form.useFormInstance();

  const onTenantChange = (tenantId) => {
    if (tenantId) {
      form.setFieldValue('hall', null);
    }
  }

  const onHallChange = (hallId) => {
    if (hallId) {
      form.setFieldValue('tenant', null);
    }
  }

  return (
    <Row gutter={32}>
      <Col span={24}>
        <TenantSelectField label="Арендатор" name="tenant" required={false} onChange={onTenantChange} />
        
        <HallSelectField label="Зал" name="hall" required={false} filter={{ forPlace: formDetail?.id || 0 }} onChange={onHallChange} />
      </Col>
    </Row>
  );
};

export default TenantFieldset;
