import { Form, Switch } from 'antd'

import "./styles.css"


const SwitchField = ({ name, label }) => {
  return (
    <Form.Item className="switch" label={label} name={name} valuePropName="checked">
      <Switch />
    </Form.Item>
  );
};

export default SwitchField;
