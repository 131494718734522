import { useSelector, useDispatch } from 'react-redux'
import { Tabs } from 'antd'

import { ModalForm } from 'components/common'
import HallApi, { pkField, getDetailUrl } from 'models/Hall/api'
import { HallActions } from 'models/Hall/slice'

import MainFieldset from './MainFieldset'
import GalleryFieldset from './GalleryFieldset'


const HallForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.hall);

  const [apiGetFormDetail, apiGetFormDetailProps] = HallApi.endpoints.getHallFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = HallApi.endpoints.updateHall.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const tabs = [
    { key: 'detail', label: 'Основное', forceRender: true, children: <MainFieldset initials={initials} /> },
    { key: 'gallery', label: 'Галерея', forceRender: true, children: <GalleryFieldset initials={initials} /> },
  ]

  return (
    <ModalForm
      name="Hall"
      withTabs={true}
      title={formDetailPK ? `Зал «${formDetail?.name}»` : 'Новый зал'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={HallApi}

      onFinish={onFinish}
      onClose={() => dispatch(HallActions.closeForm())}
      initialValues={{
        sort: 100,
        ...initials
      }}

      prepareFormValues={(detail) => {
        return {
          ...detail,
        }
      }}
    >
      <Tabs className="tabsForm" type="card" items={tabs} />
    </ModalForm>
  );
}


export default HallForm
