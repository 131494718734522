import { useSelector, useDispatch } from 'react-redux'
import { Tabs } from 'antd'

import { ModalForm } from 'components/common'
import BuildingPlaceApi, { pkField, getDetailUrl } from 'models/BuildingPlace/api'
import { BuildingPlaceActions } from 'models/BuildingPlace/slice'
import MainFieldset from './MainFieldset'
import TenantFieldset from './TenantFieldset'
import DecorationFieldset from './DecorationFieldset'


const BuildingPlaceForm = ({ mode = "list", initials }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.buildingPlace);

  const [apiGetFormDetail, apiGetFormDetailProps] = BuildingPlaceApi.endpoints.getBuildingPlaceFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = BuildingPlaceApi.endpoints.updateBuildingPlace.useMutation();

  const dispatch = useDispatch();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    const tenant = values.tenant || null;
    const hall = values.hall || null;
    const data = { ...values, tenant, hall };
    apiUpdate(data);
  };

  const initialValues = {
    schemeIconWidth: 100,
    schemeTitleWidth: 150,
    schemeTitleFontSize: 24,
    schemeInfoRotate: 0,
    schemeEntranceDirection: 'top',
    area: 0,
    purpose: 'офис',
    furnish: 'нет',
    status: 'free',
    isActive: true,
    ...initials
  };

  const tabs = [
    { key: 'detail', label: 'Основное', forceRender: true, children: <MainFieldset initials={initialValues} /> },
    { key: 'tenant', label: 'Арендатор', forceRender: true, children: <TenantFieldset formDetail={formDetail} initials={initialValues} /> },
    { key: 'decoration', label: 'Оформление', forceRender: true, children: <DecorationFieldset initials={initialValues} /> },
  ]

  return (
    <ModalForm
      name="BuildingPlace"
      withTabs={true}
      title={formDetailPK ? `Помещение «${formDetail?.number || formDetail?.name}»` : 'Новое помещение'}
      width={700}
      mode={mode}
      visible={activeForm === 'editForm'}

      pkField={pkField}
      formDetailPK={formDetailPK}

      getDetailUrl={getDetailUrl}
      apiGetFormDetail={apiGetFormDetail}
      apiGetFormDetailProps={apiGetFormDetailProps}
      apiUpdateProps={apiUpdateProps}
      api={BuildingPlaceApi}

      onFinish={onFinish}
      onClose={() => dispatch(BuildingPlaceActions.closeForm())}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        return { ...detail, level: detail.level }
      }}
    >
      <Tabs className="tabsForm" type="card" items={tabs} />
    </ModalForm>
  );
}


export default BuildingPlaceForm
