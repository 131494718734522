import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'

import { exportUrl } from 'models/Card/api'
import { CardList, CardForm } from 'models/Card/components'
import { CardUploadButton } from 'models/CardUpload/components'
import { CardTypeSelectField } from 'models/CardType/components'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('isVirtual', 'Электронная', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('clientExist', 'Есть клиента', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('cardType', 'Категория', CardTypeSelectField),
];


const CardListPage = () => {
  const [params, setParams] = useState(null);
  const token = useSelector(state => state.auth.token);

  const onFilterChange = (params) => {
    setParams(params)
  }

  const onExport = () => {

    window.location = exportUrl + '?token=' + token;
  };

  return (
    <Page
      title="Клубные карты"
      headerMiddle={<SmartFilter queryKey="card" onChange={onFilterChange} fields={filterFields} />}

      headerEnd={<CardUploadButton />}
      mainActions={[
        { name: 'export', type: 'dashed', label: 'Выгрузить', action: () => onExport() },
      ]}

      basePath="/card/"
      tabs={[
        { label: 'Карты', key: '' },
        { label: 'Типы карт', key: 'type/' },
      ]}
    >
      <SmartFilterValues queryKey="card" onChange={onFilterChange} fields={filterFields} />

      <CardList params={params} />

      <CardForm mode="list" />
    </Page>
  )
}

export default CardListPage
