import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'

import { Fieldset, Descriptions, DateTimeValue, OnOff } from 'components/common'
import BuildingApi, { pkField } from 'models/Building/api'
import { BuildingLevelActions } from 'models/BuildingLevel/slice'
import { BuildingLevelList, BuildingLevelForm } from 'models/BuildingLevel/components/'


const BuildingDetail = ({ detail }) => {
  const [apiUpdate, apiUpdateProps] = BuildingApi.endpoints.updateBuilding.useMutation();
  const [apiGetDetail] = BuildingApi.endpoints.getBuildingDetail.useLazyQuery();

  const pk = detail[pkField];

  const dispatch = useDispatch();

  useEffect(() => {
    if (apiUpdateProps.isSuccess) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line
  }, [pk, apiUpdateProps.isSuccess]);

  const onLevelEdit = () => {
    dispatch(BuildingLevelActions.openForm({ name: 'editForm' }))
  }

  return (
    <>
      <Fieldset>
        <Descriptions>
          <Descriptions.Item span={6} label="Активно">
            <OnOff name="Building" detail={detail} pkField={pkField} fieldName="isActive" apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />
          </Descriptions.Item>

          <Descriptions.Item span={6} label="Сортировка">{detail?.sort}</Descriptions.Item>

          <Descriptions.Item span={6} label="Дата создания">
            <DateTimeValue date={detail?.date_created} />
          </Descriptions.Item>
          <Descriptions.Item span={6} label="Дата изменения">
            <DateTimeValue date={detail?.date_changed} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="Основное">
        <Descriptions>
          <Descriptions.Item label="Название" span={21}>{detail?.name}</Descriptions.Item>

          <Descriptions.Item label="Slug">{detail?.slug}</Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="Этажи" actions={[<Button key="add" type="primary" onClick={() => onLevelEdit()} size="middle">Добавить</Button>]}>
        <BuildingLevelList filterParams={{ building: pk }} />
      </Fieldset>

      <BuildingLevelForm mode="list" initials={{ building: pk }} />
    </>
  );
}

export default BuildingDetail
