import { Row, Col } from 'antd'

import { Fieldset, Descriptions, DateTimeValue } from 'components/common'
import { ClientValue } from 'models/Client/components'
import { HallValue } from 'models/Hall/components'
import HallAccessStatus from '../HallAccessStatus'


const HallAccessDetail = ({ detail }) => {
  console.log('detail', detail)
  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset title="Основное">
          <Descriptions>
          <Descriptions.Item label="Код" span={24}>
              {detail?.code}
            </Descriptions.Item>

            <Descriptions.Item label="Зал" span={12}>
              <HallValue detail={detail?.hall} asLink={true} isBack={true} />
            </Descriptions.Item>

            <Descriptions.Item label="Клиент" span={12}>
              <ClientValue detail={detail?.client} showPhone={true} asLink={true} isBack={true} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Событие">
          <Descriptions column={1}>
            <Descriptions.Item label="Название события" span={24}>{detail?.eventName}</Descriptions.Item>
            <Descriptions.Item label="Дата начала" span={8}>
              <DateTimeValue date={detail?.eventStart} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата завершения" span={8}>
              <DateTimeValue date={detail?.eventEnd} />
            </Descriptions.Item>
            <Descriptions.Item label="Длительность" span={8}>{detail?.eventDuration} ч.</Descriptions.Item>

            <Descriptions.Item label="Комментарий">
              <div style={{ overflowX: 'scroll', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: detail?.eventComment }} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1}>
            <Descriptions.Item label="Статус">
              <HallAccessStatus detail={detail} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата создания">
              <DateTimeValue date={detail?.dateCreated} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения">
              <DateTimeValue date={detail?.dateChanged} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
}

export default HallAccessDetail
